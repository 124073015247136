import {Popover} from 'react-tiny-popover';
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas-pro';
import {isChrome, isFirefox, isMobile, isSafari} from 'react-device-detect';
import ysFixWebmDuration from 'fix-webm-duration';
import {toast} from 'react-toastify';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {domToJpeg} from 'modern-screenshot';
import useModalStore from '../stores/modal.store';
import usePostStore from '../stores/posts/base-post.store';
import { useState } from 'react';
import useSettingsStore from '../stores/settings.store';
import useGlobalStore from '../stores/global.store';

export default function ActionButtons(props) {
	const [popover1, setPopover1] = useState(false);
	const [canceled, setCanceled] = useState(false);
	const [mediaRecorder, setMediaRecorder] = useState({});
	const { settings, updateSettings, updateSetting } = useSettingsStore();
	const { toggleModal, isModalOpen } = useModalStore();
	const { title, team } = usePostStore();
	const { setIndividuallyEdit, setSizeMultiplier, setGraphicMap, setIsDownloading, setForceGraphicUpdate, setForceGraphicUpdateAsync, debug } = useGlobalStore();

	const download = async () => {
		setIsDownloading(true);
		const postcontainer = document.getElementById('postcontainer');
		postcontainer.style.position = 'absolute';
		postcontainer.style.left = '-1000vw';
		document.getElementById('progressbar').classList.remove('hide');
		toast.success('Downloading..', {autoClose: 1500});
		setIndividuallyEdit(false);
		let root = document.documentElement;
		let newSettings = {};
		const dimensions = settings.postHeight / settings.postWidth;
		newSettings.postWidth = 1080;
		newSettings.postHeight = 1080 * dimensions;
		newSettings.postWidthNews = 1080;
		newSettings.postHeightNews = 1080 * dimensions;
		updateSettings(newSettings);
		await setSizeMultiplier(1080 / 800);
		root.style.setProperty('--postwidth', `${newSettings.postWidth}px`);
		root.style.setProperty('--postheight', `${newSettings.postHeight}px`);
		root.style.setProperty('--postwidthnews', `${newSettings.postWidthNews}px`);
		root.style.setProperty('--postheightnews', `${newSettings.postHeightNews}px`);
		root.style.setProperty('--sizemultiplier', 1080 / 800);
		await props.updateGraphic();
		// force update to get text fit to work properly
		await setForceGraphicUpdateAsync();
		// wait for chart to redraw
		if (settings.type === 'chart') {
			await new Promise(resolve => setTimeout(resolve, 1000)); // wait for 1 seconds
		}
		props.moveDraggables();
		if (isMobile) {
			download2();
			return;
		}
		if (isSafari) {
			download3();
			return;
		}
		htmlToImage
			.toJpeg(document.getElementById('post'), {height: 1080 * dimensions, width: 1080, quality: 1})
			.then((dataUrl) => {
				let link = document.createElement('a');
				link.download = `${title}.jpeg`;
				link.href = dataUrl;
				link.click();
			})
			.catch((error) => {
				if (debug) {
					console.log(error);
				}
				download2();
			})
			.finally(() => {
				postcontainer.style.position = '';
				postcontainer.style.left = '';
				document.getElementById('progressbar').classList.add('hide');
				props.resizePost();
				// force update to get text fit to work properly
				setForceGraphicUpdate();
				props.moveDraggables();
				setIsDownloading(false);
			});
	};

	const setupCanvas = () => {
		let names = document.querySelectorAll('.boxname');
		let stats = document.querySelectorAll('.boxstat');
		names.forEach((item) => {
			if (team.length < settings.fivelessBreak && settings.type.includes('post')) {
				if (item.children[0]?.children[1]) {
					let i = 0;
					while (item.children[0].children[1].offsetWidth > item.offsetWidth - 5 && i < 25) {
						i++;
						item.children[0].children[1].innerText = item.children[0].children[1].innerText.replace('...', '');
						item.children[0].children[1].innerText = item.children[0].children[1].innerText.slice(0, item.children[0].children[1].innerText.length - 1);
						item.children[0].children[1].innerText = item.children[0].children[1].innerText + '...';
					}
				}
			} else {
				if (!item.children[0]) return;
				item.children[0].style.width = 'auto';
				if (item.children[0].offsetWidth > item.offsetWidth - 5) {
					let i = 0;
					while (item.children[0].offsetWidth > item.offsetWidth - 5 && i < 25) {
						i++;
						item.children[0].innerText = item.children[0].innerText.replace('...', '');
						item.children[0].innerText = item.children[0].innerText.slice(0, item.children[0].innerText.length - 1);
						item.children[0].innerText = item.children[0].innerText + '...';
					}
				}
				item.children[0].style.width = null;
			}
		});
		if (!settings.type.includes('post')) {
			stats.forEach((item) => {
				let i = 0;
				while (item.children[0].offsetWidth > item.offsetWidth - 5 && i < 25) {
					i++;
					item.children[0].innerText = item.children[0].innerText.replace('...', '');
					item.children[0].innerText = item.children[0].innerText.slice(0, item.children[0].innerText.length - 1);
					item.children[0].innerText = item.children[0].innerText + '...';
				}
			});
		}
		let blendInputs = document.querySelectorAll('.blend-in');
		blendInputs.forEach((blendInput) => {
			let span = document.createElement('span');
			span.classList.add('blend-in');
			span.style.minWidth = blendInput.offsetWidth + 'px';
			span.innerText = blendInput.value;
			const styles = window.getComputedStyle(blendInput);
			if (styles.cssText !== '') {
				span.style.cssText = styles.cssText;
			} else {
				const cssText = Object.values(styles).reduce((css, propertyName) => `${css}${propertyName}:${styles.getPropertyValue(propertyName)};`);

				span.style.cssText = cssText;
			}
			blendInput.replaceWith(span);
		});
		let boxImages = document.querySelectorAll('.boximagecontainer');
		boxImages.forEach((boxImage) => {
			boxImage.style.boxShadow = 'none';
		});
		let actualBoxImages = document.querySelectorAll('.boximage');
		actualBoxImages.forEach((boxImage) => {
			if (window.getComputedStyle(boxImage).backgroundPosition !== '50% 50%') {
				boxImage.style.top = `calc(var(--logoverticalmargin) * var(--sizemultiplier))`;
				boxImage.style.left = `calc(var(--logohorizontalmargin) * var(--sizemultiplier))`;
				boxImage.style.backgroundPosition = 'center';
				if (settings.type.includes('post')) {
					boxImage.style.position = 'relative';
				}
			}
		});
		let boxRanks = document.querySelectorAll('.boxrank');
		boxRanks.forEach((boxRank) => {
			boxRank.style.boxShadow = 'none';
		});
		let boxes = document.querySelectorAll('.box');
		boxes.forEach((box) => {
			box.style.boxShadow = 'none';
		});
	};

	const download2 = async () => {
		await setupCanvas();
		const post = document.getElementById('post');
		if (isMobile) {
			html2canvas(post, {
				allowTaint: false,
				useCORS: true,
			})
				.then((canvas) => {
					const dataURL = canvas.toDataURL('image/png');
					const newTab = window.open('about:blank', 'image from statlists');
					newTab.document.write("<img src='" + dataURL + "' alt='from statlists'/>");
					newTab.document.close();
				})
				.catch((e) => {
					console.log(e);
					toast.error('Graphic could not be downloaded. Please try again');
				});
		} else {
			html2canvas(post, {
				allowTaint: true,
				useCORS: false,
			})
				.then((canvas) => {
					toggleModal('image');
					setTimeout(() => {
						document.getElementById('image-modal-body').appendChild(canvas);
					});
				})
				.catch(() => {
					toast.error('Graphic could not be downloaded. Please try again');
				});
		}
		props.resizePost();
		// force update to get text fit to work properly
		setForceGraphicUpdate();
		await setGraphicMap(false);
		setGraphicMap(true);
		const postcontainer = document.getElementById('postcontainer');
		postcontainer.style.position = '';
		postcontainer.style.left = '';
		document.getElementById('progressbar').classList.add('hide');
		props.moveDraggables();
		setIsDownloading(false);
	};

	const download3 = () => {
		const dimensions = settings.postHeight / settings.postWidth;
		const postcontainer = document.getElementById('postcontainer');
		domToJpeg(document.getElementById('post'), {height: 1080 * dimensions, width: 1080, quality: 1})
			.then((dataUrl) => {
				if (isMobile) {
					const newTab = window.open('about:blank', 'image from statlists');
					newTab.document.write("<img src='" + dataUrl + "' alt='from statlists'/>");
					newTab.document.close();
					// else safari desktop
				} else {
					let link = document.createElement('a');
					link.download = `${title}.jpeg`;
					link.href = dataUrl;
					link.click();
				}
			})
			.catch((error) => {
				if (debug) {
					console.log(error);
				}
				download2();
			})
			.finally(() => {
				postcontainer.style.position = '';
				postcontainer.style.left = '';
				document.getElementById('progressbar').classList.add('hide');
				props.resizePost();
				// force update to get text fit to work properly
				setForceGraphicUpdate();
				props.moveDraggables();
				setIsDownloading(false);
			});
	};

	const setupCanvases = (height, width) => {
		let newCanvas = document.createElement('canvas');
		let previewCanvas = document.getElementById('video-preview');
		let newCtx = newCanvas.getContext('2d');
		let previewCtx = previewCanvas.getContext('2d');
		newCanvas.width = width;
		newCanvas.height = height;
		newCanvas.style.width = width + 'px';
		newCanvas.style.height = height + 'px';
		newCtx.beginPath();
		newCtx.rect(0, 0, width, height);
		newCtx.fillStyle = settings.headerColor;
		newCtx.fill();
		previewCanvas.width = width / 2;
		previewCanvas.height = height / 2;
		previewCanvas.style.width = width / 2 + 'px';
		previewCanvas.style.height = height / 2 + 'px';
		previewCtx.beginPath();
		previewCtx.rect(0, 0, width / 2, height / 2);
		previewCtx.fillStyle = settings.headerColor;
		previewCtx.fill();
		return {newCanvas, previewCanvas, newCtx, previewCtx};
	};

	const drawHeader = (newCtx, previewCtx) => {
		const titlebox = document.getElementById('titlebox');
		const titleboxheight = titlebox.getBoundingClientRect().height + 3;
		const titleboxwidth = titlebox.getBoundingClientRect().width;
		htmlToImage.toPng(titlebox, {height: titleboxheight, width: titleboxwidth, quality: 1}).then((dataUrl) => {
			var img = new Image();
			img.src = dataUrl;
			img.onload = () => {
				newCtx.drawImage(img, 0, 0, img.width, img.height, 0, 0, titleboxwidth, titleboxheight);
				previewCtx.drawImage(img, 0, 0, img.width, img.height, 0, 0, titleboxwidth / 2, titleboxheight / 2);
			};
		});
	};

	const downloadVideo = async () => {
		if (isMobile) {
			toast.error('Please use a computer to download graphics', {autoClose: 5000});
			return;
		} else if (!isChrome && !isFirefox) {
			toast.error('Please use Chrome or Firefox to download graphics', {autoClose: 5000});
			return;
		} else {
			await openVideoModal();
			document.getElementById('video-status-text').innerHTML = '<span class="animated-ellipsis">Starting recorder</span>';
			let height = settings.postHeight;
			let width = settings.postWidth;
			const setup = setupCanvases(height, width);
			let newCanvas = setup.newCanvas;
			let newCtx = setup.newCtx;
			let previewCtx = setup.previewCtx;
			let teams = document.querySelectorAll('.boxwrap');
			let teamArray = Array.from(teams);
			if (settings.videoReverse && settings.videoDirection === 'fromright' && settings.type === 'post') {
				teamArray = teamArray.reverse();
			}
			drawHeader(newCtx, previewCtx);
			const chunks = [];
			const stream = newCanvas.captureStream(30);
			let rec = new MediaRecorder(stream, {mimeType: 'video/webm;codecs=h264'});
			let inlinecanceled = false;
			rec.ondataavailable = (e) => {
				chunks.push(e.data);
			};
			let startTime;
			rec.onstop = (e) => {
				if (!canceled) {
					const duration = Date.now() - startTime;
					const buggyBlob = new Blob(chunks, {type: 'video/webm'});
					ysFixWebmDuration(buggyBlob, duration, {logger: false}).then((fixedBlob) => {
						exportVid(fixedBlob);
					});
				} else {
					setMediaRecorder({});
					inlinecanceled = true;
				}
			};
			setMediaRecorder(rec);
			const preSorted = await getTeamImages(teamArray);
			const sorted = preSorted.sort((a, b) => a.order - b.order);
			document.getElementById('video-status-text').textContent = 'Recording';
			document.getElementById('recording-indicator').style.visibility = 'visible';
			rec.start();
			startTime = Date.now();
			for (const teamCanvas of sorted) {
				await drawTeamCanvas(teamCanvas, newCanvas, newCtx, previewCtx, stream, teamArray);
				if (inlinecanceled) break;
				if (teamCanvas.order === teamArray.length - 1) {
					document.getElementById('recording-indicator').style.visibility = 'hidden';
					rec.stop();
				}
			}
		}
	};

	const getTeamImages = async (teamArray) => {
		return new Promise((resolve, reject) => {
			let teamCanvases = [];
			teamArray.forEach((team, i) => {
				let playerImage;
				let previousHeight;
				if (team.querySelector('.playerimage')) {
					playerImage = team.querySelector('.playerimage');
					previousHeight = playerImage.style.height;
					if (Number(previousHeight.replace('px', '')) > team.getBoundingClientRect().height) {
						playerImage.style.height = team.getBoundingClientRect().height * 1.15 + 'px';
					}
				}
				let boxBorder;
				if (team.querySelector('.boxborder')) {
					boxBorder = team.querySelector('.boxborder');
					boxBorder.style.height = '4px';
				}
				const height = team.getBoundingClientRect().height;
				const width = team.getBoundingClientRect().width;
				htmlToImage
					.toPng(team, {height, width, quality: 1})
					.then(async (dataUrl) => {
						if (playerImage) {
							playerImage.style.height = previousHeight;
						}
						if (boxBorder) {
							boxBorder.style.height = '2px';
						}
						var img = new Image();
						img.src = dataUrl;
						teamCanvases.push({image: img, rect: team.getBoundingClientRect(), order: i});
						if (teamCanvases.length === teamArray.length) {
							resolve(teamCanvases);
						}
					})
					.catch((error) => {
						toast.error('Video could not be created. Please try again.');
						openVideoModal();
					});
			});
		});
	};

	const drawTeamCanvas = async (teamCanvas, newCanvas, newCtx, previewCtx, stream, teamArray) => {
		return new Promise((resolve, reject) => {
			const parentRect = document.getElementById('post').getBoundingClientRect();
			const relativePos = {
				top: teamCanvas.rect.top - parentRect.top,
				left: teamCanvas.rect.left - parentRect.left,
			};
			let x = newCanvas.width;
			let y = relativePos.top;
			if (settings.videoDirection === 'frombottom') {
				x = relativePos.left;
				y = newCanvas.height;
			}
			let previousx;
			let previousy;
			let extraFrames = 0;
			const animate = () => {
				if (previousx || previousy) {
					newCtx.fillStyle = settings.headerColor;
					newCtx.fillRect(previousx, previousy, teamCanvas.rect.width, teamCanvas.rect.height);
				}
				if (previousx || previousy) {
					previewCtx.fillStyle = settings.headerColor;
					previewCtx.fillRect(previousx / 2, previousy / 2, teamCanvas.rect.width / 2, teamCanvas.rect.height / 2);
				}
				previousx = x;
				previousy = y;
				if (settings.videoDirection === 'frombottom') {
					y -= newCanvas.height / 100;
					if (y < relativePos.top) y = relativePos.top;
					if (y > relativePos.top) requestAnimationFrame(animate);
				} else {
					x -= newCanvas.width / 100;
					if (x < relativePos.left) x = relativePos.left;
					if (x > relativePos.left) requestAnimationFrame(animate);
				}
				newCtx.drawImage(teamCanvas.image, 0, 0, teamCanvas.image.width, teamCanvas.image.height, x, y, teamCanvas.rect.width, teamCanvas.rect.height);
				previewCtx.drawImage(teamCanvas.image, 0, 0, teamCanvas.image.width, teamCanvas.image.height, x / 2, y / 2, teamCanvas.rect.width / 2, teamCanvas.rect.height / 2);
				if (x === relativePos.left && y === relativePos.top) {
					if (teamCanvas.order === teamArray.length - 1) {
						if (extraFrames < 100) {
							extraFrames++;
							newCtx.drawImage(teamCanvas.image, 0, 0, teamCanvas.image.width, teamCanvas.image.height, x, y, teamCanvas.rect.width, teamCanvas.rect.height);
							previewCtx.drawImage(teamCanvas.image, 0, 0, teamCanvas.image.width, teamCanvas.image.height, x / 2, y / 2, teamCanvas.rect.width / 2, teamCanvas.rect.height / 2);
							requestAnimationFrame(animate);
						} else {
							resolve();
						}
					} else {
						resolve();
					}
				}
			};
			animate();
		});
	};

	const exportVid = async (blob) => {
		const a = document.createElement('a');
		a.download = `${title}.webm`;
		a.href = URL.createObjectURL(blob);
		a.click();
		document.getElementById('video-status-text').innerHTML = '<a target="_blank" href="https://cloudconvert.com/webm-to-mp4">Finished. Convert the downloaded WEBM file to MOV or MP4 here.</a>';
	};

	const openVideoModal = async () => {
		if (isModalOpen('video')) {
			await setCanceled(true);
			await toggleModal('video');
			if (mediaRecorder.state === 'recording') {
				mediaRecorder.stop();
			}
		} else {
			setCanceled(false);
			toggleModal('video');
		}
	};

	const changeVideoDirection = (e) => {
		updateSetting('videoDirection', e.target.getAttribute('data-direction'));
	};

	const changeVideoOrder = (e) => {
		let bool = e.target.getAttribute('data-reverse') === 'reverse' ? true : false;
		updateSetting('videoReverse', bool);
	};

	return (
		<span className='form-buttons'>
			{(settings.type === 'post' || settings.type === 'grid') && (
				<span className='downloadVideoBtn'>
					<Tippy content={<span>Download Video</span>}>
						<button
							className='newButton relative'
							onClick={downloadVideo}>
							<i className='fa-solid fa-video'></i>
						</button>
					</Tippy>
					<Popover
						isOpen={popover1}
						reposition={true}
						containerStyle={{'z-index': '1000'}}
						onClickOutside={() => setPopover1(false)}
						positions={['bottom', 'top', 'right', 'left']}
						content={
							<div className='popover-content popover2'>
								<h5>Direction</h5>
								<button
									className={`${settings.videoDirection === 'fromright' ? 'active-toggle ' : ''}newButton leftToggle`}
									data-direction='fromright'
									onClick={changeVideoDirection}>
									Animate from right
								</button>
								<button
									className={`${settings.videoDirection === 'frombottom' ? 'active-toggle ' : ''}newButton rightToggle`}
									data-direction='frombottom'
									onClick={changeVideoDirection}>
									Animate from bottom
								</button>
								{settings.videoDirection === 'fromright' && settings.type === 'post' && (
									<span>
										<h5>Order</h5>
										<button
											className={`${!settings.videoReverse ? 'active-toggle ' : ''}newButton leftToggle`}
											data-reverse='not-reverse'
											onClick={changeVideoOrder}>{`Normal order (1 - ${team.length})`}</button>
										<button
											className={`${settings.videoReverse ? 'active-toggle ' : ''}newButton rightToggle`}
											data-reverse='reverse'
											onClick={changeVideoOrder}>{`Reverse order (${team.length} - 1)`}</button>
									</span>
								)}
							</div>
						}>
						<button
							className='chevron'
							data-popover='popover1'
							onClick={() => setPopover1(!popover1)}>
							<i className='fas fa-chevron-down no-click'></i>
						</button>
					</Popover>
				</span>
			)}
			<Tippy content={<span>Download Image</span>}>
			<button
				className='newButton downloadImageBtn'
				onClick={download}>
				<i className='fa-solid fa-download'></i>
			</button>
			</Tippy>
		</span>
	);
}
