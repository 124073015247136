export const tierOptions = [
	{value: 'actualheader', label: 'Header Styles'},
	{value: 'header', label: 'Title Styles'},
	{value: 'subtitle', label: 'Subtitle Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'name', label: 'Top Styles'},
	{value: 'stat', label: 'Bottom Styles'},
	{value: 'post', label: 'Post Styles'},
	{value: 'tier', label: 'Tier Styles'},
	{value: 'footer', label: 'Footer Styles'},
];

export const bracketOptions = [
	{value: 'actualheader', label: 'Header Styles'},
	{value: 'header', label: 'Title Styles'},
	{value: 'subtitle', label: 'Subtitle Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'name', label: 'Top Styles'},
	{value: 'stat', label: 'Bottom Styles'},
	{value: 'post', label: 'Post Styles'},
];

export const gridOptions = [
	{value: 'actualheader', label: 'Header Styles'},
	{value: 'header', label: 'Title Styles'},
	{value: 'subtitle', label: 'Subtitle Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'name', label: 'Top Styles'},
	{value: 'stat', label: 'Bottom Styles'},
	{value: 'post', label: 'Post Styles'},
	{value: 'footer', label: 'Footer Styles'},
];

export const gridImageOptions = [
	{value: 'actualheader', label: 'Header Styles'},
	{value: 'header', label: 'Title Styles'},
	{value: 'subtitle', label: 'Subtitle Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'name', label: 'Top Styles'},
	{value: 'stat', label: 'Bottom Styles'},
	{value: 'image', label: 'Image Styles'},
	{value: 'post', label: 'Post Styles'},
	{value: 'footer', label: 'Footer Styles'},
];

export const scoreOptions = [
	{value: 'actualheader', label: 'Header Styles'},
	{value: 'header', label: 'Title Styles'},
	{value: 'subtitle', label: 'Subtitle Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'name', label: 'Score Styles'},
	{value: 'stat', label: 'Info Styles'},
	{value: 'rank', label: 'Rank Styles'},
	{value: 'post', label: 'Post Styles'},
	{value: 'footer', label: 'Footer Styles'},
];

export const matchupOptions = [
	{value: 'team', label: 'Team Styles'},
	{value: 'name', label: 'Score Styles'},
	{value: 'stat', label: 'Status Styles'},
	{value: 'description', label: 'Description Styles'},
	{value: 'rank', label: 'Rank Styles'},
	{value: 'points', label: 'Record Styles'},
	{value: 'post', label: 'Post Styles'},
	{value: 'image', label: 'Image Styles'},
];

export const postOptions = [
	{value: 'actualheader', label: 'Header Styles'},
	{value: 'header', label: 'Title Styles'},
	{value: 'subtitle', label: 'Subtitle Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'name', label: 'Name Styles'},
	{value: 'stat', label: 'Stat Styles'},
	{value: 'post', label: 'Post Styles'},
	{value: 'points', label: 'Point Styles'},
	{value: 'player', label: 'Player Image Styles'},
	{value: 'rank', label: 'Rank Styles'},
	{value: 'footer', label: 'Footer Styles'},
];

export const postImageOptions = [
	{value: 'actualheader', label: 'Header Styles'},
	{value: 'header', label: 'Title Styles'},
	{value: 'subtitle', label: 'Subtitle Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'name', label: 'Name Styles'},
	{value: 'stat', label: 'Stat Styles'},
	{value: 'image', label: 'Image Styles'},
	{value: 'post', label: 'Post Styles'},
	{value: 'points', label: 'Point Styles'},
	{value: 'player', label: 'Player Image Styles'},
	{value: 'rank', label: 'Rank Styles'},
	{value: 'footer', label: 'Footer Styles'},
];

export const newsOptions = [
	{value: 'header', label: 'Title Styles'},
	{value: 'subtitle', label: 'Text Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'image', label: 'Image Styles'},
	{value: 'post', label: 'Post Styles'},
];

export const chartOptions = [
	{value: 'actualheader', label: 'Header Styles'},
	{value: 'header', label: 'Title Styles'},
	{value: 'subtitle', label: 'Subtitle Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'post', label: 'Post Styles'},
	{value: 'chart', label: 'Chart Styles'},
	{value: 'footer', label: 'Footer Styles'},
];

export const comparisonOptions = [
	{value: 'actualheader', label: 'Header Styles'},
	{value: 'header', label: 'Title Styles'},
	{value: 'subtitle', label: 'Subtitle Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'name', label: 'Name Styles'},
	{value: 'stat', label: 'Stat Styles'},
	{value: 'post', label: 'Post Styles'},
	{value: 'labels', label: 'Label Styles'},
	{value: 'footer', label: 'Footer Styles'},
];

export const lineupOptions = [
	{value: 'header', label: 'Title Styles'},
	{value: 'name', label: 'Name Styles'},
	{value: 'stat', label: 'Position Styles'},
	{value: 'team', label: 'Team Styles'},
	{value: 'image', label: 'Image Styles'},
	{value: 'post', label: 'Post Styles'},
];