import useSettingsStore from '../../../stores/settings.store';

export default function Footer (props: {
    selectSettings: (arg0: string) => void;
}): React.JSX.Element {
	const { settings } = useSettingsStore();

    const alignment = settings.footerAlignment;
    const style: any = { justifyContent: alignment };
    if (settings.type.includes('grid') && settings.gridColumns) {
        style.gridColumnEnd = String(Number(settings.gridColumns) + 1);
    }
    return (
        <div
            className='graphic-footer'
            id='graphicFooter'
            style={style}
            onClick={() => props.selectSettings('footer')}>
            {alignment === 'space-between' && (
                <span
                    className='footer-text'
                    style={{ transform: 'translateX(-50%)' }}>
                    {settings.footerText}
                </span>
            )}
            <span className='footer-text'>{settings.footerText}</span>
            {alignment === 'space-between' && (
                <span
                    className='footer-text'
                    style={{ transform: 'translateX(50%)' }}>
                    {settings.footerText}
                </span>
            )}
        </div>
    );
}
