export const quick2 = {
	type: 'post',
	headerSize: 20,
	nameSize: 24,
	statSize: 23,
	logoOrder: 0,
	logoOpacity: 100,
	logoSize: 60,
	headerMargin: 0,
	headerSpacing: 0,
	nameMargin: 0,
	headerFont: 'roboto condensed',
	nameFont: 'roboto condensed',
	statFont: 'roboto condensed',
	headerColor: '#000000',
	headerFontColor: '#ffffff',
	watermark: '',
	watermarkFont: 'roboto condensed',
	watermarkSize: 30,
	watermarkWidth: 75,
	statMargin: 0,
	watermarkColor: '#ffffff',
	nameShadow: 0,
	borderColor: '#ffffff',
	nameSpacing: 0,
	statSpacing: 0,
	statShadow: 0,
	logoPosition: 292,
	overlap: false,
	statMargin2: 0,
	lineHeight: 0.9,
	nameBackground: '#000000',
	statBackground: '#000000',
	nameColor: '#ffffff',
	statColor: '#ffffff',
	imageHorizontalNews: 0,
	imageVerticalNews: 0,
	imageZoomNews: 100,
	headerSizeNews: 20,
	headerFontNews: 'roboto condensed',
	headerMarginNews: 0,
	headerSpacingNews: 0,
	headerColorNews: '#ffffff',
	headerFontColorNews: '#000000',
	logoOpacityNews: 100,
	nameColorNews: '#ffffff',
	logoSizeNews: 500,
	orientation: 'leftright',
	orientationNews: 'leftright',
	orientationMatchup: 'topbottom',
	borderRadius: 5,
	scoreMargin: 5,
	paneSplit: [50, 50],
	paneSplitNews: [50, 50],
	paneSplitMatchup: [80, 20],
	duplicate: false,
	logoShadow: 0,
	nameMargin2: 10,
	postWidth: 680,
	postHeight: 680,
	postWidthNews: 680,
	postHeightNews: 680,
	tierFont: 'roboto condensed',
	tierColor: '#ffffff',
	tierSize: 75,
	duplicateLogoSize: 500,
	duplicateLogoPosition: 0,
	duplicateLogoOpacity: 10,
	gridMargin: 1,
	logoFlip: true,
	scoreMinHeight: 100,
	videoDirection: 'fromright',
	videoReverse: false,
	headerOutlineColor: '#ffffff',
	nameOutlineColor: '#ffffff',
	statOutlineColor: '#ffffff',
	headerOutlineSize: 0,
	nameOutlineSize: 0,
	statOutlineSize: 0,
	headerTransform: 'none',
	nameTransform: 'none',
	statTransform: 'none',
	nameShadowColor: '#000000',
	statShadowColor: '#000000',
	tierTitle: 'left',
	pyramid: false,
	newsStyle: {
		value: 'style-1',
		label: 'Style 1',
	},
	postBackground: null,
	titleHeight: 10,
	subtitleFontSize: 10,
	subtitleMargin: 0,
	subtitleSpacing: 0,
	subtitleFont: 'roboto condensed',
	subtitleFontColor: '#ffffff',
	subtitleOutlineColor: '#ffffff',
	subtitleOutlineSize: 0,
	subtitleTransform: 'none',
	backgroundColor: 'single',
	postColumns: null,
	gridColumns: null,
	tierSplit: 8,
	fivelessBreak: 8,
	helmets: false,
	rankSize: 23,
	rankFont: 'roboto condensed',
	rankFontColor: '#ffffff',
	rankMargin: 0,
	rankSpacing: 0,
	rankOutlineSize: 2,
	rankOutlineColor: '#000000',
	logoSizeGrid: 100,
	logoSizeTier: 100,
	pointsSize: 20,
	pointsFont: 'roboto condensed',
	pointsFontColor: '#ffffff',
	pointsMargin: 0,
	pointsSpacing: -1.2,
	pointsOutlineSize: 0,
	pointsOutlineColor: '#000000',
	pointsBackground: '#000000',
	playerHeight: 1,
	statLineHeight: 1,
	indexAxis: 'y',
	chartType: {
		value: 'bar',
		label: 'Bar Chart',
	},
	labelSize: 14,
	labelColor: '#ffffff',
	labelFont: 'roboto condensed',
	chartBorderColor: '#ffffff',
	chartBorderWidth: 0.5,
	chartLogoSize: 75,
	playerMargin: 0,
	lighterLogos: false,
	texture: null,
	textureSize: 5,
	textureOpacity: 10,
	rankBackground: '#000000',
	boxMargin: 0,
	teamBackground: 'gradient',
	actualHeaderColor: '#00000000',
	rankWidth: 40,
	boxRankOutlineSize: 0,
	backgroundGradientDirectionDeg: '180deg',
	backgroundGradientStops: [
		{
			color: '#013369',
			percentage: 0,
		},
		{
			color: '#D50A0A',
			percentage: 100,
		},
	],
	gradientDirection: '90deg',
	gradientDirectionNews: '0deg',
	gradientType: 'linear-gradient',
	gradientStops: [
		{
			color: 'secondary',
			percentage: 0,
		},
		{
			color: 'mid',
			percentage: 50,
		},
		{
			color: 'primary',
			percentage: 100,
		},
	],
	gradientTypeNews: 'linear-gradient',
	gradientStopsNews: [
		{
			color: 'secondary',
			percentage: 0,
		},
		{
			color: 'mid',
			percentage: 50,
		},
		{
			color: 'primary',
			percentage: 100,
		},
	],
};
