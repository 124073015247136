export const fontOptions = [
	{value: 'adidas', label: 'Adidas', className: 'adidas'},
	{value: 'american captain', label: 'American Captain', className: 'american captain'},
	{value: 'anton', label: 'Anton', className: 'anton'},
	{value: 'aurach', label: 'Aurach', className: 'aurach'},
	{value: 'beantown', label: 'Beantown', className: 'beantown'},
	{value: 'bebas neue', label: 'Bebas Neue', className: 'bebas neue'},
	{value: 'berlina', label: 'Berlina', className: 'berlina'},
	{value: 'carbon', label: 'Carbon', className: 'carbon'},
	{value: 'edo', label: 'Edo', className: 'edo'},
	{value: 'enfatica', label: 'Enfatica', className: 'enfatica'},
	{value: 'exo', label: 'Exo', className: 'exo'},
	{value: 'graduate', label: 'Graduate', className: 'graduate'},
	{value: 'hemi', label: 'Hemi', className: 'hemi'},
	{value: 'jersey', label: 'Jersey', className: 'jersey'},
	{value: 'league gothic', label: 'League Gothic', className: 'leaguegothic'},
	{value: 'league spartan', label: 'League Spartan', className: 'leaguespartan'},
	{value: 'lemon milk light', label: 'Lemon Milk Light', className: 'lemon milk light'},
	{value: 'lemon milk reg', label: 'Lemon Milk Reg', className: 'lemon milk reg'},
	{value: 'metropolis', label: 'Metropolis', className: 'metropolis'},
	{value: 'montserrat', label: 'Montserrat', className: 'montserrat'},
	{value: 'new athletic', label: 'New Athletic', className: 'new athletic'},
	{value: 'octin', label: 'Octin', className: 'octin'},
	{value: 'open sans', label: 'Open Sans', className: 'opensans'},
	{value: 'ostrich sans', label: 'Ostrich Sans', className: 'ostrichsans'},
	{value: 'playball', label: 'Playball', className: 'playball'},
	{value: 'pop warner', label: 'Pop Warner', className: 'popwarner'},
	{value: 'roboto bold', label: 'Roboto Bold', className: 'robotobold'},
	{value: 'roboto condensed', label: 'Roboto Condensed', className: 'robotocondensed'},
	{value: 'soccer', label: 'Soccer', className: 'soccer'},
	{value: 'staubach', label: 'Staubach', className: 'staubach'},
	{value: 'stadium', label: 'Stadium', className: 'stadium'},
	{value: 'the bold one', label: 'The Bold One', className: 'the bold one'},
	{value: 'true lies', label: 'True Lies', className: 'true lies'},
	{value: 'uberlin', label: 'Uberlin', className: 'uberlin'},
	{value: 'ubuntu', label: 'Ubuntu', className: 'ubuntu'},
	{value: 'uniform reg', label: 'Uniform Reg', className: 'uniform reg'},
	{value: 'uniform bold', label: 'Uniform Bold', className: 'uniform bold'},
	{value: 'uni sans', label: 'Uni Sans', className: 'unisans'},
	{value: 'varsity', label: 'Varsity', className: 'varsity'},
];
