export const mlbTeams = [
	{
		abbreviation: 'ARI',
		abbreviation2: 'DBACKS',
		full: 'DIAMONDBACKS',
		full2: 'D-BACKS',
		primary: '#a71930',
		secondary: '#e3d4ad',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/ari.png)', 'url(/logos/mlb/ari2.png)', 'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/ari.png)'],
	},
	{
		abbreviation: 'ATL',
		full: 'BRAVES',
		primary: '#13274f',
		secondary: '#ce1141',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/atl.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/atlanta_braves.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/atl.png)',
			'url(/logos/mlb/bravesfull.png)',
		],
	},
	{
		abbreviation: 'BAL',
		full: 'ORIOLES',
		primary: '#df4601',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/bal.png)', 'url(/logos/mlb/bal2.png)'],
	},
	{
		abbreviation: 'BOS',
		full: 'RED SOX',
		primary: '#bd3039',
		secondary: '#0c2340',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/bos.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/boston_red_sox.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/bos.png)',
		],
	},
	{
		abbreviation: 'CHC',
		full: 'CUBS',
		primary: '#0e3386',
		secondary: '#cc3433',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/chc.png)', 'url(/logos/mlb/cubs.png)'],
	},
	{
		abbreviation: 'CHW',
		full: 'WHITE SOX',
		primary: '#27251f',
		secondary: '#c4ced4',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/chw.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/chw.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/chw.png)',
		],
	},
	{
		abbreviation: 'CIN',
		full: 'REDS',
		primary: '#000000',
		secondary: '#c6011f',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/cin.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/cin.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/cincinnati_reds.png)',
		],
	},
	{
		abbreviation: 'CLE',
		full: 'GUARDIANS',
		primary: '#0c2340',
		secondary: '#e31937',
		logos: ['url(/logos/mlb/cle.png)', 'url(https://a.espncdn.com/i/teamlogos/mlb/500/cle.png)'],
	},
	{
		abbreviation: 'COL',
		full: 'ROCKIES',
		primary: '#33006f',
		secondary: '#c4ced4',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/col.png)', 'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/col.png)', 'url(/logos/mlb/rockies.png)'],
	},
	{
		abbreviation: 'DET',
		full: 'TIGERS',
		primary: '#fa4616',
		secondary: '#0c2340',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/det.png)', 'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/det.png)'],
	},
	{
		abbreviation: 'HOU',
		full: 'ASTROS',
		primary: '#002d62',
		secondary: '#eb6e1f',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/hou.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/houston_astros.png)'],
	},
	{
		abbreviation: 'KC',
		abbreviation2: 'KCR',
		full: 'ROYALS',
		primary: '#bd9b60',
		secondary: '#004687',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/kc.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/kc.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/kc.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/kansas_city_royals.png)',
		],
	},
	{
		abbreviation: 'LAA',
		full: 'ANGELS',
		primary: '#003263',
		secondary: '#ba0021',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/laa.png)', 'url(/logos/mlb/laa.png)'],
	},
	{
		abbreviation: 'LAD',
		full: 'DODGERS',
		primary: '#005a9c',
		secondary: '#a5acaf',
		logos: [
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/los_angeles_dodgers.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/lad.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/lad.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/lad.png)',
			'url(/logos/mlb/dodgers.png)',
		],
	},
	{
		abbreviation: 'MIA',
		full: 'MARLINS',
		primary: '#00a3e0',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/mia.png)', 'url(/logos/mlb/marlins.png)'],
	},
	{
		abbreviation: 'MIL',
		full: 'BREWERS',
		primary: '#ffc52f',
		secondary: '#12284b',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/mil.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/milwaukee_brewers.png)'],
	},
	{
		abbreviation: 'MIN',
		full: 'TWINS',
		primary: '#002b5c',
		secondary: '#d31145',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/min.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/min.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/min.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/minnesota_twins.png)',
		],
	},
	{
		abbreviation: 'NYY',
		full: 'YANKEES',
		primary: '#0c2340',
		secondary: '#6f6f6f',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/nyy.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/nyy.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/nyy.png)',
			'url(/logos/mlb/yankees.png)',
		],
	},
	{
		abbreviation: 'NYM',
		full: 'METS',
		primary: '#002d72',
		secondary: '#ff5910',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/nym.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/nym.png)',
			'url(https://upload.wikimedia.org/wikipedia/en/thumb/7/7b/New_York_Mets.svg/1200px-New_York_Mets.svg.png)',
		],
	},
	{
		abbreviation: 'OAK',
		full: 'ATHLETICS',
		full2: "A's",
		primary: '#efb21e',
		secondary: '#003831',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/oak.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/oakland_athletics.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/oak.png)',
		],
	},
	{
		abbreviation: 'PHI',
		full: 'PHILLIES',
		primary: '#002d72',
		secondary: '#e81828',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/phi.png)',
			'url(https://upload.wikimedia.org/wikipedia/en/thumb/f/f0/Philadelphia_Phillies_%282019%29_logo.svg/1200px-Philadelphia_Phillies_%282019%29_logo.svg.png)',
		],
	},
	{
		abbreviation: 'PIT',
		full: 'PIRATES',
		primary: '#27251f',
		secondary: '#fdb827',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/pit.png)', 'url(/logos/mlb/pit.png)', 'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/pit.png)'],
	},
	{
		abbreviation: 'SD',
		abbreviation2: 'SDP',
		full: 'PADRES',
		primary: '#ffc425',
		secondary: '#2f241d',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/sd.png)', 'url(/logos/mlb/sdp.png)', 'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/sd.png)'],
	},
	{
		abbreviation: 'SF',
		abbreviation2: 'SFG',
		full: 'GIANTS',
		primary: '#27251f',
		secondary: '#fd5a1e',
		logos: ['url(https://cdn.bleacherreport.net/images/team_logos/328x328/san_francisco_giants.png)', 'url(https://a.espncdn.com/i/teamlogos/mlb/500/sf.png)'],
	},
	{
		abbreviation: 'SEA',
		full: 'MARINERS',
		primary: '#0c2c56',
		secondary: '#005c5c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/sea.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/seattle_mariners.png)'],
	},
	{
		abbreviation: 'STL',
		full: 'CARDINALS',
		primary: '#c41e3a',
		secondary: '#0c2340',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/stl.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/stl.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/st_louis_cardinals.png)',
		],
	},
	{
		abbreviation: 'TB',
		abbreviation2: 'TBR',
		full: 'RAYS',
		primary: '#8fbce6',
		secondary: '#092c5c',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/tb.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/tampa_bay_rays.png)',
			'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/tb.png)',
		],
	},
	{
		abbreviation: 'TEX',
		full: 'RANGERS',
		primary: '#003278',
		secondary: '#c0111f',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/tex.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/texas_rangers.png)'],
	},
	{
		abbreviation: 'TOR',
		full: 'BLUE JAYS',
		full2: 'JAYS',
		primary: '#134a8e',
		secondary: '#e8291c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/tor.png)', 'url(/logos/mlb/tor.png)'],
	},
	{
		abbreviation: 'WAS',
		abbreviation2: 'WSH',
		abbreviation3: 'WSN',
		full: 'NATIONALS',
		full2: 'NATS',
		primary: '#14225a',
		secondary: '#ab0003',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/scoreboard/wsh.png)', 'url(/logos/mlb/was.png)', 'url(https://a.espncdn.com/i/teamlogos/mlb/500-dark/scoreboard/wsh.png)'],
	},
	{
		abbreviation: 'MLB',
		primary: '#004684',
		secondary: '#ed174b',
		logos: ['url(https://assets.espn.go.com/i/espn/misc_logos/500/mlb.png)'],
	},
	{
		abbreviation: 'AL',
		primary: '#004684',
		secondary: '#ed174b',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/al.png)'],
	},
	{
		abbreviation: 'NL',
		primary: '#004684',
		secondary: '#ed174b',
		logos: ['url(https://a.espncdn.com/i/teamlogos/mlb/500/nl.png)'],
	},
];

export const nflTeams = [
	{
		abbreviation: 'ARI',
		full: 'CARDINALS',
		primary: '#97233f',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/ari.png)'],
		helmet: 'url(/logos/helmets/ari.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/0/04/Arizona_Cardinals_wordmark.svg/1024px-Arizona_Cardinals_wordmark.svg.png)',
		outline: 'url(/logos/outlines/ari.png)',
	},
	{
		abbreviation: 'ATL',
		full: 'FALCONS',
		primary: '#a71930',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/atl.png)'],
		helmet: 'url(/logos/helmets/atl.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/e/ec/Atlanta_Falcons_wordmark.svg/1024px-Atlanta_Falcons_wordmark.svg.png)',
		outline: 'url(/logos/outlines/atl.png)',
	},
	{
		abbreviation: 'BAL',
		full: 'RAVENS',
		primary: '#241773',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/bal.png)'],
		helmet: 'url(/logos/helmets/bal.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Baltimore_Ravens_wordmark.svg/1024px-Baltimore_Ravens_wordmark.svg.png)',
		outline: 'url(/logos/outlines/bal.png)',
	},
	{
		abbreviation: 'BUF',
		full: 'BILLS',
		primary: '#00338d',
		secondary: '#c60c30',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/buf.png)'],
		helmet: 'url(/logos/helmets/buf.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/b/bb/Buffalo_Bills_wordmark.svg/1024px-Buffalo_Bills_wordmark.svg.png)',
		outline: 'url(/logos/outlines/buf.png)',
	},
	{
		abbreviation: 'CAR',
		full: 'PANTHERS',
		primary: '#0085ca',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/car.png)'],
		helmet: 'url(/logos/helmets/car.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/d/dd/Carolina_Panthers_wordmark.svg/1024px-Carolina_Panthers_wordmark.svg.png)',
		outline: 'url(/logos/outlines/car.png)',
	},
	{
		abbreviation: 'CHI',
		full: 'BEARS',
		primary: '#0b162a',
		secondary: '#c83803',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/chi.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/chicago_bears.png)'],
		helmet: 'url(/logos/helmets/chi.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Chicago_Bears_wordmark.svg/1024px-Chicago_Bears_wordmark.svg.png)',
		outline: 'url(/logos/outlines/chi.png)',
	},
	{
		abbreviation: 'CIN',
		full: 'BENGALS',
		primary: '#fb4f14',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/cin.png)'],
		helmet: 'url(/logos/helmets/cin.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/8/8e/Cincinnati_Bengals_wordmark.svg/1024px-Cincinnati_Bengals_wordmark.svg.png)',
		outline: 'url(/logos/outlines/cin.png)',
	},
	{
		abbreviation: 'CLE',
		full: 'BROWNS',
		primary: '#311d00',
		secondary: '#ff3c00',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/cle.png)'],
		helmet: 'url(/logos/helmets/cle.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/a/aa/Cleveland_Browns_wordmark.svg/1024px-Cleveland_Browns_wordmark.svg.png)',
		outline: 'url(/logos/outlines/cle.png)',
	},
	{
		abbreviation: 'DAL',
		full: 'COWBOYS',
		primary: '#041e42',
		secondary: '#869397',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/dal.png)'],
		helmet: 'url(/logos/helmets/dal.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/b/be/Cowboys_wordmark.svg/1024px-Cowboys_wordmark.svg.png)',
		outline: 'url(/logos/outlines/dal.png)',
	},
	{
		abbreviation: 'DEN',
		full: 'BRONCOS',
		primary: '#fb4f14',
		secondary: '#002244',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/den.png)'],
		helmet: 'url(/logos/helmets/den.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/2/25/Denver_Broncos_wordmark.svg/1024px-Denver_Broncos_wordmark.svg.png)',
		outline: 'url(/logos/outlines/den.png)',
	},
	{
		abbreviation: 'DET',
		full: 'LIONS',
		primary: '#0076b6',
		secondary: '#b0b7bc',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/det.png)'],
		helmet: 'url(/logos/helmets/det.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/2/26/Detroit_Lions_wordmark.svg/1024px-Detroit_Lions_wordmark.svg.png)',
		outline: 'url(/logos/outlines/det.png)',
	},
	{
		abbreviation: 'GB',
		abbreviation2: 'GNB',
		full: 'PACKERS',
		primary: '#203731',
		secondary: '#ffb612',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/gb.png)'],
		helmet: 'url(/logos/helmets/gb.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/1/17/Green_Bay_Packers_wordmark.svg/1024px-Green_Bay_Packers_wordmark.svg.png)',
		outline: 'url(/logos/outlines/gb.png)',
	},
	{
		abbreviation: 'HOU',
		full: 'TEXANS',
		primary: '#03202f',
		secondary: '#a71930',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/hou.png)', 'url(/logos/nfl/texans.png)'],
		helmet: 'url(/logos/helmets/hou.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Houston_Texans_wordmark.svg/1024px-Houston_Texans_wordmark.svg.png)',
		outline: 'url(/logos/outlines/hou.png)',
	},
	{
		abbreviation: 'IND',
		full: 'COLTS',
		primary: '#002c5f',
		secondary: '#a2aaad',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/ind.png)'],
		helmet: 'url(/logos/helmets/ind.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Indianapolis_Colts_new_wordmark.svg/1024px-Indianapolis_Colts_new_wordmark.svg.png)',
		outline: 'url(/logos/outlines/ind.png)',
	},
	{
		abbreviation: 'JAX',
		abbreviation2: 'JAC',
		full: 'JAGUARS',
		primary: '#006778',
		secondary: '#9f792c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/jax.png)'],
		helmet: 'url(/logos/helmets/jax.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/7/7b/Jacksonville_Jaguars_wordmark.svg/1024px-Jacksonville_Jaguars_wordmark.svg.png)',
		outline: 'url(/logos/outlines/jax.png)',
	},
	{
		abbreviation: 'KC',
		abbreviation2: 'KAN',
		full: 'CHIEFS',
		primary: '#e31837',
		secondary: '#ffb81c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/kc.png)'],
		helmet: 'url(/logos/helmets/kc.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/7/79/Kansas_City_Chiefs_wordmark.svg/1024px-Kansas_City_Chiefs_wordmark.svg.png)',
		outline: 'url(/logos/outlines/kc.png)',
	},
	{
		abbreviation: 'LAC',
		full: 'CHARGERS',
		primary: '#0080c6',
		secondary: '#ffc20e',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/lac.png)'],
		helmet: 'url(/logos/helmets/lac.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/b/b1/Los_Angeles_Chargers_2020_wordmark.svg/1024px-Los_Angeles_Chargers_2020_wordmark.svg.png)',
		outline: 'url(/logos/outlines/lac.png)',
	},
	{
		abbreviation: 'LAR',
		full: 'RAMS',
		primary: '#003594',
		secondary: '#ffd100',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/lar.png)', 'url(https://a.espncdn.com/i/teamlogos/nfl/500-dark/lar.png)', 'url(/logos/nfl/rams.png)'],
		helmet: 'url(/logos/helmets/lar.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/LA_Rams_wordmark.svg/1024px-LA_Rams_wordmark.svg.png)',
		outline: 'url(/logos/outlines/lar.png)',
	},
	{
		abbreviation: 'MIA',
		full: 'DOLPHINS',
		primary: '#008e97',
		secondary: '#fc4c02',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/mia.png)'],
		helmet: 'url(/logos/helmets/mia.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/1/13/Miami_Dolphins_wordmark.svg/1024px-Miami_Dolphins_wordmark.svg.png)',
		outline: 'url(/logos/outlines/mia.png)',
	},
	{
		abbreviation: 'MIN',
		full: 'VIKINGS',
		primary: '#4f2683',
		secondary: '#ffc62f',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/min.png)'],
		helmet: 'url(/logos/helmets/min.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/9/9d/Minnesota_Vikings_wordmark.svg/1024px-Minnesota_Vikings_wordmark.svg.png)',
		outline: 'url(/logos/outlines/min.png)',
	},
	{
		abbreviation: 'NE',
		abbreviation2: 'NWE',
		full: 'PATRIOTS',
		primary: '#002244',
		secondary: '#c60c30',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/ne.png)'],
		helmet: 'url(/logos/helmets/ne.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/f/f5/New_England_Patriots_wordmark.svg/1024px-New_England_Patriots_wordmark.svg.png)',
		outline: 'url(/logos/outlines/ne.png)',
	},
	{
		abbreviation: 'NO',
		abbreviation2: 'NOR',
		full: 'SAINTS',
		primary: '#d3bc8d',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/no.png)', 'url(https://s.yimg.com/it/api/res/1.2/26a.mcqeOZM8wNz4ePm5qQ--~A/YXBwaWQ9eW5ld3M7dz0xMjAwO2g9NjMwO3E9MTAw/https://s.yimg.com/cv/apiv2/default/nfl/20190724/500x500/2019_NO_wbg.png)'],
		helmet: 'url(/logos/helmets/no.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/4/4a/New_Orleans_Saints_wordmark.svg/1024px-New_Orleans_Saints_wordmark.svg.png)',
		outline: 'url(/logos/outlines/no.png)',
	},
	{
		abbreviation: 'NYG',
		full: 'GIANTS',
		primary: '#0b2265',
		secondary: '#a71930',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/nfl/500-dark/nyg.png)',
			'url(https://a.espncdn.com/i/teamlogos/nfl/500/nyg.png)',
			'url(https://s.yimg.com/it/api/res/1.2/zr7qwz9AEPasIn9XGbF1gw--~A/YXBwaWQ9eW5ld3M7dz0xMjAwO2g9NjMwO3E9MTAw/https://s.yimg.com/cv/apiv2/default/nfl/20190724/500x500/2019_NYG_wbg.png)'
		],
		helmet: 'url(/logos/helmets/nyg.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/New_York_Giants_wordmark.svg/1024px-New_York_Giants_wordmark.svg.png)',
		outline: 'url(/logos/outlines/nyg.png)',
	},
	{
		abbreviation: 'NYJ',
		full: 'JETS',
		primary: '#125740',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500-dark/nyj.png)', 'url(https://a.espncdn.com/i/teamlogos/nfl/500/nyj.png)', 'url(https://a.espncdn.com/i/teamlogos/nfl/500/scoreboard/nyj.png)', 'url(https://a.espncdn.com/i/teamlogos/nfl/500-dark/scoreboard/nyj.png)'],
		helmet: 'url(/logos/helmets/nyj.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/New_York_Jets_wordmark.svg/1024px-New_York_Jets_wordmark.svg.png)',
		outline: 'url(/logos/outlines/nyj.png)',
	},
	{
		abbreviation: 'LV',
		abbreviation2: 'LVR',
		full: 'RAIDERS',
		full2: 'OAK',
		primary: '#a5acaf',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/lv.png)'],
		helmet: 'url(/logos/helmets/lv.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/5/51/Las_Vegas_Raiders_wordmark.svg/1024px-Las_Vegas_Raiders_wordmark.svg.png)',
		outline: 'url(/logos/outlines/lv.png)',
	},
	{
		abbreviation: 'PHI',
		full: 'EAGLES',
		primary: '#004c54',
		secondary: '#a5acaf',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/phi.png)'],
		helmet: 'url(/logos/helmets/phi.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/7/7d/Philadelphia_Eagles_wordmark.svg/1024px-Philadelphia_Eagles_wordmark.svg.png)',
		outline: 'url(/logos/outlines/phi.png)',
	},
	{
		abbreviation: 'PIT',
		full: 'STEELERS',
		primary: '#ffb612',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/pit.png)'],
		helmet: 'url(/logos/helmets/pit.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/Pittsburgh_Steelers_Script.svg/1024px-Pittsburgh_Steelers_Script.svg.png)',
		outline: 'url(/logos/outlines/pit.png)',
	},
	{
		abbreviation: 'SF',
		abbreviation2: 'SFO',
		full: '49ERS',
		primary: '#aa0000',
		secondary: '#b3995d',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/sf.png)'],
		helmet: 'url(/logos/helmets/sf.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/San_Francisco_49ers_wordmark.svg/1024px-San_Francisco_49ers_wordmark.svg.png)',
		outline: 'url(/logos/outlines/sf.png)',
	},
	{
		abbreviation: 'SEA',
		full: 'SEAHAWKS',
		primary: '#002244',
		secondary: '#69be28',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/sea.png)'],
		helmet: 'url(/logos/helmets/sea.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Seattle_Seahawks_wordmark.svg/1024px-Seattle_Seahawks_wordmark.svg.png)',
		outline: 'url(/logos/outlines/sea.png)',
	},
	{
		abbreviation: 'TB',
		abbreviation2: 'TAM',
		full: 'BUCCANEERS',
		primary: '#b6002b',
		secondary: '#34302b',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/tb.png)', 'url(/logos/nfl/bucs1.png)', 'url(/logos/nfl/bucs2.png)'],
		helmet: 'url(/logos/helmets/tb.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/a/ab/Tampa_Bay_Buccaneers_wordmark.svg/1024px-Tampa_Bay_Buccaneers_wordmark.svg.png)',
		outline: 'url(/logos/outlines/tb.png)',
	},
	{
		abbreviation: 'TEN',
		full: 'TITANS',
		primary: '#0c2340',
		secondary: '#4b92db',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/ten.png)', 'url(/logos/nfl/titans.png)'],
		helmet: 'url(/logos/helmets/ten.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/7/75/Tennessee_Titans_wordmark%2C_2018.svg/1024px-Tennessee_Titans_wordmark%2C_2018.svg.png)',
		outline: 'url(/logos/outlines/ten.png)',
	},
	{
		abbreviation: 'WAS',
		abbreviation2: 'WSH',
		full: 'COMMANDERS',
		full2: 'WASHINGTON',
		primary: '#5A1414',
		secondary: '#ffb612',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/wsh.png)', 'url(/logos/nfl/wsh.png)', 'url(https://s.yimg.com/it/api/res/1.2/SrDWa_IjACKbIuiBEMo7Ug--~A/YXBwaWQ9eW5ld3M7dz0xMjAwO2g9NjMwO3E9MTAw/https://s.yimg.com/cv/apiv2/default/nfl/20220202/500x500/washington_wbg.png)'],
		helmet: 'url(/logos/helmets/wsh.png)',
		wordmark: 'url(https://upload.wikimedia.org/wikipedia/commons/6/62/Washington_Commanders_wordmark.png)',
		outline: 'url(/logos/outlines/was.png)',
	},
	{
		abbreviation: 'OTI',
		full: 'OILERS',
		primary: '#418fde',
		secondary: '#c8102e',
		logos: ['url(/oilers.png)'],
	},
	{
		abbreviation: 'NFL',
		abbreviation2: 'FA',
		primary: '#003a74',
		secondary: '#da2128',
		logos: ['url(https://assets.espn.go.com/i/espn/misc_logos/500/nfl.png)'],
	},
	{
		abbreviation: 'NFC',
		primary: '#003a74',
		secondary: '#da2128',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/nfc.png)'],
	},
	{
		abbreviation: 'AFC',
		primary: '#003a74',
		secondary: '#da2128',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nfl/500/afc.png)'],
	},
];

export const nhlTeams = [
	{
		abbreviation: 'ANA',
		full: 'DUCKS',
		primary: '#f47a38',
		secondary: '#b9975b',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/ana.png)'],
	},
	{
		abbreviation: 'ARI',
		full: 'COYOTES',
		primary: '#8c2633',
		secondary: '#e2d6b5',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/ari.png)'],
	},
	{
		abbreviation: 'BOS',
		full: 'BRUINS',
		primary: '#ffb81c',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/bos.png)'],
	},
	{
		abbreviation: 'BUF',
		full: 'SABRES',
		primary: '#002654',
		secondary: '#fcb514',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/buf.png)'],
	},
	{
		abbreviation: 'CGY',
		abbreviation2: 'CAL',
		full: 'FLAMES',
		primary: '#c8102e',
		secondary: '#f1be48',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/cgy.png)'],
	},
	{
		abbreviation: 'CAR',
		full: 'HURRICANES',
		primary: '#cc0000',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/car.png)'],
	},
	{
		abbreviation: 'CHI',
		full: 'BLACKHAWKS',
		primary: '#cf0a2c',
		secondary: '#ff671b',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/chi.png)'],
	},
	{
		abbreviation: 'COL',
		full: 'AVALANCHE',
		primary: '#6f263d',
		secondary: '#236192',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/col.png)'],
	},
	{
		abbreviation: 'CBJ',
		abbreviation2: 'CLS',
		full: 'BLUE JACKETS',
		primary: '#002654',
		secondary: '#ce1126',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/cbj.png)'],
	},
	{
		abbreviation: 'DAL',
		full: 'STARS',
		primary: '#006847',
		secondary: '#8f8f8c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/dal.png)'],
	},
	{
		abbreviation: 'DET',
		full: 'RED WINGS',
		primary: '#ce1126',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/det.png)'],
	},
	{
		abbreviation: 'EDM',
		full: 'OILERS',
		primary: '#041e42',
		secondary: '#ff4c00',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/edm.png)'],
	},
	{
		abbreviation: 'FLA',
		full: 'PANTHERS',
		primary: '#041e42',
		secondary: '#c8102e',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/fla.png)'],
	},
	{
		abbreviation: 'LA',
		abbreviation2: 'LAK',
		abbreviation3: 'LOS',
		full: 'KINGS',
		primary: '#111111',
		secondary: '#a2aaad',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/la.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/los_angeles_kings.png)'],
	},
	{
		abbreviation: 'MIN',
		full: 'WILD',
		primary: '#a6192e',
		secondary: '#154734',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/min.png)'],
	},
	{
		abbreviation: 'MTL',
		abbreviation2: 'MON',
		full: 'CANADIENS',
		primary: '#af1e2d',
		secondary: '#192168',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/mtl.png)'],
	},
	{
		abbreviation: 'NSH',
		abbreviation2: 'NAS',
		full: 'PREDATORS',
		primary: '#ffb81c',
		secondary: '#041e42',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/nsh.png)'],
	},
	{
		abbreviation: 'NJ',
		abbreviation2: 'NJD',
		full: 'DEVILS',
		primary: '#ce1126',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/nj.png)'],
	},
	{
		abbreviation: 'NYI',
		full: 'ISLANDERS',
		primary: '#00539b',
		secondary: '#f47d30',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/nyi.png)'],
	},
	{
		abbreviation: 'NYR',
		full: 'RANGERS',
		primary: '#0038a8',
		secondary: '#ce1126',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/nyr.png)'],
	},
	{
		abbreviation: 'OTT',
		full: 'SENATORS',
		primary: '#c52032',
		secondary: '#c2912c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/ott.png)'],
	},
	{
		abbreviation: 'PHI',
		full: 'FLYERS',
		primary: '#f74902',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/phi.png)'],
	},
	{
		abbreviation: 'PIT',
		full: 'PENGUINS',
		primary: '#000000',
		secondary: '#fcb514',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/pit.png)'],
	},
	{
		abbreviation: 'SJ',
		abbreviation2: 'SJS',
		abbreviation3: 'SAN',
		full: 'SHARKS',
		primary: '#006d75',
		secondary: '#ea7200',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/sj.png)'],
	},
	{
		abbreviation: 'SEA',
		full: 'KRAKEN',
		primary: '#001628',
		secondary: '#99d9d9',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/sea.png)'],
	},
	{
		abbreviation: 'STL',
		full: 'BLUES',
		primary: '#002f87',
		secondary: '#fcb514',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/stl.png)'],
	},
	{
		abbreviation: 'TB',
		abbreviation2: 'TBL',
		abbreviation3: 'TAM',
		full: 'LIGHTNING',
		primary: '#002868',
		secondary: '#000000',
		logos: ['url(https://cdn.bleacherreport.net/images/team_logos/328x328/tampa_bay_lightning.png)'],
	},
	{
		abbreviation: 'TOR',
		full: 'MAPLE LEAFS',
		primary: '#00205b',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/tor.png)'],
	},
	{
		abbreviation: 'UTAH',
		abbreviation2: 'UTA',
		full: 'HOCKEY CLUB',
		primary: '#57B4E3',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/utah.png)'],
	},
	{
		abbreviation: 'VAN',
		full: 'CANUCKS',
		primary: '#00205b',
		secondary: '#00843d',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/van.png)'],
	},
	{
		abbreviation: 'VGK',
		abbreviation2: 'VGS',
		full: 'GOLDEN KNIGHTS',
		full2: 'KNIGHTS',
		primary: '#b4975a',
		secondary: '#333f42',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/vgs.png)'],
	},
	{
		abbreviation: 'WSH',
		abbreviation2: 'WAS',
		full: 'CAPITALS',
		primary: '#041e42',
		secondary: '#c8102e',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/wsh.png)'],
	},
	{
		abbreviation: 'WPG',
		abbreviation2: 'WIN',
		full: 'JETS',
		primary: '#041e42',
		secondary: '#ac162c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nhl/500/wpg.png)'],
	},
	{
		abbreviation: 'NHL',
		primary: '#000000',
		secondary: '#8394a1',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/espn/misc_logos/500/nhl.png)'],
	},
];

export const nbaTeams = [
	{
		abbreviation: 'ATL',
		full: 'HAWKS',
		primary: '#e03a3e',
		secondary: '#26282a',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/atl.png)', 'url(/logos/nba/atl2.png)', 'url(/logos/nba/atl3.png)', 'url(/logos/nba/atl4.png)'],
	},
	{
		abbreviation: 'BOS',
		full: 'CELTICS',
		primary: '#007a33',
		secondary: '#ba9653',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/nba/500/bos.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/boston_celtics.png)',
			'url(/logos/nba/bos2.png)',
			'url(/logos/nba/bos3.png)',
		],
	},
	{
		abbreviation: 'BRK',
		abbreviation2: 'BKN',
		full: 'NETS',
		primary: '#000000',
		secondary: '#a9a9a9',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/bkn.png)', 'https://cdn.bleacherreport.net/images/team_logos/328x328/brooklyn_nets.png', 'url(/logos/nba/brk3.png)'],
	},
	{
		abbreviation: 'CHA',
		abbreviation2: 'CHO',
		full: 'HORNETS',
		primary: '#1d1160',
		secondary: '#00788c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/cha.png)', 'url(/logos/nba/cha2.png)', 'url(/logos/nba/cha3.png)'],
	},
	{
		abbreviation: 'CHI',
		full: 'BULLS',
		primary: '#ce1141',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/chi.png)', 'url(/logos/nba/chi2.png)'],
	},
	{
		abbreviation: 'CLE',
		full: 'CAVALIERS',
		primary: '#860038',
		secondary: '#041e42',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/cle.png)', 'url(/logos/nba/cle2.png)', 'url(/logos/nba/cle3.png)', 'url(/logos/nba/cle4.png)'],
	},
	{
		abbreviation: 'DAL',
		full: 'MAVERICKS',
		primary: '#00538c',
		secondary: '#b8c4ca',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/dal.png)', 'url(/logos/nba/dal2.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/dallas_mavericks.png)'],
	},
	{
		abbreviation: 'DEN',
		full: 'NUGGETS',
		primary: '#0e2240',
		secondary: '#fec524',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/den.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/denver_nuggets.png)', 'url(/logos/nba/den3.png)'],
	},
	{
		abbreviation: 'DET',
		full: 'PISTONS',
		primary: '#c8102e',
		secondary: '#1d42ba',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/det.png)', 'url(/logos/nba/det2.png)'],
	},
	{
		abbreviation: 'GS',
		abbreviation2: 'GSW',
		full: 'WARRIORS',
		primary: '#1d428a',
		secondary: '#ffc72c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/gs.png)', 'url(/logos/nba/gs2.png)'],
	},
	{
		abbreviation: 'HOU',
		full: 'ROCKETS',
		primary: '#ce1141',
		secondary: '#000000',
		logos: ['url(https://cdn.bleacherreport.net/images/team_logos/328x328/houston_rockets.png)', 'url(https://a.espncdn.com/i/teamlogos/nba/500/hou.png)', 'url(/logos/nba/hou3.png)'],
	},
	{
		abbreviation: 'IND',
		full: 'PACERS',
		primary: '#002d62',
		secondary: '#fdbb30',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/ind.png)', 'url(/logos/nba/ind2.png)'],
	},
	{
		abbreviation: 'LAC',
		full: 'CLIPPERS',
		primary: '#c8102e',
		secondary: '#1d428a',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/lac.png)', 'url(/logos/nba/lac2.png)'],
	},
	{
		abbreviation: 'LAL',
		full: 'LAKERS',
		primary: '#552583',
		secondary: '#fdb927',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/lal.png)', 'url(/logos/nba/lal2.png)'],
	},
	{
		abbreviation: 'MEM',
		full: 'GRIZZLIES',
		primary: '#5d76a9',
		secondary: '#12173f',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/mem.png)', 'url(/logos/nba/mem2.png)'],
	},
	{
		abbreviation: 'MIA',
		full: 'HEAT',
		primary: '#98002e',
		secondary: '#f9a01b',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/mia.png)', 'url(/logos/nba/mia2.png)'],
	},
	{
		abbreviation: 'MIL',
		full: 'BUCKS',
		primary: '#eee1c6',
		secondary: '#00471b',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/mil.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/milwaukee_bucks.png)'],
	},
	{
		abbreviation: 'MIN',
		full: 'TIMBERWOLVES',
		primary: '#0c2340',
		secondary: '#9ea2a2',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/min.png)', 'url(/logos/nba/min2.png)', 'url(/logos/nba/min3.png)'],
	},
	{
		abbreviation: 'NO',
		abbreviation2: 'NOP',
		full: 'PELICANS',
		primary: '#0c2340',
		secondary: '#c8102e',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/no.png)', 'url(/logos/nba/no2.png)'],
	},
	{
		abbreviation: 'NY',
		abbreviation2: 'NYK',
		full: 'KNICKS',
		primary: '#006bb6',
		secondary: '#f58426',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/nyk.png)', 'url(https://a.espncdn.com/i/teamlogos/nba/500/scoreboard/nyk.png)'],
	},
	{
		abbreviation: 'OKC',
		full: 'THUNDER',
		primary: '#007ac1',
		secondary: '#ef3b24',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/okc.png)', 'url(/logos/nba/okc2.png)'],
	},
	{
		abbreviation: 'ORL',
		full: 'MAGIC',
		primary: '#0077c0',
		secondary: '#c4ced4',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/orl.png)', 'url(/logos/nba/orl2.png)'],
	},
	{
		abbreviation: 'PHI',
		full: '76ERS',
		primary: '#006bb6',
		secondary: '#ed174c',
		logos: ['url(https://cdn.bleacherreport.net/images/team_logos/328x328/philadelphia_76ers.png)', 'url(https://a.espncdn.com/i/teamlogos/nba/500/scoreboard/phi.png)', 'url(/logos/nba/phi3.png)'],
	},
	{
		abbreviation: 'PHX',
		abbreviation2: 'PHO',
		full: 'SUNS',
		primary: '#1d1160',
		secondary: '#e56020',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/nba/500/phx.png)',
			'url(https://a.espncdn.com/i/teamlogos/nba/500-dark/pho.png)',
			'url(https://a.espncdn.com/i/teamlogos/nba/500/scoreboard/pho.png)',
		],
	},
	{
		abbreviation: 'POR',
		full: 'TRAIL BLAZERS',
		full2: 'BLAZERS',
		primary: '#e03a3e',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/por.png)'],
	},
	{
		abbreviation: 'SAC',
		full: 'KINGS',
		primary: '#5a2d81',
		secondary: '#63727a',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/sac.png)'],
	},
	{
		abbreviation: 'SA',
		abbreviation2: 'SAS',
		full: 'SPURS',
		primary: '#c4ced4',
		secondary: '#000000',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/sa.png)', 'url(https://a.espncdn.com/i/teamlogos/nba/500/sas.png)'],
	},
	{
		abbreviation: 'TOR',
		full: 'RAPTORS',
		primary: '#000000',
		secondary: '#ce1141',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/tor.png)', 'url(/logos/nba/tor2.png)', 'url(/logos/nba/tor3.png)', 'url(https://a.espncdn.com/i/teamlogos/nba/500-dark/scoreboard/tor.png)'],
	},
	{
		abbreviation: 'UTA',
		abbreviation2: 'UTAH',
		full: 'JAZZ',
		primary: '#4F1691',
		secondary: '#79A3DC',
		logos: ['url(/logos/nba/utah1.png)', 'url(https://a.espncdn.com/i/teamlogos/nba/500/utah.png)', 'url(/logos/nba/utah2.png)'],
	},
	{
		abbreviation: 'WAS',
		abbreviation2: 'WSH',
		full: 'WIZARDS',
		primary: '#002b5c',
		secondary: '#e31837',
		logos: ['url(https://a.espncdn.com/i/teamlogos/nba/500/was.png)', 'url(/logos/nba/was2.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/washington_wizards.png)'],
	},
	{
		abbreviation: 'NBA',
		primary: '#126bb5',
		secondary: '#d81f55',
		logos: ['url(https://assets.espn.go.com/i/espn/misc_logos/500/nba.png)'],
	},
];

export const ncaaTeams = [
	{
		abbreviation: 'AAMU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2010.png)'],
		primary: '#660000',
		secondary: '#000000',
		full: 'ALABAMA A&M',
	},
	{
		abbreviation: 'ACU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2000.png)'],
		primary: '#4f2170',
		secondary: '#000000',
		full: 'ABILENE CHRISTIAN',
	},
	{
		abbreviation: 'AFA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2005.png)'],
		primary: '#a6a6a8',
		secondary: '#00308f',
		full: 'AIR FORCE',
	},
	{
		abbreviation: 'AKR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2006.png)'],
		primary: '#002147',
		secondary: '#918b4c',
		full: 'AKRON',
	},
	{
		abbreviation: 'ALA',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/333.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/333.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/alabama_crimson_tide_football.png)',
		],
		primary: '#9e1b32',
		secondary: '#828a8f',
		full: 'ALABAMA',
	},
	{
		abbreviation: 'ALB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/399.png)'],
		primary: '#461660',
		secondary: '#eeb211',
		full: 'ALBANY',
	},
	{
		abbreviation: 'ALCN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2016.png)'],
		primary: '#ce8e00',
		secondary: '#4b306a',
		full: 'ALCORN STATE',
	},
	{
		abbreviation: 'ALST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2011.png)'],
		primary: '#c99700',
		secondary: '#000000',
		full: 'ALABAMA STATE',
	},
	{
		abbreviation: 'AMCC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/357.png)'],
		primary: '#0067c5',
		secondary: '#007f3e',
		full: 'TEXAS A&M-CC',
	},
	{
		abbreviation: 'AMER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/44.png)'],
		primary: '#143d8d',
		secondary: '#c41230',
		full: 'AMERICAN',
	},
	{
		abbreviation: 'APP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2026.png)'],
		primary: '#ffcc00',
		secondary: '#222222',
		full: 'APPALACHIAN STATE',
		full2: 'APP STATE'
	},
	{
		abbreviation: 'APSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2046.png)'],
		primary: '#ba0c2f',
		secondary: '#000000',
		full: 'AUSTIN PEAY',
	},
	{
		abbreviation: 'ARIZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/12.png)'],
		primary: '#0c234b',
		secondary: '#ab0520',
		full: 'ARIZONA',
	},
	{
		abbreviation: 'ARK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/8.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/8.png)'],
		primary: '#9d2235',
		secondary: '#000000',
		full: 'ARKANSAS',
	},
	{
		abbreviation: 'ARMY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/349.png)'],
		primary: '#d3bc8d',
		secondary: '#000000',
		full: 'ARMY',
	},
	{
		abbreviation: 'ARST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2032.png)'],
		primary: '#000000',
		secondary: '#cc092f',
		full: 'ARKANSAS STATE',
	},
	{
		abbreviation: 'ASU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/9.png)'],
		primary: '#ffb310',
		secondary: '#990033',
		full: 'ARIZONA STATE',
	},
	{
		abbreviation: 'AUB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2.png)'],
		primary: '#f26522',
		secondary: '#0c2340',
		full: 'AUBURN',
	},
	{
		abbreviation: 'BALL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2050.png)'],
		primary: '#ba0c2f',
		secondary: '#000000',
		full: 'BALL STATE',
	},
	{
		abbreviation: 'BAY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/239.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/239.png)'],
		primary: '#fecb00',
		secondary: '#003015',
		full: 'BAYLOR',
	},
	{
		abbreviation: 'BC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/103.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/103.png)'],
		primary: '#910039',
		secondary: '#b38f59',
		full: 'BOSTON COLLEGE',
	},
	{
		abbreviation: 'BCU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2065.png)'],
		primary: '#f2a900',
		secondary: '#6f263d',
		full: 'BETHUNE-COOKMAN',
	},
	{
		abbreviation: 'BEL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2057.png)'],
		primary: '#c8102e',
		secondary: '#00205b',
		full: 'BELMONT',
	},
	{
		abbreviation: 'BELL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/91.png)'],
		primary: '#660000',
		secondary: '#bdbcbc',
		full: 'BELLARMINE',
	},
	{
		abbreviation: 'BGSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/189.png)'],
		primary: '#fe5000',
		secondary: '#4f2c1d',
		full: 'BOWLING GREEN',
	},
	{
		abbreviation: 'BING',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2066.png)'],
		primary: '#005a43',
		secondary: '#169b62',
		full: 'BINGHAMTON',
	},
	{
		abbreviation: 'BOBJ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/3243.png)'],
		primary: '#001b41',
		secondary: '#884a1c',
	},
	{
		abbreviation: 'BRAD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/71.png)'],
		primary: '#a50000',
		secondary: '#999999',
		full: 'BRADLEY',
	},
	{
		abbreviation: 'BRWN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/225.png)'],
		primary: '#4e3629',
		secondary: '#7c2529',
		full: 'BROWN',
	},
	{
		abbreviation: 'BRY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2803.png)'],
		primary: '#b4975b',
		secondary: '#c9bb8c',
		full: 'BRYANT',
	},
	{
		abbreviation: 'BSU',
		abbreviation2: 'BOIS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/68.png)'],
		primary: '#09347a',
		secondary: '#f1632a',
		full: 'BOISE STATE',
	},
	{
		abbreviation: 'BU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/104.png)'],
		primary: '#cc0000',
		secondary: '#a3011b',
		full: 'BOSTON',
	},
	{
		abbreviation: 'BUCK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2083.png)'],
		primary: '#e87722',
		secondary: '#003865',
		full: 'BUCKNELL',
	},
	{
		abbreviation: 'BUFF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2084.png)'],
		primary: '#005bbb',
		secondary: '#000000',
		full: 'BUFFALO',
	},
	{
		abbreviation: 'BUT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2086.png)'],
		primary: '#13294b',
		secondary: '#747678',
		full: 'BUTLER',
	},
	{
		abbreviation: 'BYU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/252.png)'],
		primary: '#002255',
		secondary: '#c1c1c1',
		full: 'BRIGHAM YOUNG',
	},
	{
		abbreviation: 'CAL',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/25.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/25.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/cal_bears_football.png)',
		],
		primary: '#ffc72c',
		secondary: '#041e42',
		full: 'CALIFORNIA',
	},
	{
		abbreviation: 'CAM',
		abbreviation2: 'CAMP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2097.png)'],
		primary: '#f58025',
		secondary: '#231f20',
		full: 'CAMPBELL',
	},
	{
		abbreviation: 'CAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2099.png)'],
		primary: '#0c2340',
		secondary: '#ffba00',
		full: 'CANISIUS',
	},
	{
		abbreviation: 'CBU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2856.png)'],
		primary: '#002554',
		secondary: '#a37400',
		full: 'CALIFORNIA BAPTIST',
	},
	{
		abbreviation: 'CCAR',
		abbreviation2: 'CCU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/324.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/324.png)'],
		primary: '#006f71',
		secondary: '#a27752',
		full: 'COASTAL CAROLINA',
		full2: 'C CAROLINA',
	},
	{
		abbreviation: 'CCSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2115.png)'],
		primary: '#00539b',
		secondary: '#000000',
		full: 'CENTRAL CONNECTICUT',
	},
	{
		abbreviation: 'CHIC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2130.png)'],
		primary: '#006666',
		secondary: '#000000',
		full: 'CHICAGO STATE',
	},
	{
		abbreviation: 'CHSO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2127.png)'],
		primary: '#a8996e',
		secondary: '#002855',
		full: 'CHARLESTON SOUTHERN',
	},
	{
		abbreviation: 'CIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2132.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2132.png)'],
		primary: '#e00122',
		secondary: '#000000',
		full: 'CINCINNATI',
	},
	{
		abbreviation: 'CIT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2643.png)'],
		primary: '#3975b7',
		secondary: '#1f3a60',
		full: 'THE CITADEL',
	},
	{
		abbreviation: 'CLEM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/228.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/228.png)'],
		primary: '#522d80',
		secondary: '#f56600',
		full: 'CLEMSON',
	},
	{
		abbreviation: 'CLEV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/325.png)'],
		primary: '#006747',
		secondary: '#000000',
		full: 'CLEVELAND STATE',
	},
	{
		abbreviation: 'CLMB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/171.png)'],
		primary: '#003865',
		secondary: '#9bcbeb',
		full: 'COLUMBIA',
	},
	{
		abbreviation: 'CLT',
		abbreviation2: 'CHAR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2429.png)'],
		primary: '#b9975b',
		secondary: '#046a38',
		full: 'CHARLOTTE',
	},
	{
		abbreviation: 'CMU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2117.png)'],
		primary: '#6a0032',
		secondary: '#ffc82e',
		full: 'CENTRAL MICHIGAN',
		full2: 'C MICHIGAN',
	},
	{
		abbreviation: 'COFC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/232.png)'],
		primary: '#800000',
		secondary: '#9d8958',
		full: 'CHARLESTON',
	},
	{
		abbreviation: 'COLG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2142.png)'],
		primary: '#821019',
		secondary: '#000000',
		full: 'COLGATE',
	},
	{
		abbreviation: 'COLO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/38.png)'],
		primary: '#cfb87c',
		secondary: '#000000',
		full: 'COLORADO',
	},
	{
		abbreviation: 'CONN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/41.png)'],
		primary: '#000e2f',
		secondary: '#c1c1c1',
		full: 'UCONN',
	},
	{
		abbreviation: 'COPP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2154.png)'],
		primary: '#003056',
		secondary: '#ffc915',
		full: 'COPPIN STATE',
	},
	{
		abbreviation: 'COR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/172.png)'],
		primary: '#b31b1b',
		secondary: '#5e3920',
		full: 'CORNELL',
	},
	{
		abbreviation: 'CP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/13.png)'],
		primary: '#003831',
		secondary: '#ffe395',
		full: 'CAL POLY',
	},
	{
		abbreviation: 'CREI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/156.png)'],
		primary: '#005ca9',
		secondary: '#00235d',
		full: 'CREIGHTON',
	},
	{
		abbreviation: 'CSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/36.png)'],
		primary: '#1e4d2b',
		secondary: '#c8c372',
		full: 'COLORADO STATE',
	},
	{
		abbreviation: 'CSUB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2934.png)'],
		primary: '#003594',
		secondary: '#ffc72c',
		full: 'CSU BAKERSFIELD',
	},
	{
		abbreviation: 'CSUF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2239.png)'],
		primary: '#00274c',
		secondary: '#dc8218',
		full: 'CSU FULLERTON',
	},
	{
		abbreviation: 'CSUN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2463.png)'],
		primary: '#ce1126',
		secondary: '#000000',
		full: 'CSU NORTHRIDGE',
	},
	{
		abbreviation: 'DART',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/159.png)'],
		primary: '#046a38',
		secondary: '#000000',
		full: 'DARTMOUTH',
	},
	{
		abbreviation: 'DAV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2166.png)'],
		primary: '#ac1a2f',
		secondary: '#000000',
		full: 'DAVIDSON',
	},
	{
		abbreviation: 'DAY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2168.png)'],
		primary: '#004b8d',
		secondary: '#ce1141',
		full: 'DAYTON',
	},
	{
		abbreviation: 'DEL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/48.png)'],
		primary: '#00539f',
		secondary: '#ffdd31',
		full: 'DELAWARE',
	},
	{
		abbreviation: 'DEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2172.png)'],
		primary: '#8b2332',
		secondary: '#8b6f4b',
		full: 'DENVER',
	},
	{
		abbreviation: 'DEP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/305.png)'],
		primary: '#005eb8',
		secondary: '#e4002b',
		full: 'DEPAUL',
	},
	{
		abbreviation: 'DET',
		abbreviation2: 'DETM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2174.png)'],
		primary: '#a6093d',
		secondary: '#002d72',
		full: 'DETROIT MERCY',
	},
	{
		abbreviation: 'DREX',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2182.png)'],
		primary: '#00437b',
		secondary: '#ffda02',
		full: 'DREXEL',
	},
	{
		abbreviation: 'DRKE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2181.png)'],
		primary: '#004477',
		secondary: '#999999',
		full: 'DRAKE',
	},
	{
		abbreviation: 'DSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2169.png)'],
		primary: '#ee3124',
		secondary: '#72cdf4',
		full: 'DELAWARE STATE',
	},
	{
		abbreviation: 'DUKE',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/150.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/150.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/duke_football.png)',
		],
		primary: '#c1c1c1',
		secondary: '#001a57',
		full: 'DUKE',
	},
	{
		abbreviation: 'DUQ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2184.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2184.png)'],
		primary: '#ba0c2f',
		secondary: '#041e42',
		full: 'DUQUESNE',
	},
	{
		abbreviation: 'UTU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/3101.png)'],
		primary: '#BA1C21',
		secondary: '#003058',
		full: 'UTAH TECH',
	},
	{
		abbreviation: 'ECU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/151.png)'],
		primary: '#592a8a',
		secondary: '#dfc82f',
		full: 'EAST CAROLINA',
	},
	{
		abbreviation: 'EIU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2197.png)'],
		primary: '#919295',
		secondary: '#004b83',
		full: 'EASTERN ILLINOIS',
	},
	{
		abbreviation: 'EKU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2198.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2198.png)'],
		primary: '#8a0039',
		secondary: '#000000',
		full: 'EASTERN KENTUCKY',
	},
	{
		abbreviation: 'ELON',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2210.png)'],
		primary: '#73000a',
		secondary: '#b59a57',
		full: 'ELON',
	},
	{
		abbreviation: 'EMU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2199.png)'],
		primary: '#000000',
		secondary: '#006633',
		full: 'EASTERN MICHIGAN',
		full2: 'E MICHIGAN',
	},
	{
		abbreviation: 'ETSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2193.png)'],
		primary: '#041e42',
		secondary: '#ffc72c',
		full: 'EAST TENNESSEE STATE',
	},
	{
		abbreviation: 'EVAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/339.png)'],
		primary: '#52237f',
		secondary: '#f36f21',
		full: 'EVANSVILLE',
	},
	{
		abbreviation: 'EWU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/331.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/331.png)'],
		primary: '#a10022',
		secondary: '#000000',
		full: 'EASTERN WASHINGTON',
	},
	{
		abbreviation: 'FAIR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2217.png)'],
		primary: '#c8102e',
		secondary: '#000000',
		full: 'FAIRFIELD',
	},
	{
		abbreviation: 'FAMU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/50.png)'],
		primary: '#ee7624',
		secondary: '#1b5633',
		full: 'FLORIDA A&M',
	},
	{
		abbreviation: 'FAU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2226.png)'],
		primary: '#003366',
		secondary: '#cc0000',
		full: 'FLORIDA ATLANTIC',
	},
	{
		abbreviation: 'FDU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/161.png)'],
		primary: '#28334a',
		secondary: '#72293c',
		full: 'FAIRLEIGH DICKINSON',
	},
	{
		abbreviation: 'FGCU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/526.png)'],
		primary: '#002d72',
		secondary: '#007749',
		full: 'FLORIDA GULF COAST',
	},
	{
		abbreviation: 'FIU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2229.png)'],
		primary: '#081e3f',
		secondary: '#b6862c',
		full: 'FLORIDA INTERNATIONAL UNIVERSITY',
	},
	{
		abbreviation: 'FLA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/57.png)'],
		primary: '#0021a5',
		secondary: '#fa4616',
		full: 'FLORIDA',
	},
	{
		abbreviation: 'FOR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2230.png)'],
		primary: '#860038',
		secondary: '#000000',
		full: 'FORDHAM',
	},
	{
		abbreviation: 'FRES',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/278.png)'],
		primary: '#c41230',
		secondary: '#043b7e',
		full: 'FRESNO STATE',
	},
	{
		abbreviation: 'FSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/52.png)'],
		primary: '#782f40',
		secondary: '#ceb888',
		full: 'FLORIDA STATE',
	},
	{
		abbreviation: 'FUR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/231.png)'],
		primary: '#201547',
		secondary: '#582c83',
		full: 'FURMAN',
	},
	{
		abbreviation: 'GASO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/290.png)'],
		primary: '#011e41',
		secondary: '#a3aaae',
		full: 'GEORGIA SOUTHERN',
		full2: 'GA SOUTHERN',
	},
	{
		abbreviation: 'GAST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2247.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2247.png)'],
		primary: '#0039a6',
		secondary: '#c60c30',
		full: 'GEORGIA STATE',
	},
	{
		abbreviation: 'GB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2739.png)'],
		primary: '#006747',
		secondary: '#9d2235',
		full: 'GREEN BAY',
	},
	{
		abbreviation: 'GCU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2253.png)'],
		primary: '#522398',
		secondary: '#000000',
		full: 'GRAND CANYON',
	},
	{
		abbreviation: 'GMU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2244.png)'],
		primary: '#006633',
		secondary: '#ffcc33',
		full: 'GEORGE MASON',
	},
	{
		abbreviation: 'GONZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2250.png)'],
		primary: '#041e42',
		secondary: '#c8102e',
		full: 'GONZAGA',
	},
	{
		abbreviation: 'GRAM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2755.png)'],
		primary: '#eaa921',
		secondary: '#000000',
		full: 'GRAMBLING STATE',
	},
	{
		abbreviation: 'GT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/59.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/59.png)'],
		primary: '#c59353',
		secondary: '#003057',
		full: 'GEORGIA TECH',
	},
	{
		abbreviation: 'GTWN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/46.png)'],
		primary: '#041e42',
		secondary: '#8d817b',
		full: 'GEORGETOWN',
	},
	{
		abbreviation: 'GW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/45.png)'],
		primary: '#e2cb92',
		secondary: '#002654',
		full: 'GEORGE WASHINGTON',
	},
	{
		abbreviation: 'GWEB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2241.png)'],
		primary: '#bf2f37',
		secondary: '#000000',
		full: 'GARDNER-WEBB',
	},
	{
		abbreviation: 'HALL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2550.png)'],
		primary: '#004488',
		secondary: '#a2aaad',
		full: 'SETON HALL',
	},
	{
		abbreviation: 'HAMP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2261.png)'],
		primary: '#0060a9',
		secondary: '#000000',
		full: 'HAMPTON',
	},
	{
		abbreviation: 'HART',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/42.png)'],
		primary: '#c02427',
		secondary: '#0f0708',
		full: 'HARTFORD',
	},
	{
		abbreviation: 'HARV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/108.png)'],
		primary: '#a41034',
		secondary: '#000000',
		full: 'HARVARD',
	},
	{
		abbreviation: 'HAW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/62.png)'],
		primary: '#024731',
		secondary: '#c8c8c8',
		full: 'HAWAII',
	},
	{
		abbreviation: 'HCU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2277.png)'],
		primary: '#1c4e9d',
		secondary: '#f3652c',
		full: 'HOUSTON CHRISTIAN',
	},
	{
		abbreviation: 'HC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/107.png)'],
		primary: '#602d89',
		secondary: '#000000',
		full: 'HOLY CROSS',
	},
	{
		abbreviation: 'HOF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2275.png)'],
		primary: '#0b1e73',
		secondary: '#feb612',
		full: 'HOFSTRA',
	},
	{
		abbreviation: 'HOU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/248.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/248.png)'],
		primary: '#c92839',
		secondary: '#b2b4b2',
		full: 'HOUSTON',
	},
	{
		abbreviation: 'HOW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/47.png)'],
		primary: '#003a63',
		secondary: '#e51937',
		full: 'HOWARD',
	},
	{
		abbreviation: 'HP',
		abbreviation2: 'HPU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2272.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500/2272.png)'],
		primary: '#330072',
		secondary: '#000000',
		full: 'HIGH POINT',
	},
	{
		abbreviation: 'IDHO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/70.png)'],
		primary: '#b3a369',
		secondary: '#eaab00',
		full: 'IDAHO',
	},
	{
		abbreviation: 'IDST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/304.png)'],
		primary: '#ff671f',
		secondary: '#000000',
		full: 'IDAHO STATE',
	},
	{
		abbreviation: 'ILL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/356.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/356.png)'],
		primary: '#13294b',
		secondary: '#e04e39',
		full: 'ILLINOIS',
	},
	{
		abbreviation: 'ILST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2287.png)'],
		primary: '#ce1126',
		secondary: '#f9dd16',
		full: 'ILLINOIS STATE',
	},
	{
		abbreviation: 'IND',
		abbreviation2: 'IU',
		logos: [
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/indiana_hoosiers_football.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/84.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/84.png)',
		],
		primary: '#eeedeb',
		secondary: '#990000',
		full: 'INDIANA',
	},
	{
		abbreviation: 'INST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/282.png)'],
		primary: '#0142bc',
		secondary: '#000000',
		full: 'INDIANA STATE',
	},
	{
		abbreviation: 'IONA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/314.png)'],
		primary: '#6f2c3f',
		secondary: '#f2a900',
		full: 'IONA',
	},
	{
		abbreviation: 'IOWA',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/2294.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2294.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/iowa_hawkeyes_football.png)',
		],
		primary: '#ffe100',
		secondary: '#000000',
		full: 'IOWA',
	},
	{
		abbreviation: 'ISU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/66.png)'],
		primary: '#a6192e',
		secondary: '#fdc82f',
		full: 'IOWA STATE',
	},
	{
		abbreviation: 'IUPU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/85.png)'],
		primary: '#9d2235',
		secondary: '#dfd1a7',
		full: 'INDIANA UNIVERSITY–PURDUE UNIVERSITY INDIANAPOLIS',
	},
	{
		abbreviation: 'JAX',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/294.png)'],
		primary: '#004e44',
		secondary: '#898a8d',
		full: 'JACKSONVILLE',
	},
	{
		abbreviation: 'JKST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2296.png)'],
		primary: '#002147',
		secondary: '#008ed6',
		full: 'JACKSON STATE',
	},
	{
		abbreviation: 'JMU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/256.png)'],
		primary: '#450084',
		secondary: '#cbb677',
		full: 'JAMES MADISON',
	},
	{
		abbreviation: 'JOES',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2603.png)'],
		primary: '#9e1b32',
		secondary: '#6c6f70',
		full: "SAINT JOSEPH'S",
	},
	{
		abbreviation: 'JVST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/55.png)'],
		primary: '#cc0000',
		secondary: '#000000',
		full: 'JACKSONVILLE STATE',
	},
	{
		abbreviation: 'KC',
		abbreviation2: 'UMKC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/140.png)'],
		primary: '#005293',
		secondary: '#fecb00',
		full: 'UM KANSAS CITY',
	},
	{
		abbreviation: 'KENN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/338.png)'],
		primary: '#fdbb30',
		secondary: '#0b1315',
		full: 'KENNESAW STATE',
	},
	{
		abbreviation: 'KENT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2309.png)'],
		primary: '#002664',
		secondary: '#eaab00',
		full: 'KENT STATE',
	},
	{
		abbreviation: 'KSU',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/2306.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2306.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/kansas_state_football.png)',
		],
		primary: '#a7a7a7',
		secondary: '#512888',
		full: 'KANSAS STATE',
	},
	{
		abbreviation: 'KU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2305.png)'],
		primary: '#0051ba',
		secondary: '#e8000d',
		full: 'KANSAS',
	},
	{
		abbreviation: 'L-MD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2352.png)'],
		primary: '#00694e',
		secondary: '#cacac8',
		full: 'LOYOLA (MD)',
	},
	{
		abbreviation: 'LAF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/322.png)'],
		primary: '#a8996e',
		secondary: '#98002e',
		full: 'LAFAYETTE',
	},
	{
		abbreviation: 'LAG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2331.png)'],
		primary: '#a41f2f',
		secondary: '#000000',
	},
	{
		abbreviation: 'LAM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2320.png)'],
		primary: '#dc0032',
		secondary: '#fcb034',
		full: 'LAMAR',
	},
	{
		abbreviation: 'LAS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2325.png)'],
		primary: '#ffcc00',
		secondary: '#003366',
		full: 'LA SALLE',
	},
	{
		abbreviation: 'LAT',
		abbreviation2: 'LT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2348.png)'],
		primary: '#003087',
		secondary: '#cb333b',
		full: 'LOUISIANA TECH',
	},
	{
		abbreviation: 'LBSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/299.png)'],
		primary: '#ffc72a',
		secondary: '#000000',
		full: 'LONG BEACH STATE',
	},
	{
		abbreviation: 'LEH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2329.png)'],
		primary: '#653600',
		secondary: '#ffd24f',
		full: 'LEHIGH',
	},
	{
		abbreviation: 'LIB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2335.png)'],
		primary: '#990000',
		secondary: '#0a254e',
		full: 'LIBERTY',
	},
	{
		abbreviation: 'LIP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/288.png)'],
		primary: '#331e54',
		secondary: '#f4aa00',
		full: 'LIPSCOMB',
	},
	{
		abbreviation: 'LIU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/112358.png)'],
		primary: '#69b3e7',
		secondary: '#ffc72c',
		full: 'LONG ISLAND UNIVERSITY',
	},
	{
		abbreviation: 'LMU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2351.png)'],
		primary: '#b62b3a',
		secondary: '#00447c',
		full: 'LOYOLA MARYMOUNT',
	},
	{
		abbreviation: 'LONG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2344.png)'],
		primary: '#15213b',
		secondary: '#999d9e',
		full: 'LONGWOOD',
	},
	{
		abbreviation: 'LOU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/97.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/97.png)'],
		primary: '#ad0000',
		secondary: '#000000',
		full: 'LOUISVILLE',
	},
	{
		abbreviation: 'LSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/99.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/lsu_football.png)'],
		primary: '#582c83',
		secondary: '#ffc72c',
		full: 'LOUISIANA STATE UNIVERSITY',
	},
	{
		abbreviation: 'LUC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2350.png)'],
		primary: '#922247',
		secondary: '#febd18',
		full: 'LOYOLA CHICAGO',
	},
	{
		abbreviation: 'M-OH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/193.png)'],
		primary: '#b61e2e',
		secondary: '#000000',
		full: 'MIAMI (OH)',
		full2: 'MIAMI-OH',
	},
	{
		abbreviation: 'MAINE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/311.png)'],
		primary: '#003263',
		secondary: '#b0d7ff',
	},
	{
		abbreviation: 'MAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2363.png)'],
		primary: '#00703c',
		secondary: '#000000',
		full: 'MANHATTAN',
	},
	{
		abbreviation: 'MARQ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/269.png)'],
		primary: '#003366',
		secondary: '#ffcc00',
		full: 'MARQUETTE',
	},
	{
		abbreviation: 'MASS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/113.png)'],
		primary: '#881c1c',
		secondary: '#572932',
	},
	{
		abbreviation: 'MCN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2377.png)'],
		primary: '#00529b',
		secondary: '#ffd204',
	},
	{
		abbreviation: 'MCNS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2377.png)'],
		primary: '#00529b',
		secondary: '#ffd204',
		full: 'MCNEESE STATE',
		full2: 'MCNEESE',
	},
	{
		abbreviation: 'MD',
		abbreviation2: 'UMD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/120.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/120.png)'],
		primary: '#c8102e',
		secondary: '#ffd520',
		full: 'MARYLAND',
	},
	{
		abbreviation: 'ME',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/311.png)'],
		primary: '#003263',
		secondary: '#b0d7ff',
		full: 'MAINE',
	},
	{
		abbreviation: 'MEM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/235.png)'],
		primary: '#0d3182',
		secondary: '#888c8f',
		full: 'MEMPHIS',
	},
	{
		abbreviation: 'MER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2382.png)'],
		primary: '#f76800',
		secondary: '#000000',
		full: 'MERCER',
	},
	{
		abbreviation: 'MIA',
		abbreviation2: 'MIAMI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2390.png)'],
		primary: '#f47321',
		secondary: '#005030',
		full: 'MIAMI',
	},
	{
		abbreviation: 'MICH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/130.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/130.png)'],
		primary: '#00274c',
		secondary: '#ffcb05',
		full: 'MICHIGAN',
	},
	{
		abbreviation: 'MILW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/270.png)'],
		primary: '#ffbd00',
		secondary: '#cccccc',
		full: 'MILWAUKEE',
	},
	{
		abbreviation: 'MINN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/135.png)'],
		primary: '#7a0019',
		secondary: '#ffcc33',
		full: 'MINNESOTA',
	},
	{
		abbreviation: 'MISS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/145.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/ole_miss_football.png)'],
		primary: '#00205b',
		secondary: '#c8102e',
		full: 'OLE MISS',
		full2: 'MISSISSIPPI'
	},
	{
		abbreviation: 'MIZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/142.png)'],
		primary: '#f1b82d',
		secondary: '#000000',
		full: 'MISSOURI',
	},
	{
		abbreviation: 'MONM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2405.png)'],
		primary: '#041e42',
		secondary: '#53565a',
		full: 'MONMOUTH',
	},
	{
		abbreviation: 'MONT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/149.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/149.png)'],
		primary: '#660033',
		secondary: '#999999',
		full: 'MONTANA',
	},
	{
		abbreviation: 'MORE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2413.png)'],
		primary: '#ffcf00',
		secondary: '#005eb8',
		full: 'MOREHEAD STATE',
	},
	{
		abbreviation: 'MORG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2415.png)'],
		primary: '#1b4383',
		secondary: '#f47937',
		full: 'MORGAN STATE',
	},
	{
		abbreviation: 'MOST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2623.png)'],
		primary: '#5e0009',
		secondary: '#000000',
		full: 'MISSOURI STATE',
	},
	{
		abbreviation: 'MRMK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2771.png)'],
		primary: '#003768',
		secondary: '#fdb813',
		full: 'MERRIMACK',
	},
	{
		abbreviation: 'MRSH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/276.png)'],
		primary: '#006140',
		secondary: '#a2aaad',
		full: 'MARSHALL',
	},
	{
		abbreviation: 'MRST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2368.png)'],
		primary: '#c8102e',
		secondary: '#000000',
		full: 'MARIST',
	},
	{
		abbreviation: 'MSM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/116.png)'],
		primary: '#002855',
		secondary: '#84754e',
		full: "MOUNT ST. MARY'S",
	},
	{
		abbreviation: 'MSST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/344.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/mississippi_state_football.png)'],
		primary: '#660000',
		secondary: '#75787b',
		full: 'MISSISSIPPI STATE',
		full2: 'MISS STATE',
	},
	{
		abbreviation: 'MSU',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/127.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/127.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/michigan_state_football.png)',
		],
		primary: '#c1c1c1',
		secondary: '#18453b',
		full: 'MICHIGAN STATE',
	},
	{
		abbreviation: 'MTST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/147.png)'],
		primary: '#00205b',
		secondary: '#b9975b',
		full: 'MONTANA STATE',
	},
	{
		abbreviation: 'MTSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2393.png)'],
		primary: '#002b5c',
		secondary: '#0066cc',
		full: 'MIDDLE TENNESSEE',
		full2: 'MIDDLE TENN',
	},
	{
		abbreviation: 'MUR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/93.png)'],
		primary: '#ecac00',
		secondary: '#002144',
		full: 'MURRAY STATE',
	},
	{
		abbreviation: 'MVSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2400.png)'],
		primary: '#00703c',
		secondary: '#e51937',
		full: 'MISSISSIPPI VALLEY STATE',
	},
	{
		abbreviation: 'NAU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2464.png)'],
		primary: '#003466',
		secondary: '#ffd200',
		full: 'NORTHERN ARIZONA',
	},
	{
		abbreviation: 'NAVY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2426.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2426.png)'],
		primary: '#00205b',
		secondary: '#c5b783',
		full: 'NAVY',
	},
	{
		abbreviation: 'NCAA',
		logos: ['url(https://assets.espn.go.com/i/espn/misc_logos/500/ncaa.png)'],
		primary: '#126bb5',
		secondary: '#d81f55',
	},
	{
		abbreviation: 'NCAT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2448.png)'],
		primary: '#f3b237',
		secondary: '#003d6d',
		full: 'NORTH CAROLINA A&T',
	},
	{
		abbreviation: 'NCCU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2428.png)'],
		primary: '#880023',
		secondary: '#8e9093',
		full: 'NC CENTRAL',
	},
	{
		abbreviation: 'NCST',
		abbreviation2: 'NCSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/152.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/nc_state_football.png)'],
		primary: '#cc0000',
		secondary: '#000000',
		full: 'NC STATE',
	},
	{
		abbreviation: 'ND',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/87.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/87.png)'],
		primary: '#c99700',
		secondary: '#0c2340',
		full: 'NOTRE DAME',
	},
	{
		abbreviation: 'NDSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2449.png)'],
		primary: '#0a5640',
		secondary: '#ffc72a',
		full: 'NORTH DAKOTA STATE',
	},
	{
		abbreviation: 'NE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/111.png)'],
		primary: '#d41b2c',
		secondary: '#000000',
		full: 'NORTHEASTERN',
	},
	{
		abbreviation: 'NEB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/158.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/158.png)'],
		primary: '#e41c38',
		secondary: '#000000',
		full: 'NEBRASKA',
	},
	{
		abbreviation: 'NEV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2440.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2440.png)'],
		primary: '#807f84',
		secondary: '#003366',
		full: 'NEVADA',
	},
	{
		abbreviation: 'NIAG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/315.png)'],
		primary: '#582c83',
		secondary: '#000000',
		full: 'NIAGARA',
	},
	{
		abbreviation: 'NICH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2447.png)'],
		primary: '#ae132a',
		secondary: '#72808a',
		full: 'NICHOLLS STATE',
	},
	{
		abbreviation: 'NIU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2459.png)'],
		primary: '#000000',
		secondary: '#ba0c2f',
		full: 'NORTHERN ILLINOIS',
		full2: 'N ILLINOIS',
	},
	{
		abbreviation: 'NJIT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2885.png)'],
		primary: '#d22630',
		secondary: '#071d49',
		full: 'NEW JERSEY INSTITUTE OF TECHNOLOGY',
	},
	{
		abbreviation: 'NKU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/94.png)'],
		primary: '#ffc72c',
		secondary: '#000000',
		full: 'NORTHERN KENTUCKY',
	},
	{
		abbreviation: 'NMSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/166.png)'],
		primary: '#8c0b42',
		secondary: '#97999b',
		full: 'NEW MEXICO STATE',
	},
	{
		abbreviation: 'NORF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2450.png)'],
		primary: '#007a53',
		secondary: '#f3d03e',
		full: 'NORFOLK STATE',
	},
	{
		abbreviation: 'NU',
		abbreviation2: 'NW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/77.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/77.png)'],
		primary: '#c1c1c1',
		secondary: '#4e2a84',
		full: 'NORTHWESTERN',
	},
	{
		abbreviation: 'NWST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2466.png)'],
		primary: '#663399',
		secondary: '#ff6600',
		full: 'NORTHWESTERN STATE',
	},
	{
		abbreviation: 'NYA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/428.png)'],
		primary: '#94191e',
		secondary: '#b1b1b0',
	},
	{
		abbreviation: 'OAK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2473.png)'],
		primary: '#b59a57',
		secondary: '#000000',
		full: 'OAKLAND',
	},
	{
		abbreviation: 'ODU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/295.png)'],
		primary: '#7c878e',
		secondary: '#05344c',
		full: 'OLD DOMINION',
	},
	{
		abbreviation: 'OHIO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/195.png)'],
		primary: '#cda077',
		secondary: '#00694e',
		full: 'OHIO',
	},
	{
		abbreviation: 'OKLA',
		abbreviation2: 'OU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/201.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/201.png)'],
		primary: '#ddcba4',
		secondary: '#841617',
		full: 'OKLAHOMA',
	},
	{
		abbreviation: 'OKST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/197.png)', 'url(https://b.fssta.com/uploads/application/college/team-logos/OklahomaState-alternate.vresize.350.350.medium.0.png)'],
		primary: '#ff7300',
		secondary: '#000000',
		full: 'OKLAHOMA STATE',
	},
	{
		abbreviation: 'OMA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2437.png)'],
		primary: '#000000',
		secondary: '#d71920',
		full: 'OMAHA',
	},
	{
		abbreviation: 'ORE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2483.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2483.png)'],
		primary: '#fee123',
		secondary: '#154733',
		full: 'OREGON',
	},
	{
		abbreviation: 'ORST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/204.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/204.png)'],
		primary: '#000000',
		secondary: '#c34500',
		full: 'OREGON STATE',
	},
	{
		abbreviation: 'ORU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/198.png)'],
		primary: '#002f60',
		secondary: '#c5b783',
		full: 'ORAL ROBERTS',
	},
	{
		abbreviation: 'OSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/194.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/194.png)'],
		primary: '#bb0000',
		secondary: '#666666',
		full: 'OHIO STATE',
	},
	{
		abbreviation: 'PAC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/279.png)'],
		primary: '#e35205',
		secondary: '#231f20',
		full: 'PACIFIC',
	},
	{
		abbreviation: 'PENN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/219.png)'],
		primary: '#990000',
		secondary: '#011f5b',
		full: 'UNIVERSITY OF PENNSYLVANIA',
	},
	{
		abbreviation: 'PEPP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2492.png)'],
		primary: '#00205c',
		secondary: '#ee7624',
		full: 'PEPPERDINE',
	},
	{
		abbreviation: 'PFW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2870.png)'],
		primary: '#002f6c',
		secondary: '#000000',
		full: 'PURDUE FORT WAYNE',
	},
	{
		abbreviation: 'PITT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/221.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/221.png)'],
		primary: '#003594',
		secondary: '#ffb81c',
		full: 'PITTSBURGH',
	},
	{
		abbreviation: 'PORT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2501.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2501.png)'],
		primary: '#1e1656',
		secondary: '#5e6a71',
		full: 'PORTLAND',
	},
	{
		abbreviation: 'PRE',
		abbreviation2: 'PRES',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2506.png)'],
		primary: '#0060a9',
		secondary: '#9d2235',
		full: 'PRESBYTERIAN',
	},
	{
		abbreviation: 'PRIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/163.png)'],
		primary: '#ff671f',
		secondary: '#000000',
		full: 'PRINCETON',
	},
	{
		abbreviation: 'PROV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2507.png)'],
		primary: '#8a8d8f',
		secondary: '#000000',
		full: 'PROVIDENCE',
	},
	{
		abbreviation: 'PRST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2502.png)'],
		primary: '#154734',
		secondary: '#a5acaf',
		full: 'PORTLAND STATE',
	},
	{
		abbreviation: 'PSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/213.png)'],
		primary: '#041e42',
		secondary: '#c1c1c1',
		full: 'PENN STATE',
	},
	{
		abbreviation: 'PUR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2509.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2509.png)'],
		primary: '#ceb888',
		secondary: '#000000',
		full: 'PURDUE',
	},
	{
		abbreviation: 'PV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2504.png)'],
		primary: '#330066',
		secondary: '#ffcc33',
		full: 'PRAIRIE VIEW A&M',
	},
	{
		abbreviation: 'QUIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2514.png)'],
		primary: '#0c2340',
		secondary: '#ffb81c',
		full: 'QUINNIPIAC',
	},
	{
		abbreviation: 'RAD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2515.png)'],
		primary: '#c2011b',
		secondary: '#000000',
		full: 'RADFORD',
	},
	{
		abbreviation: 'RGV',
		abbreviation2: 'UTRGV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/292.png)'],
		primary: '#f05023',
		secondary: '#646469',
		full: 'UT RIO GRANDE VALLEY',
	},
	{
		abbreviation: 'RICE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/242.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/242.png)'],
		primary: '#5e6062',
		secondary: '#002469',
		full: 'RICE',
	},
	{
		abbreviation: 'RICH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/257.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/257.png)'],
		primary: '#990000',
		secondary: '#000066',
		full: 'RICHMOND',
	},
	{
		abbreviation: 'RID',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2520.png)'],
		primary: '#981e32',
		secondary: '#6c6f70',
		full: 'RIDER',
	},
	{
		abbreviation: 'RMU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2523.png)'],
		primary: '#14234b',
		secondary: '#a6192e',
		full: 'ROBERT MORRIS (PA)',
	},
	{
		abbreviation: 'RUTG',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/164.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/164.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/rutgers_football.png)',
		],
		primary: '#d21034',
		secondary: '#5f6a72',
		full: 'RUTGERS',
	},
	{
		abbreviation: 'SAC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/16.png)'],
		primary: '#043927',
		secondary: '#c4b581',
		full: 'SACRAMENTO STATE',
	},
	{
		abbreviation: 'SAM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2535.png)'],
		primary: '#002649',
		secondary: '#c4161d',
		full: 'SAMFORD',
	},
	{
		abbreviation: 'SBU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/179.png)'],
		primary: '#54261a',
		secondary: '#fdb726',
		full: 'ST. BONAVENTURE',
	},
	{
		abbreviation: 'SC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2579.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2579.png)'],
		primary: '#73000a',
		secondary: '#000000',
		full: 'SOUTH CAROLINA',
	},
	{
		abbreviation: 'SCST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2569.png)'],
		primary: '#862633',
		secondary: '#001a72',
		full: 'SOUTH CAROLINA STATE',
	},
	{
		abbreviation: 'SCU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2541.png)'],
		primary: '#862633',
		secondary: '#231f20',
		full: 'SANTA CLARA',
	},
	{
		abbreviation: 'SCUP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2908.png)'],
		primary: '#00703c',
		secondary: '#000000',
		full: 'SOUTH CAROLINA UPSTATE',
	},
	{
		abbreviation: 'SDAK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/233.png)'],
		primary: '#ad0000',
		secondary: '#000000',
		full: 'SOUTH DAKOTA',
	},
	{
		abbreviation: 'SDST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2571.png)'],
		primary: '#0033a0',
		secondary: '#ffd100',
		full: 'SOUTH DAKOTA STATE',
	},
	{
		abbreviation: 'SDSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/21.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/21.png)'],
		primary: '#000000',
		secondary: '#c41230',
		full: 'SAN DIEGO STATE',
	},
	{
		abbreviation: 'SEA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2547.png)'],
		primary: '#aa0000',
		secondary: '#000000',
		full: 'SEATTLE U',
	},
	{
		abbreviation: 'SELA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2545.png)'],
		primary: '#006341',
		secondary: '#eaaa00',
		full: 'SOUTHEASTERN LOUISIANA',
	},
	{
		abbreviation: 'SEMO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2546.png)'],
		primary: '#000000',
		secondary: '#c8102e',
		full: 'SOUTHEAST MISSOURI',
	},
	{
		abbreviation: 'SF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2539.png)'],
		primary: '#fdbb30',
		secondary: '#00543c',
		full: 'SAN FRANCISCO',
	},
	{
		abbreviation: 'SFA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2617.png)'],
		primary: '#613393',
		secondary: '#000000',
		full: 'STEPHEN F. AUSTIN',
	},
	{
		abbreviation: 'SFBK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2597.png)'],
		primary: '#005496',
		secondary: '#e03a3e',
		full: 'ST. FRANCIS (BKN)',
	},
	{
		abbreviation: 'SFPA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2598.png)'],
		primary: '#bd1f25',
		secondary: '#790000',
		full: 'ST. FRANCIS (PA)',
	},
	{
		abbreviation: 'SHSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2534.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500/2534.png)'],
		primary: '#fe5100',
		secondary: '#000000',
		full: 'SAM HOUSTON STATE',
		full2: 'SAM HOUSTON',
	},
	{
		abbreviation: 'SHU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2529.png)'],
		primary: '#ce1141',
		secondary: '#b1b3b6',
		full: 'SACRED HEART',
	},
	{
		abbreviation: 'SIE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2561.png)'],
		primary: '#ffc72c',
		secondary: '#c8102e',
		full: 'SIENA',
	},
	{
		abbreviation: 'SIU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/79.png)'],
		primary: '#720000',
		secondary: '#000000',
		full: 'SOUTHERN ILLINOIS',
	},
	{
		abbreviation: 'SIUE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2565.png)'],
		primary: '#ef3829',
		secondary: '#cfaa7a',
		full: 'SIU EDWARDSVILLE',
	},
	{
		abbreviation: 'SJSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/23.png)'],
		primary: '#0055a2',
		secondary: '#e5a823',
		full: 'SAN JOSE STATE',
	},
	{
		abbreviation: 'SJU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2599.png)'],
		primary: '#ba0c2f',
		secondary: '#041c2c',
		full: "ST. JOHN'S",
	},
	{
		abbreviation: 'SLU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/139.png)'],
		primary: '#003da5',
		secondary: '#c8c9c7',
		full: 'SAINT LOUIS',
	},
	{
		abbreviation: 'SMC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2608.png)'],
		primary: '#d80024',
		secondary: '#06315b',
		full: "SAINT MARY'S",
	},
	{
		abbreviation: 'SMU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2567.png)'],
		primary: '#354ca1',
		secondary: '#cd2027',
		full: 'SOUTHERN METHODIST UNIVERSITY',
	},
	{
		abbreviation: 'SOU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2582.png)'],
		primary: '#58b6e7',
		secondary: '#ffce34',
		full: 'SOUTHERN UNIVERSITY',
	},
	{
		abbreviation: 'SPU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2612.png)'],
		primary: '#003c71',
		secondary: '#0072ce',
		full: "SAINT PETER'S",
	},
	{
		abbreviation: 'STAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/24.png)'],
		primary: '#8c1515',
		secondary: '#4d4f53',
		full: 'STANFORD',
	},
	{
		abbreviation: 'STBK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2619.png)'],
		primary: '#990000',
		secondary: '#16243e',
		full: 'STONY BROOK',
	},
	{
		abbreviation: 'STET',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/56.png)'],
		primary: '#006747',
		secondary: '#b58150',
		full: 'STETSON',
	},
	{
		abbreviation: 'STMN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2900.png)'],
		primary: '#512773',
		secondary: '#7f888e',
	},
	{
		abbreviation: 'SUU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/253.png)'],
		primary: '#c41425',
		secondary: '#000000',
		full: 'SOUTHERN UTAH',
	},
	{
		abbreviation: 'SYR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/183.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/183.png)'],
		primary: '#d44500',
		secondary: '#3e3d3c',
		full: 'SYRACUSE',
	},
	{
		abbreviation: 'TAMU',
		abbreviation2: 'TA&M',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/245.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/245.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/texas_am_football.png)',
		],
		primary: '#500000',
		secondary: '#c1c1c1',
		full: 'TEXAS A&M',
	},
	{
		abbreviation: 'TAR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2627.png)'],
		primary: '#5a2b81',
		secondary: '#000000',
		full: 'TARLETON',
	},
	{
		abbreviation: 'TCU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2628.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2628.png)'],
		primary: '#a3a9ac',
		secondary: '#4d1979',
		full: 'TEXAS CHRISTIAN',
	},
	{
		abbreviation: 'TEM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/218.png)'],
		primary: '#8a8d8f',
		secondary: '#990033',
		full: 'TEMPLE',
	},
	{
		abbreviation: 'TENN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2633.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2633.png)'],
		primary: '#58595b',
		secondary: '#ff8200',
		full: 'TENNESSEE',
	},
	{
		abbreviation: 'TEX',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/251.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/251.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/texas_longhorns_football.png)',
		],
		primary: '#333f48',
		secondary: '#bf5700',
		full: 'TEXAS',
	},
	{
		abbreviation: 'TLSA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/202.png)'],
		primary: '#c5b783',
		secondary: '#002d72',
		full: 'TULSA',
	},
	{
		abbreviation: 'TNST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2634.png)'],
		primary: '#00539f',
		secondary: '#000000',
		full: 'TENNESSEE STATE',
	},
	{
		abbreviation: 'TNTC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2635.png)'],
		primary: '#4f2984',
		secondary: '#ffdd00',
		full: 'TENNESSEE TECH',
	},
	{
		abbreviation: 'TOL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2649.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2649.png)'],
		primary: '#002569',
		secondary: '#ffce00',
		full: 'TOLEDO',
	},
	{
		abbreviation: 'TOW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/119.png)'],
		primary: '#ffbb00',
		secondary: '#3c3c3c',
		full: 'TOWSON',
	},
	{
		abbreviation: 'TROY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2653.png)'],
		primary: '#862633',
		secondary: '#8a8d8f',
		full: 'TROY',
	},
	{
		abbreviation: 'TTU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2641.png)'],
		primary: '#000000',
		secondary: '#cc0000',
		full: 'TEXAS TECH',
	},
	{
		abbreviation: 'TUL',
		abbreviation2: 'TULN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2655.png)'],
		primary: '#0082ba',
		secondary: '#005837',
		full: 'TULANE',
	},
	{
		abbreviation: 'TXSO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2640.png)'],
		primary: '#7c183e',
		secondary: '#9da6ab',
		full: 'TEXAS SOUTHERN',
	},
	{
		abbreviation: 'TXST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/326.png)'],
		primary: '#501214',
		secondary: '#8d734a',
		full: 'TEXAS STATE',
	},
	{
		abbreviation: 'UAB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/5.png)'],
		primary: '#1e6b52',
		secondary: '#ffd400',
		full: 'UNIVERSITY OF ALABAMA AT BIRMINGHAM',
	},
	{
		abbreviation: 'UALR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2031.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2031.png)'],
		primary: '#6e2639',
		secondary: '#a7a9ac',
		full: 'LITTLE ROCK',
	},
	{
		abbreviation: 'UAPB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2029.png)'],
		primary: '#eeb310',
		secondary: '#e31837',
		full: 'ARKANSAS-PINE BLUFF',
	},
	{
		abbreviation: 'UCA',
		abbreviation2: 'CARK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2110.png)'],
		primary: '#4f2d7f',
		secondary: '#818a8f',
		full: 'CENTRAL ARKANSAS',
	},
	{
		abbreviation: 'UCD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/302.png)'],
		primary: '#b3a369',
		secondary: '#002855',
		full: 'UC DAVIS',
	},
	{
		abbreviation: 'UCF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2116.png)'],
		primary: '#ba9b37',
		secondary: '#000000',
		full: 'UNIVERSITY OF CENTRAL FLORIDA',
	},
	{
		abbreviation: 'UCI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/300.png)'],
		primary: '#0c2340',
		secondary: '#ffc72c',
		full: 'UC IRVINE',
	},
	{
		abbreviation: 'UCLA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/26.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/26.png)'],
		primary: '#ffc72c',
		secondary: '#0072ce',
		full: 'UNIVERSITY OF CALIFORNIA LOS ANGELES',
	},
	{
		abbreviation: 'UCONN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/41.png)'],
		primary: '#000e2f',
		secondary: '#c1c1c1',
	},
	{
		abbreviation: 'UCR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/27.png)'],
		primary: '#2d6cc0',
		secondary: '#003066',
		full: 'UC RIVERSIDE',
	},
	{
		abbreviation: 'UCSB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2540.png)'],
		primary: '#003660',
		secondary: '#febc11',
		full: 'UC SANTA BARBARA',
	},
	{
		abbreviation: 'UCSD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/28.png)'],
		primary: '#13284b',
		secondary: '#ffce07',
		full: 'UC SAN DIEGO',
	},
	{
		abbreviation: 'UGA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/61.png)'],
		primary: '#da291c',
		secondary: '#000000',
		full: 'GEORGIA',
	},
	{
		abbreviation: 'UIC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/82.png)'],
		primary: '#ac1e2d',
		secondary: '#041e42',
		full: 'UNIVERSITY OF ILLINOIS AT CHICAGO',
	},
	{
		abbreviation: 'UIW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2916.png)'],
		primary: '#cb333b',
		secondary: '#000000',
		full: 'INCARNATE WORD',
	},
	{
		abbreviation: 'UK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/96.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/96.png)'],
		primary: '#0033a0',
		secondary: '#c1c1c1',
		full: 'KENTUCKY',
	},
	{
		abbreviation: 'UL',
		abbreviation2: 'ULL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/309.png)'],
		primary: '#ce181e',
		secondary: '#000000',
		full: 'LOUISIANA',
	},
	{
		abbreviation: 'ULM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2433.png)'],
		primary: '#800029',
		secondary: '#bd955a',
		full: 'LOUISIANA MONROE',
		full2: 'UL-MONROE',
	},
	{
		abbreviation: 'UMASS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/113.png)'],
		primary: '#881c1c',
		secondary: '#572932',
		full: 'UMASS',
	},
	{
		abbreviation: 'UMBC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2378.png)'],
		primary: '#b18906',
		secondary: '#000000',
		full: 'UNIVERSITY OF MARYLAND BALTIMORE COUNTY',
	},
	{
		abbreviation: 'UMES',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2379.png)'],
		primary: '#862633',
		secondary: '#888b8d',
		full: 'MARYLAND-EASTERN SHORE',
	},
	{
		abbreviation: 'UML',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2349.png)'],
		primary: '#003da5',
		secondary: '#c8102e',
		full: 'UMASS LOWELL',
	},
	{
		abbreviation: 'UNA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2453.png)'],
		primary: '#46166b',
		secondary: '#db9f11',
		full: 'NORTH ALABAMA',
	},
	{
		abbreviation: 'UNC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/153.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/153.png)'],
		primary: '#13294b',
		secondary: '#7bafd4',
		full: 'NORTH CAROLINA',
	},
	{
		abbreviation: 'UNCA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2427.png)'],
		primary: '#003da5',
		secondary: '#808285',
		full: 'UNC ASHEVILLE',
	},
	{
		abbreviation: 'UNCG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2430.png)'],
		primary: '#0f2044',
		secondary: '#ffb71b',
		full: 'UNC GREENSBORO',
	},
	{
		abbreviation: 'UNCO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2458.png)'],
		primary: '#013c65',
		secondary: '#f6b000',
		full: 'NORTHERN COLORADO',
	},
	{
		abbreviation: 'UNCW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/350.png)'],
		primary: '#003366',
		secondary: '#006666',
		full: 'UNC WILMINGTON',
	},
	{
		abbreviation: 'UND',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/155.png)'],
		primary: '#009a44',
		secondary: '#aaaead',
		full: 'NORTH DAKOTA',
	},
	{
		abbreviation: 'UNF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2454.png)'],
		primary: '#00246b',
		secondary: '#d9d9d9',
		full: 'NORTH FLORIDA',
	},
	{
		abbreviation: 'UNH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/160.png)'],
		primary: '#041e42',
		secondary: '#bbbcbc',
		full: 'NEW HAMPSHIRE',
	},
	{
		abbreviation: 'UNI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2460.png)'],
		primary: '#4b116f',
		secondary: '#ffcc00',
		full: 'NORTHERN IOWA',
	},
	{
		abbreviation: 'UNLV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2439.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2439.png)'],
		primary: '#b10202',
		secondary: '#666666',
		full: 'UNIVERSITY OF NEVADA LAS VEGAS',
	},
	{
		abbreviation: 'UNM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/167.png)'],
		primary: '#b71234',
		secondary: '#c3c8c8',
		full: 'NEW MEXICO',
	},
	{
		abbreviation: 'UNO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2443.png)'],
		primary: '#005ca6',
		secondary: '#a3a9ac',
		full: 'NEW ORLEANS',
	},
	{
		abbreviation: 'UNT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/249.png)'],
		primary: '#000000',
		secondary: '#00853e',
		full: 'NORTH TEXAS',
	},
	{
		abbreviation: 'URI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/227.png)'],
		primary: '#68abe8',
		secondary: '#002147',
		full: 'RHODE ISLAND',
	},
	{
		abbreviation: 'USA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/6.png)'],
		primary: '#00205b',
		secondary: '#bf0d3e',
		full: 'SOUTH ALABAMA',
	},
	{
		abbreviation: 'USC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/30.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/30.png)'],
		primary: '#990000',
		secondary: '#ffcc00',
		full: 'UNIVERSITY OF SOUTHERN CALIFORNIA',
	},
	{
		abbreviation: 'USD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/301.png)'],
		primary: '#002868',
		secondary: '#84bce8',
		full: 'SAN DIEGO',
	},
	{
		abbreviation: 'USF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/58.png)'],
		primary: '#006747',
		secondary: '#cfc493',
		full: 'SOUTH FLORIDA',
	},
	{
		abbreviation: 'USM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2572.png)'],
		primary: '#fdc737',
		secondary: '#000000',
		full: 'SOUTHERN MISS',
		full2: 'SO MISS',
	},
	{
		abbreviation: 'USU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/328.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/328.png)'],
		primary: '#9d968d',
		secondary: '#0f2439',
		full: 'UTAH STATE',
	},
	{
		abbreviation: 'UTA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/250.png)'],
		primary: '#f58025',
		secondary: '#0064b1',
		full: 'UT ARLINGTON',
	},
	{
		abbreviation: 'UTAH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/254.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/254.png)'],
		primary: '#cc0000',
		secondary: '#000000',
		full: 'UTAH',
	},
	{
		abbreviation: 'UTC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/236.png)'],
		primary: '#00386b',
		secondary: '#e0aa0f',
		full: 'CHATTANOOGA',
	},
	{
		abbreviation: 'UTEP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2638.png)'],
		primary: '#ff5600',
		secondary: '#002147',
		full: 'UNIVERSITY OF TEXAS AT EL PASO',
	},
	{
		abbreviation: 'UTM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2630.png)'],
		primary: '#f79728',
		secondary: '#002a5b',
		full: 'TENNESSEE-MARTIN',
	},
	{
		abbreviation: 'UTSA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2636.png)'],
		primary: '#0c2340',
		secondary: '#f15a22',
		full: 'UNIVERSITY OF TEXAS AT SAN ANTONIO',
	},
	{
		abbreviation: 'UVA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/258.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/258.png)'],
		primary: '#fa4616',
		secondary: '#041e42',
		full: 'VIRGINIA',
	},
	{
		abbreviation: 'UVM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/261.png)'],
		primary: '#154734',
		secondary: '#8b5b29',
		full: 'VERMONT',
	},
	{
		abbreviation: 'UVU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/3084.png)'],
		primary: '#275d38',
		secondary: '#000000',
		full: 'UTAH VALLEY',
	},
	{
		abbreviation: 'VAL',
		abbreviation2: 'VALP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2674.png)'],
		primary: '#381e0e',
		secondary: '#613318',
		full: 'VALPARAISO',
	},
	{
		abbreviation: 'VAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/238.png)'],
		primary: '#997f3d',
		secondary: '#000000',
		full: 'VANDERBILT',
	},
	{
		abbreviation: 'VCU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2670.png)'],
		primary: '#ffb300',
		secondary: '#000000',
		full: 'VIRGINIA COMMONWEALTH',
	},
	{
		abbreviation: 'VILL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/222.png)'],
		primary: '#00205b',
		secondary: '#13b5ea',
		full: 'VILLANOVA',
	},
	{
		abbreviation: 'VMI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2678.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/2678.png)'],
		primary: '#a71f23',
		secondary: '#ffd520',
		full: 'VIRGINIA MILITARY',
	},
	{
		abbreviation: 'VT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/259.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/259.png)'],
		primary: '#ff6600',
		secondary: '#660000',
		full: 'VIRGINIA TECH',
	},
	{
		abbreviation: 'WAG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2681.png)'],
		primary: '#00483a',
		secondary: '#b9bbbd',
		full: 'WAGNER',
	},
	{
		abbreviation: 'WAKE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/154.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/wake_forest_football.png)'],
		primary: '#9e7e38',
		secondary: '#000000',
		full: 'WAKE FOREST',
	},
	{
		abbreviation: 'WASH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/264.png)'],
		primary: '#363c74',
		secondary: '#e8d3a2',
		full: 'WASHINGTON',
	},
	{
		abbreviation: 'WCU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2717.png)'],
		primary: '#592c88',
		secondary: '#c1a875',
		full: 'WESTERN CAROLINA',
	},
	{
		abbreviation: 'WEB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2692.png)'],
		primary: '#4b2682',
		secondary: '#a1a1a4',
		full: 'WEBER STATE',
	},
	{
		abbreviation: 'WICH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2724.png)'],
		primary: '#ffcd00',
		secondary: '#000000',
		full: 'WICHITA STATE',
	},
	{
		abbreviation: 'WILY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2728.png)'],
		primary: '#635782',
		secondary: '#131313',
	},
	{
		abbreviation: 'WIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2737.png)'],
		primary: '#660000',
		secondary: '#f0b323',
		full: 'WINTHROP',
	},
	{
		abbreviation: 'WIS',
		abbreviation2: 'WISC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/275.png)', 'url(https://cdn.bleacherreport.net/images/team_logos/328x328/wisconsin_badgers_football.png)'],
		primary: '#c5050c',
		secondary: '#000000',
		full: 'WISCONSIN',
	},
	{
		abbreviation: 'WIU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2710.png)'],
		primary: '#663399',
		secondary: '#ffcc00',
		full: 'WESTERN ILLINOIS',
	},
	{
		abbreviation: 'WKU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/98.png)'],
		primary: '#b01e24',
		secondary: '#1e1e1e',
		full: 'WESTERN KENTUCKY',
		full2: 'W KENTUCKY',
	},
	{
		abbreviation: 'WM',
		abbreviation2: 'W&M',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2729.png)'],
		primary: '#115740',
		secondary: '#f0b323',
		full: 'WILLIAM & MARY',
	},
	{
		abbreviation: 'WMU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2711.png)'],
		primary: '#6c4023',
		secondary: '#b5a167',
		full: 'WESTERN MICHIGAN',
		full2: 'W MICHIGAN',
	},
	{
		abbreviation: 'WOF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2747.png)'],
		primary: '#886e4c',
		secondary: '#000000',
		full: 'WOFFORD',
	},
	{
		abbreviation: 'WRST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2750.png)'],
		primary: '#026937',
		secondary: '#cea052',
		full: 'WRIGHT STATE',
	},
	{
		abbreviation: 'WSU',
		logos: [
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500/265.png)',
			'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/265.png)',
			'url(https://cdn.bleacherreport.net/images/team_logos/328x328/washington_state_football.png)',
		],
		primary: '#5e6a71',
		secondary: '#981e32',
		full: 'WASHINGTON STATE',
	},
	{
		abbreviation: 'WVU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/277.png)', 'url(https://a.espncdn.com/i/teamlogos/ncaa/500-dark/277.png)'],
		primary: '#eaaa00',
		secondary: '#002855',
		full: 'WEST VIRGINIA',
	},
	{
		abbreviation: 'WYO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2751.png)'],
		primary: '#492f24',
		secondary: '#ffc425',
		full: 'WYOMING',
	},
	{
		abbreviation: 'XAV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2752.png)'],
		primary: '#0c2340',
		secondary: '#9ea2a2',
		full: 'XAVIER',
	},
	{
		abbreviation: 'YALE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/43.png)'],
		primary: '#00356b',
		secondary: '#000000',
		full: 'YALE',
	},
	{
		abbreviation: 'YSU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2754.png)'],
		primary: '#c8102e',
		secondary: '#f3d54e',
		full: 'YOUNGSTOWN STATE',
	},
	{
		abbreviation: 'LIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2815.png)'],
		primary: '#101820',
		secondary: '#B5A36A',
		full: 'LINDENWOOD',
	},
	{
		abbreviation: 'QUOC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2511.png)'],
		primary: '#002452',
		secondary: '#fabd0f',
		full: 'QUEENS UNIVERSITY',
	},
	{
		abbreviation: 'USI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/88.png)'],
		primary: '#CF102D',
		secondary: '#002D5D',
		full: 'SOUTHERN INDIANA',
	},
	{
		abbreviation: 'STO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/284.png)'],
		primary: '#2F2975',
		secondary: '#000000',
		full: 'STONEHILL',
	},
	{
		abbreviation: 'TAMC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2837.png)'],
		primary: '#00386C',
		secondary: '#F1B20F',
		full: 'TEXAS A&M COMMERCE',
	},
	{
		abbreviation: 'ACC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/1.png)'],
		primary: '#A5A9AB',
		secondary: '#013CA6',
	},
	{
		abbreviation: 'BIGTWELVE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/4.png)'],
		primary: '#C41230',
		secondary: '#D1D5D8',
	},
	{
		abbreviation: 'BIGTEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/5.png)'],
		primary: '#000000',
		secondary: '#1E88CA',
	},
	{
		abbreviation: 'SEC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/8.png)'],
		primary: '#264697',
		secondary: '#FFC921',
	},
	{
		abbreviation: 'PACTWELVE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/9.png)'],
		primary: '#00264B',
		secondary: '#000000',
	},
	{
		abbreviation: 'C-USA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/12.png)'],
		primary: '#E91A46',
		secondary: '#002539',
	},
	{
		abbreviation: 'MAC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/15.png)'],
		primary: '#002A5C',
		secondary: '#00A261',
	},
	{
		abbreviation: 'MWC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/17.png)'],
		primary: '#462783',
		secondary: '#A6ADB5',
	},
	{
		abbreviation: 'BIGSKY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/20.png)'],
		primary: '#005DAB',
		secondary: '#6FCDE4',
	},
	{
		abbreviation: 'MVC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/21.png)'],
		primary: '#E64E4E',
		secondary: '#004B8B',
	},
	{
		abbreviation: 'IVY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/22.png)'],
		primary: '#C6B784',
		secondary: '#0F6A37',
	},
	{
		abbreviation: 'MEAC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/24.png)'],
		primary: '#332A7A',
		secondary: '#FDBE57',
	},
	{
		abbreviation: 'NEC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/25.png)'],
		primary: '#006CB8',
		secondary: '#000000',
	},
	{
		abbreviation: 'OVC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/26.png)'],
		primary: '#A61744',
		secondary: '#D0AE85',
	},
	{
		abbreviation: 'PATRIOT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/27.png)'],
		primary: '#ED1B2E',
		secondary: '#003876',
	},
	{
		abbreviation: 'SOUTHERN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/29.png)'],
		primary: '#125285',
		secondary: '#E33D2E',
	},
	{
		abbreviation: 'SOUTHLAND',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/30.png)'],
		primary: '#002A5C',
		secondary: '#FDB825',
	},
	{
		abbreviation: 'SWAC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/31.png)'],
		primary: '#FF260C',
		secondary: '#000000',
	},
	{
		abbreviation: 'SUNBELT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/37.png)'],
		primary: '#F5A705',
		secondary: '#0B2240',
	},
	{
		abbreviation: 'BIGSOUTH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/40.png)'],
		primary: '#F57B20',
		secondary: '#005DAB',
	},
	{
		abbreviation: 'AMERICAEAST',
		logos: ['url(https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/America_East_Conference_logo.svg/1200px-America_East_Conference_logo.svg.png)'],
		primary: '#09164D',
		secondary: '#00B3E4',
	},
	{
		abbreviation: 'AMERICAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/151.png)'],
		primary: '#005DAB',
		secondary: '#F57B20',
	},
	{
		abbreviation: 'BIGEAST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/big_east.png)'],
		primary: '#ED1A3B',
		secondary: '#003E7D',
	},
	{
		abbreviation: 'WCC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/west_coast.png)'],
		primary: '#000000',
		secondary: '#33cad1',
	},
	{
		abbreviation: 'MAAC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/maac.png)'],
		primary: '#084FA3',
		secondary: '#E2383E',
	},
	{
		abbreviation: 'HORIZON',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/horizon.png)'],
		primary: '#D88C28',
		secondary: '#4E4E50',
	},
	{
		abbreviation: 'COASTAL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/coastal.png)'],
		primary: '#166cbb',
		secondary: '#012D52',
	},
	{
		abbreviation: 'BIGWEST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/big_west.png)'],
		primary: '#A1A8AD',
		secondary: '#000000',
	},
	{
		abbreviation: 'ATLANTICTEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/atlantic_10.png)'],
		primary: '#E32526',
		secondary: '#000000',
	},
	{
		abbreviation: 'WAC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/wac.png)'],
		primary: '#98092E',
		secondary: '#D9D4CC',
	},
	{
		abbreviation: 'ASUN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa_conf/500/atlantic_sun.png)'],
		primary: '#4A4F55',
		secondary: '#F3E601',
	},
	{
		abbreviation: 'LEM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/ncaa/500/2330.png)'],
		primary: '#01462F',
		secondary: '#FDE616',
		full: 'LE MOYNE',
	},
];

export const soccerTeams = [
	{
		abbreviation: 'ACCR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2731.png)'],
		primary: '#C8142F',
		secondary: '#5CBFEB',
		full: 'ACCRINGTON STANLEY',
	},
	{
		abbreviation: 'AFG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/afg.png)'],
		primary: '#000000',
		secondary: '#D32011',
		full: 'AFGHANISTAN',
	},
	{
		abbreviation: 'AIA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/aia.png)'],
		primary: '#00247D',
		secondary: '#CF142B',
		full: 'ANGUILLA',
	},
	{
		abbreviation: 'AJA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/139.png)'],
		primary: '#d2122e',
		secondary: '#000000',
		full: 'AJAX AMSTERDAM',
	},
	{
		abbreviation: 'ALA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/17858.png)'],
		primary: '#FCEE33',
		secondary: '#ffffff',
		full: 'ALASHKERT FC',
	},
	{
		abbreviation: 'ALB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/alb.png)'],
		primary: '#E70000',
		secondary: '#000000',
		full: 'ALBANIA',
	},
	{
		abbreviation: 'ALG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/alg.png)'],
		primary: '#5bbd19',
		secondary: '#000000',
		full: 'ALGERIA',
	},
	{
		abbreviation: 'ALV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/96.png)'],
		primary: '#0761af',
		secondary: '#009ad7',
		full: 'ALAVÉS',
	},
	{
		abbreviation: 'AME',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/227.png)'],
		primary: '#ffff91',
		secondary: '#001C58',
		full: 'AMÉRICA',
	},
	{
		abbreviation: 'AND',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/and.png)'],
		primary: '#E70000',
		secondary: '#0000cd',
		full: 'ANDORRA',
	},
	{
		abbreviation: 'ANG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/7868.png)'],
		primary: '#1a1a1a',
		secondary: '#ffffff',
		full: 'ANGERS',
	},
	{
		abbreviation: 'ANGL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ang.png)'],
		primary: '#BE0027',
		secondary: '#C60000',
		full: 'ANGOLA',
	},
	{
		abbreviation: 'ANO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2550.png)'],
		primary: '#0000ff',
		secondary: '#ffffff',
		full: 'ANORTHOSIS',
	},
	{
		abbreviation: 'ANT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/17544.png)'],
		primary: '#C60000',
		secondary: '#ffffff',
		full: 'ANTWERP',
	},
	{
		abbreviation: 'ARG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/arg.png)'],
		primary: '#9fcfff',
		secondary: '#0a2f41',
		full: 'ARGENTINA',
	},
	{
		abbreviation: 'ARM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/fifa.armenia.png)'],
		primary: '#DE2400',
		secondary: '#FFB000',
		full: 'ARMENIA',
	},
	{
		abbreviation: 'ARS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/359.png)'],
		primary: '#ef0107',
		secondary: '#063672',
		full: 'ARSENAL',
	},
	{
		abbreviation: 'ARU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/aru.png)'],
		primary: '#3E90DF',
		secondary: '#FFD201',
		full: 'ARUBA',
	},
	{
		abbreviation: 'ASL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/15720.png)'],
		primary: '#EF0107',
		secondary: '#dfa829',
		full: 'ATLÉTICO SAN LUIS',
	},
	{
		abbreviation: 'ASM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/174.png)'],
		primary: '#E91514',
		secondary: '#1a1a1a',
		full: 'AS MONACO',
	},
	{
		abbreviation: 'ASSE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/178.png)'],
		primary: '#51cc5f',
		secondary: '#ffffff',
		full: 'SAINT-ETIENNE',
	},
	{
		abbreviation: 'ATA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/105.png)'],
		primary: '#1e71b8',
		secondary: '#000000',
		full: 'ATALANTA',
	},
	{
		abbreviation: 'ATG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/atg.png)'],
		primary: '#CE1126',
		secondary: '#0072C6',
		full: 'ANTIGUA AND BARBUDA',
	},
	{
		abbreviation: 'ATH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/93.png)'],
		primary: '#ee2523',
		secondary: '#000000',
		full: 'ATHLETIC CLUB',
	},
	{
		abbreviation: 'ATL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/1068.png)'],
		primary: '#272e61',
		secondary: '#cb3524',
		full: 'ATLETICO MADRID',
	},
	{
		abbreviation: 'ATLU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/18418.png)'],
		primary: '#80000a',
		secondary: '#221f1f',
		full: 'ATLANTA UNITED FC',
	},
	{
		abbreviation: 'ATS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/216.png)'],
		primary: '#EF0107',
		secondary: '#e1e1e1',
		full: 'ATLAS',
	},
	{
		abbreviation: 'ATX',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/20906.png)'],
		primary: '#00b140',
		secondary: '#000000',
		full: 'AUSTIN FC',
	},
	{
		abbreviation: 'AUG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3841.png)'],
		primary: '#ba3733',
		secondary: '#46714d',
		full: 'FC AUGSBURG',
	},
	{
		abbreviation: 'AUS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/aus.png)'],
		primary: '#012A7C',
		secondary: '#C70A38',
		full: 'AUSTRALIA',
	},
	{
		abbreviation: 'AUT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/aut.png)'],
		primary: '#d61229',
		secondary: '#121212',
		full: 'AUSTRIA',
	},
	{
		abbreviation: 'AVL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/362.png)'],
		primary: '#95bfe5',
		secondary: '#670e36',
		full: 'ASTON VILLA',
	},
	{
		abbreviation: 'AZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/140.png)'],
		primary: '#CF010E',
		secondary: '#000000',
		full: 'AZ ALKMAAR',
	},
	{
		abbreviation: 'AZE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/aze.png)'],
		primary: '#2D4AB0',
		secondary: '#30B356',
		full: 'AZERBAIJAN',
	},
	{
		abbreviation: 'BAH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bah.png)'],
		primary: '#000000',
		secondary: '#00778B',
		full: 'BAHAMAS',
	},
	{
		abbreviation: 'BAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ban.png)'],
		primary: '#006747',
		secondary: '#DA291C',
		full: 'BANGLADESH',
	},
	{
		abbreviation: 'BAR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/83.png)'],
		primary: '#edbb00',
		secondary: '#004d98',
		full: 'BARCELONA',
	},
	{
		abbreviation: 'BARN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/397.png)'],
		primary: '#f42727',
		secondary: '#065035',
		full: 'BARNSLEY',
	},
	{
		abbreviation: 'BAS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/989.png)'],
		primary: '#C8142F',
		secondary: '#ffffff',
		full: 'FC BASEL',
	},
	{
		abbreviation: 'BDI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bdi.png)'],
		primary: '#1EB53A',
		secondary: '#CE1126',
		full: 'BURUNDI',
	},
	{
		abbreviation: 'BEL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bel.png)'],
		primary: '#000000',
		secondary: '#DE2918',
		full: 'BELGIUM',
	},
	{
		abbreviation: 'BEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/4059.png)'],
		primary: '#e30e1e',
		secondary: '#ffef01',
		full: 'BENEVENTO',
	},
	{
		abbreviation: 'BENF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/20830.png)'],
		primary: '#ca281d',
		secondary: '#FFFFFF',
		full: 'BENFICA',
	},
	{
		abbreviation: 'BENN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ben.png)'],
		primary: '#008751',
		secondary: '#FCD116',
		full: 'BENIN',
	},
	{
		abbreviation: 'BER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ber.png)'],
		primary: '#c8102e',
		secondary: '#0033a0',
		full: 'BERMUDA',
	},
	{
		abbreviation: 'BES',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/1895.png)'],
		primary: '#D92027',
		secondary: '#000000',
		full: 'BESIKTAS',
	},
	{
		abbreviation: 'BET',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/244.png)'],
		primary: '#0bb363',
		secondary: '#e7a614',
		full: 'REAL BETIS',
	},
	{
		abbreviation: 'BHA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/331.png)'],
		primary: '#0057b8',
		secondary: '#ffcd00',
		full: 'BRIGHTON & HOVE ALBION',
	},
	{
		abbreviation: 'BHR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bhr.png)'],
		primary: '#d50032',
		secondary: '#FFFFFF',
		full: 'BAHRAIN',
	},
	{
		abbreviation: 'BHU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/5784.png)'],
		primary: '#FFD520',
		secondary: '#FF4E12',
		full: 'BHUTAN',
	},
	{
		abbreviation: 'BIH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bih.png)'],
		primary: '#112855',
		secondary: '#FFCC00',
		full: 'BOSNIA AND HERZEGOVINA',
	},
	{
		abbreviation: 'BIR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/392.png)'],
		primary: '#0000fa',
		secondary: '#ffde00',
		full: 'BIRMINGHAM CITY',
	},
	{
		abbreviation: 'BK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/21124.png)'],
		primary: '#000000',
		secondary: '#C60000',
		full: 'BK HÄCKEN',
	},
	{
		abbreviation: 'BKA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bka.png)'],
		primary: '#EF2B2D',
		secondary: '#006600',
		full: 'BURKINA FASO',
	},
	{
		abbreviation: 'BLK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/365.png)'],
		primary: '#0000fa',
		secondary: '#C8142F',
		full: 'BLACKBURN ROVERS',
	},
	{
		abbreviation: 'BLP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/346.png)'],
		primary: '#F5A12D',
		secondary: '#ffffff',
		full: 'BLACKPOOL',
	},
	{
		abbreviation: 'BLR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/blr.png)'],
		primary: '#DD1209',
		secondary: '#0E7B00',
		full: 'BELARUS',
	},
	{
		abbreviation: 'BLZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/blz.png)'],
		primary: '#CE1126',
		secondary: '#003F87',
		full: 'BELIZE',
	},
	{
		abbreviation: 'BO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bol.png)'],
		primary: '#00843d',
		secondary: '#DE1917',
		full: 'BOLIVIA',
	},
	{
		abbreviation: 'BOC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/121.png)'],
		primary: '#005CA9',
		secondary: '#ffffff',
	},
	{
		abbreviation: 'BODO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2980.png)'],
		primary: '#FCEE33',
		secondary: '#025719',
		full: 'BODO/GLIMT',
	},
	{
		abbreviation: 'BOL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/107.png)'],
		primary: '#1a2f48',
		secondary: '#a21c26',
		full: 'BOLOGNA',
	},
	{
		abbreviation: 'BOR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/159.png)'],
		primary: '#011F68',
		secondary: '#ffffff',
		full: 'BORDEAUX',
	},
	{
		abbreviation: 'BOT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bot.png)'],
		primary: '#0099ff',
		secondary: '#000000',
		full: 'BOTSWANA',
	},
	{
		abbreviation: 'BOU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/349.png)'],
		primary: '#C8142F',
		secondary: '#ffffff',
		full: 'AFC BOURNEMOUTH',
	},
	{
		abbreviation: 'BRA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bra.png)'],
		primary: '#ffe400',
		secondary: '#0c2fff',
		full: 'BRAZIL',
	},
	{
		abbreviation: 'BRAD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/387.png)'],
		primary: '#890000',
		secondary: '#ffffff',
		full: 'BRADFORD CITY',
	},
	{
		abbreviation: 'BRAG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2994.png)'],
		primary: '#de1f26',
		secondary: '#2b6a36',
		full: 'BRAGA',
	},
	{
		abbreviation: 'BRB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/brb.png)'],
		primary: '#00267F',
		secondary: '#FFC726',
		full: 'BARBADOS',
	},
	{
		abbreviation: 'BRC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/333.png)'],
		primary: '#f42727',
		secondary: '#1a1a1a',
		full: 'BRISTOL CITY',
	},
	{
		abbreviation: 'BRE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/337.png)'],
		primary: '#E20813',
		secondary: '#FCB800',
		full: 'BRENTFORD',
	},
	{
		abbreviation: 'BREI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/20112.png)'],
		primary: '#0c4011',
		secondary: '#ffffff',
		full: 'BREIDABLIK',
	},
	{
		abbreviation: 'BRES',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/6997.png)'],
		primary: '#ef2f24',
		secondary: '#1a1a1a',
		full: 'BREST',
	},
	{
		abbreviation: 'BRI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/308.png)'],
		primary: '#000099',
		secondary: '#425679',
		full: 'BRISTOL ROVERS',
	},
	{
		abbreviation: 'BRO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/575.png)'],
		primary: '#ffff00',
		secondary: '#43473d',
		full: 'BRØNDBY',
	},
	{
		abbreviation: 'BRT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2567.png)'],
		primary: '#ffff00',
		secondary: '#ffffff',
		full: 'BURTON ALBION',
	},
	{
		abbreviation: 'BRU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/10525.png)'],
		primary: '#FFF633',
		secondary: '#000000',
		full: 'BRUNEI DARUSSALAM',
	},
	{
		abbreviation: 'BRUG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/570.png)'],
		primary: '#0078bf',
		secondary: '#000000',
		full: 'CLUB BRUGGE',
	},
	{
		abbreviation: 'BRW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/642.png)'],
		primary: '#ffffff',
		secondary: '#deb887',
		full: 'BARROW',
	},
	{
		abbreviation: 'BUL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bul.png)'],
		primary: '#00924a',
		secondary: '#E70000',
		full: 'BULGARIA',
	},
	{
		abbreviation: 'BUR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/379.png)'],
		primary: '#6c1d45',
		secondary: '#99d6ea',
		full: 'BURNLEY',
	},
	{
		abbreviation: 'BVI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/bvr.png)'],
		primary: '#0033a0',
		secondary: '#c8102e',
		full: 'BRITISH VIRGIN ISLANDS',
	},
	{
		abbreviation: 'BW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/358.png)'],
		primary: '#ffffff',
		secondary: '#1a1a1a',
		full: 'BOLTON WANDERERS',
	},
	{
		abbreviation: 'CAD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3842.png)'],
		primary: '#0045a7',
		secondary: '#ffe500',
		full: 'CÁDIZ',
	},
	{
		abbreviation: 'CAG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2925.png)'],
		primary: '#002350',
		secondary: '#ad002a',
		full: 'CAGLIARI',
	},
	{
		abbreviation: 'CAM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3736.png)'],
		primary: '#FCEE33',
		secondary: '#ffffff',
		full: 'SC CAMBUUR',
	},
	{
		abbreviation: 'CAMB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/351.png)'],
		primary: '#FECD32',
		secondary: '#82dbf5',
		full: 'CAMBRIDGE UNITED',
	},
	{
		abbreviation: 'CAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/can.png)'],
		primary: '#FF0000',
		secondary: '#ffffff',
		full: 'CANADA',
	},
	{
		abbreviation: 'CAR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/347.png)'],
		primary: '#0000fa',
		secondary: '#ffbaf3',
		full: 'CARDIFF CITY',
	},
	{
		abbreviation: 'CARL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/322.png)'],
		primary: '#0e00f7',
		secondary: '#b0ffe1',
		full: 'CARLISLE UNITED',
	},
	{
		abbreviation: 'CAY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/cay.png)'],
		primary: '#001F7E',
		secondary: '#D00C27',
		full: 'CAYMAN ISLANDS',
	},
	{
		abbreviation: 'CAZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/218.png)'],
		primary: '#0000ff',
		secondary: '#ffffff',
		full: 'CRUZ AZUL',
	},
	{
		abbreviation: 'CEL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/85.png)'],
		primary: '#8ac3ee',
		secondary: '#e5254e',
		full: 'CELTA VIGO',
	},
	{
		abbreviation: 'CELT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/256.png)'],
		primary: '#009921',
		secondary: '#053e29',
		full: 'CELTIC',
	},
	{
		abbreviation: 'CGO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/cgo.png)'],
		primary: '#009543',
		secondary: '#FBDE4A',
		full: 'CONGO',
	},
	{
		abbreviation: 'CHA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/372.png)'],
		primary: '#C8142F',
		secondary: '#020202',
		full: 'CHARLTON ATHLETIC',
	},
	{
		abbreviation: 'CHAD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/cha.png)'],
		primary: '#002664',
		secondary: '#FECB00',
		full: 'CHAD',
	},
	{
		abbreviation: 'CHE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/363.png)'],
		primary: '#034694',
		secondary: '#ee242c',
		full: 'CHELSEA',
	},
	{
		abbreviation: 'CHI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/182.png)'],
		primary: '#121f48',
		secondary: '#b3272d',
		full: 'CHICAGO FIRE FC',
	},
	{
		abbreviation: 'CHIC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/15360.png)'],
		primary: '#C60000',
		secondary: '#000000',
		full: 'CHICAGO RED STARS',
	},
	{
		abbreviation: 'CHL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/320.png)'],
		primary: '#C8142F',
		secondary: '#82dbf5',
		full: 'CHELTENHAM TOWN',
	},
	{
		abbreviation: 'CHN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/chn.png)'],
		primary: '#ed1b2d',
		secondary: '#FFFF00',
		full: 'CHINA',
	},
	{
		abbreviation: 'CIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/18267.png)'],
		primary: '#fe5000',
		secondary: '#003087',
		full: 'FC CINCINNATI',
	},
	{
		abbreviation: 'CIV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/civ.png)'],
		primary: '#d48c00',
		secondary: '#5bbd19',
		full: 'IVORY COAST',
	},
	{
		abbreviation: 'CL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/chi.png)'],
		primary: '#ea2300',
		secondary: '#ffffff',
		full: 'CHILE',
	},
	{
		abbreviation: 'CLB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/183.png)'],
		primary: '#fef200',
		secondary: '#231f20',
		full: 'COLUMBUS CREW SC',
	},
	{
		abbreviation: 'CLER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3171.png)'],
		primary: '#8C3140',
		secondary: '#ffffff',
		full: 'CLERMONT FOOT',
	},
	{
		abbreviation: 'CLT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/21300.png)'],
		primary: '#0086C9',
		secondary: '#000000',
	},
	{
		abbreviation: 'CLUJ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/5260.png)'],
		primary: '#94283f',
		secondary: '#ffffff',
		full: 'CFR CLUJ-NAPOCA',
	},
	{
		abbreviation: 'CMR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/crm.png)'],
		primary: '#5bbd19',
		secondary: '#dabf00',
		full: 'CAMEROON',
	},
	{
		abbreviation: 'CO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/col.png)'],
		primary: '#ffff00',
		secondary: '#0c2fff',
		full: 'COLOMBIA',
	},
	{
		abbreviation: 'COD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/rdc.png)'],
		primary: '#418fde',
		secondary: '#C60000',
		full: 'CONGO DR',
	},
	{
		abbreviation: 'COL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/122.png)'],
		primary: '#ed1c24',
		secondary: '#000000',
		full: 'FC COLOGNE',
	},
	{
		abbreviation: 'COLO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/184.png)'],
		primary: '#862633',
		secondary: '#8bb8e8',
		full: 'COLORADO RAPIDS',
	},
	{
		abbreviation: 'COM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/com.png)'],
		primary: '#3d8e33',
		secondary: '#ffc61E',
		full: 'COMOROS ISLANDS',
	},
	{
		abbreviation: 'COP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/909.png)'],
		primary: '#ffffff',
		secondary: '#1a1a1a',
		full: 'FC COPENHAGEN',
	},
	{
		abbreviation: 'COV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/388.png)'],
		primary: '#82dbf5',
		secondary: '#C8142F',
		full: 'COVENTRY CITY',
	},
	{
		abbreviation: 'CPV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/cpv.png)'],
		primary: '#003893',
		secondary: '#F7D116',
		full: 'CAPE VERDE ISLANDS',
	},
	{
		abbreviation: 'CRA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/cro.png)'],
		primary: '#DE2400',
		secondary: '#0433B1',
		full: 'CROATIA',
	},
	{
		abbreviation: 'CRAW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2594.png)'],
		primary: '#C8142F',
		secondary: '#ffffff',
		full: 'CRAWLEY TOWN',
	},
	{
		abbreviation: 'CRC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/crc.png)'],
		primary: '#EF0000',
		secondary: '#000000',
		full: 'COSTA RICA',
	},
	{
		abbreviation: 'CREW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/396.png)'],
		primary: '#C8142F',
		secondary: '#FECD32',
		full: 'CREWE ALEXANDRA',
	},
	{
		abbreviation: 'CRO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3173.png)'],
		primary: '#201c5d',
		secondary: '#ca0006',
		full: 'CROTONE',
	},
	{
		abbreviation: 'CRY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/384.png)'],
		primary: '#1b458f',
		secondary: '#a7a5a6',
		full: 'CRYSTAL PALACE',
	},
	{
		abbreviation: 'CSKA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/10834.png)'],
		primary: '#C60000',
		secondary: '#ffffff',
		full: 'CSKA SOFIA',
	},
	{
		abbreviation: 'CTA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/10528.png)'],
		primary: '#071d49',
		secondary: '#ea2300',
		full: 'CENTRAL AFRICAN REPUBLIC',
	},
	{
		abbreviation: 'CU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/339.png)'],
		primary: '#0e00f7',
		secondary: '#1a1a1a',
		full: 'COLCHESTER UNITED',
	},
	{
		abbreviation: 'CUB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/cub.png)'],
		primary: '#0033a0',
		secondary: '#c8102e',
		full: 'CUBA',
	},
	{
		abbreviation: 'CUR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/11678.png)'],
		primary: '#0537e4',
		secondary: '#000000',
		full: 'CURACAO',
	},
	{
		abbreviation: 'CYP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/cyp.png)'],
		primary: '#195ccd',
		secondary: '#ffffff',
		full: 'CYPRUS',
	},
	{
		abbreviation: 'CZE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/cze.png)'],
		primary: '#d61229',
		secondary: '#00318D',
		full: 'CZECH REPUBLIC',
	},
	{
		abbreviation: 'DAL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/185.png)'],
		primary: '#d11241',
		secondary: '#003e7e',
		full: 'FC DALLAS',
	},
	{
		abbreviation: 'DC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/193.png)'],
		primary: '#ee1a39',
		secondary: '#231f20',
		full: 'DC UNITED',
	},
	{
		abbreviation: 'DEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/den.png)'],
		primary: '#ea2300',
		secondary: '#ffffff',
		full: 'DENMARK',
	},
	{
		abbreviation: 'DER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/374.png)'],
		primary: '#ffffff',
		secondary: '#1f365e',
		full: 'DERBY COUNTY',
	},
	{
		abbreviation: 'DJI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/dji.png)'],
		primary: '#6AB2E7',
		secondary: '#12AD2B',
		full: 'DJIBOUTI',
	},
	{
		abbreviation: 'DMA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/dma.png)'],
		primary: '#32ad42',
		secondary: '#C60000',
		full: 'DOMINICA',
	},
	{
		abbreviation: 'DOM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/dom.png)'],
		primary: '#002D62',
		secondary: '#CE1126',
		full: 'DOMINICAN REPUBLIC',
	},
	{
		abbreviation: 'DON',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/279.png)'],
		primary: '#C8142F',
		secondary: '#000099',
		full: 'DONCASTER ROVERS',
	},
	{
		abbreviation: 'DOR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/124.png)'],
		primary: '#fde100',
		secondary: '#000000',
		full: 'BORUSSIA DORTMUND',
	},
	{
		abbreviation: 'DSC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2506.png)'],
		primary: '#004e95',
		secondary: '#000000',
		full: 'ARMINIA BIELEFELD',
	},
	{
		abbreviation: 'ECU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ecu.png)'],
		primary: '#ffff00',
		secondary: '#0a2c5a',
		full: 'ECUADOR',
	},
	{
		abbreviation: 'EGY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/egy.png)'],
		primary: '#D20300',
		secondary: '#000000',
		full: 'EGYPT',
	},
	{
		abbreviation: 'EIB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3752.png)'],
		primary: '#0071b9',
		secondary: '#aa093c',
		full: 'EIBAR',
	},
	{
		abbreviation: 'EINF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/125.png)'],
		primary: '#e1000f',
		secondary: '#000000',
		full: 'EINTRACHT FRANKFURT',
	},
	{
		abbreviation: 'ELC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3751.png)'],
		primary: '#05642c',
		secondary: '#e6c777',
		full: 'ELCHE',
	},
	{
		abbreviation: 'EMP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2574.png)'],
		primary: '#00579C',
		secondary: '#ffffff',
		full: 'EMPOLI',
	},
	{
		abbreviation: 'ENG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/eng.png)'],
		primary: '#D0132C',
		secondary: '#ffffff',
		full: 'ENGLAND',
	},
	{
		abbreviation: 'UK',
		abbreviation2: 'GB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/fifa.great_britain.png)'],
		primary: '#C8102E',
		secondary: '#012169',
		full: 'UNITED KINGDOM',
		full2: 'GREAT BRITAIN'
	},
	{
		abbreviation: 'EQG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/eqg.png)'],
		primary: '#d50032',
		secondary: '#3F9A00',
		full: 'EQUATORIAL GUINEA',
	},
	{
		abbreviation: 'ERI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/eri.png)'],
		primary: '#EA0437',
		secondary: '#FFC726',
		full: 'ERITREA',
	},
	{
		abbreviation: 'ESP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/88.png)'],
		primary: '#E72425',
		secondary: '#F2D02C',
	},
	{
		abbreviation: 'ESPA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/esp.png)'],
		primary: '#ea2300',
		secondary: '#FDFF16',
		full: 'SPAIN',
	},
	{
		abbreviation: 'EST',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/est.png)'],
		primary: '#195ccd',
		secondary: '#000000',
		full: 'ESTONIA',
	},
	{
		abbreviation: 'ETH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/eth.png)'],
		primary: '#078930',
		secondary: '#FCDD09',
		full: 'ETHIOPIA',
	},
	{
		abbreviation: 'EVE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/368.png)'],
		primary: '#003399',
		secondary: '#000000',
		full: 'EVERTON',
	},
	{
		abbreviation: 'EXE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/324.png)'],
		primary: '#C8142F',
		secondary: '#ffff00',
		full: 'EXETER CITY',
	},
	{
		abbreviation: 'FCJ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/17851.png)'],
		primary: '#89f442',
		secondary: '#ffffff',
		full: 'FC JUAREZ',
	},
	{
		abbreviation: 'FCK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/11336.png)'],
		primary: '#000000',
		secondary: '#028542',
		full: 'KRASNODAR',
	},
	{
		abbreviation: 'FCUB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/598.png)'],
		primary: '#eb1923',
		secondary: '#fddc02',
		full: 'FC UNION BERLIN',
	},
	{
		abbreviation: 'FCZA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/17520.png)'],
		primary: '#C60000',
		secondary: '#1a1a1a',
		full: 'FC ZORYA LUHANSK',
	},
	{
		abbreviation: 'FEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/436.png)'],
		primary: '#ffff00',
		secondary: '#cccccc',
		full: 'FENERBAHCE',
	},
	{
		abbreviation: 'FERE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/622.png)'],
		primary: '#248c21',
		secondary: '#9f8917',
		full: 'FERENCVAROS',
	},
	{
		abbreviation: 'FEY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/142.png)'],
		primary: '#CF010E',
		secondary: '#787878',
		full: 'FEYENOORD ROTTERDAM',
	},
	{
		abbreviation: 'FGR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/282.png)'],
		primary: '#aeff2b',
		secondary: '#1a1a1a',
		full: 'FOREST GREEN ROVERS',
	},
	{
		abbreviation: 'FIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/fin.png)'],
		primary: '#ffffff',
		secondary: '#052a87',
		full: 'FINLAND',
	},
	{
		abbreviation: 'FIO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/109.png)'],
		primary: '#ec1c23',
		secondary: '#a29160',
		full: 'FIORENTINA',
	},
	{
		abbreviation: 'FKQA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/10414.png)'],
		primary: '#000000',
		secondary: '#ffffff',
		full: 'FK QARABAG',
	},
	{
		abbreviation: 'FLE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3891.png)'],
		primary: '#C8142F',
		secondary: '#ffff00',
		full: 'FLEETWOOD TOWN',
	},
	{
		abbreviation: 'FLO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/12146.png)'],
		primary: '#34a66d',
		secondary: '#a9aaa9',
		full: 'FLORA',
	},
	{
		abbreviation: 'FOR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/143.png)'],
		primary: '#FCEE33',
		secondary: '#091453',
		full: 'FORTUNA SITTARD',
	},
	{
		abbreviation: 'FRA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/fra.png)'],
		primary: '#112855',
		secondary: '#DE0026',
		full: 'FRANCE',
	},
	{
		abbreviation: 'FREI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/126.png)'],
		primary: '#000000',
		secondary: '#7c7c7c',
		full: 'SC FREIBURG',
	},
	{
		abbreviation: 'FRO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/fro.png)'],
		primary: '#EF2B2D',
		secondary: '#195ccd',
		full: 'FAROE ISLANDS',
	},
	{
		abbreviation: 'FUL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/370.png)'],
		primary: '#cc0000',
		secondary: '#000000',
		full: 'FULHAM',
	},
	{
		abbreviation: 'FUR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3070.png)'],
		primary: '#009932',
		secondary: '#ffffff',
	},
	{
		abbreviation: 'GAB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/gab.png)'],
		primary: '#009E60',
		secondary: '#FCD116',
		full: 'GABON',
	},
	{
		abbreviation: 'GAE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3706.png)'],
		primary: '#C60000',
		secondary: '#8d0057',
		full: 'GO AHEAD EAGLES',
	},
	{
		abbreviation: 'GAL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/432.png)'],
		primary: '#aa0031',
		secondary: '#000000',
		full: 'GALATASARAY',
	},
	{
		abbreviation: 'GAM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/gam.png)'],
		primary: '#CE1126',
		secondary: '#0C1C8C',
		full: 'GAMBIA',
	},
	{
		abbreviation: 'GCF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3747.png)'],
		primary: '#a61b2b',
		secondary: '#000000',
		full: 'GRANADA',
	},
	{
		abbreviation: 'GDL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/219.png)'],
		primary: '#EF0107',
		secondary: '#104e8a',
		full: 'GUADALAJARA',
	},
	{
		abbreviation: 'GEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3263.png)'],
		primary: '#ad1919',
		secondary: '#05232f',
		full: 'GENOA',
	},
	{
		abbreviation: 'GENK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/938.png)'],
		primary: '#0000ff',
		secondary: '#cccccc',
		full: 'RACING GENK',
	},
	{
		abbreviation: 'GENT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3611.png)'],
		primary: '#0000ff',
		secondary: '#ff6600',
		full: 'KAA GENT',
	},
	{
		abbreviation: 'GEO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/geo.png)'],
		primary: '#ffffff',
		secondary: '#DE2918',
		full: 'GEORGIA',
	},
	{
		abbreviation: 'GER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ger.png)'],
		primary: '#FD0300',
		secondary: '#000000',
		full: 'GERMANY',
	},
	{
		abbreviation: 'GET',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2922.png)'],
		primary: '#005999',
		secondary: '#c43a2f',
		full: 'GETAFE',
	},
	{
		abbreviation: 'GHA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/gha.png)'],
		primary: '#dabf00',
		secondary: '#ea2300',
		full: 'GHANA',
	},
	{
		abbreviation: 'GIB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/16721.png)'],
		primary: '#DE2918',
		secondary: '#ffffff',
		full: 'GIBRALTAR',
	},
	{
		abbreviation: 'GIL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/389.png)'],
		primary: '#000099',
		secondary: '#C60000',
		full: 'GILLINGHAM',
	},
	{
		abbreviation: 'GNB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/gnb.png)'],
		primary: '#000000',
		secondary: '#CE1126',
		full: 'GUINEA-BISSAU',
	},
	{
		abbreviation: 'GRE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/gre.png)'],
		primary: '#ffffff',
		secondary: '#0A0080',
		full: 'GREECE',
	},
	{
		abbreviation: 'GRN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/grn.png)'],
		primary: '#FCD116',
		secondary: '#007A5E',
		full: 'GRENADA',
	},
	{
		abbreviation: 'GRO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/145.png)'],
		primary: '#006416',
		secondary: '#420089',
		full: 'FC GRONINGEN',
	},
	{
		abbreviation: 'GUA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/10529.png)'],
		primary: '#C71B36',
		secondary: '#00257C',
		full: 'GUAM',
	},
	{
		abbreviation: 'GUI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/gui.png)'],
		primary: '#CE1126',
		secondary: '#FCD116',
		full: 'GUINEA',
	},
	{
		abbreviation: 'GUY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/guy.png)'],
		primary: '#fff200',
		secondary: '#22b14c',
		full: 'GUYANA',
	},
	{
		abbreviation: 'HAI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/hai.png)'],
		primary: '#0033a0',
		secondary: '#D20300',
		full: 'HAITI',
	},
	{
		abbreviation: 'HAR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/19262.png)'],
		primary: '#ffff00',
		secondary: '#aeeaff',
		full: 'HARROGATE TOWN',
	},
	{
		abbreviation: 'HART',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/323.png)'],
		primary: '#0000FF',
		secondary: '#1a1a1a',
		full: 'HARTLEPOOL UNITED',
	},
	{
		abbreviation: 'HBK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/21127.png)'],
		primary: '#000000',
		secondary: '#C60000',
		full: 'HB KØGE',
	},
	{
		abbreviation: 'HEE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/146.png)'],
		primary: '#e40624',
		secondary: '#004993',
		full: 'HEERENVEEN',
	},
	{
		abbreviation: 'HER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3708.png)'],
		primary: '#000000',
		secondary: '#3c357b',
		full: 'HERACLES ALMELO',
	},
	{
		abbreviation: 'HERT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/129.png)'],
		primary: '#004d9e',
		secondary: '#000000',
		full: 'HERTHA BERLIN',
	},
	{
		abbreviation: 'HJKH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/502.png)'],
		primary: '#0000ff',
		secondary: '#000099',
		full: 'HJK HELSINKI',
	},
	{
		abbreviation: 'HKG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/hkg.png)'],
		primary: '#E70000',
		secondary: '#FFFFFF',
		full: 'HONG KONG',
	},
	{
		abbreviation: 'HOF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/7911.png)'],
		primary: '#1961b5',
		secondary: '#000000',
		full: 'TSG HOFFENHEIM',
	},
	{
		abbreviation: 'HON',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/hon.png)'],
		primary: '#333ebd',
		secondary: '#000000',
		full: 'HONDURAS',
	},
	{
		abbreviation: 'HOU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/6077.png)'],
		primary: '#f4911e',
		secondary: '#92c3f1',
		full: 'HOUSTON DYNAMO FC',
	},
	{
		abbreviation: 'HOUS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/17346.png)'],
		primary: '#F36600',
		secondary: '#a7c0dd',
		full: 'HOUSTON DASH',
	},
	{
		abbreviation: 'HUD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/335.png)'],
		primary: '#0074d0',
		secondary: '#081d54',
		full: 'HUDDERSFIELD TOWN',
	},
	{
		abbreviation: 'HUE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/5413.png)'],
		primary: '#283380',
		secondary: '#cf122d',
		full: 'HUESCA',
	},
	{
		abbreviation: 'HUL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/306.png)'],
		primary: '#F5A12D',
		secondary: '#1a1a1a',
		full: 'HULL CITY',
	},
	{
		abbreviation: 'HUN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/hun.png)'],
		primary: '#E70000',
		secondary: '#038751',
		full: 'HUNGARY',
	},
	{
		abbreviation: 'IBFK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/7914.png)'],
		primary: '#1e3a58',
		secondary: '#ed5e27',
		full: 'ISTANBUL BASAKSEHIR',
	},
	{
		abbreviation: 'IDN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/idn.png)'],
		primary: '#E70000',
		secondary: '#FFFFFF',
		full: 'INDONESIA',
	},
	{
		abbreviation: 'IND',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ind.png)'],
		primary: '#FF9933',
		secondary: '#138808',
		full: 'INDIA',
	},
	{
		abbreviation: 'INT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/110.png)'],
		primary: '#a29161',
		secondary: '#221f20',
		full: 'INTERNAZIONALE',
	},
	{
		abbreviation: 'IPS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/373.png)'],
		primary: '#0000fa',
		secondary: '#890000',
		full: 'IPSWICH TOWN',
	},
	{
		abbreviation: 'IRL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/irl.png)'],
		primary: '#32ad42',
		secondary: '#FF7200',
		full: 'REPUBLIC OF IRELAND',
	},
	{
		abbreviation: 'IRN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/irn.png)'],
		primary: '#C60000',
		secondary: '#000000',
		full: 'IR IRAN',
	},
	{
		abbreviation: 'IRQ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/irq.png)'],
		primary: '#00843d',
		secondary: '#CE1126',
		full: 'IRAQ',
	},
	{
		abbreviation: 'ISL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/isl.png)'],
		primary: '#0c2fff',
		secondary: '#D72927',
		full: 'ICELAND',
	},
	{
		abbreviation: 'ISR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/isr.png)'],
		primary: '#014ACD',
		secondary: '#5CBFEB',
		full: 'ISRAEL',
	},
	{
		abbreviation: 'ITA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ita.png)'],
		primary: '#309905',
		secondary: '#FF0108',
		full: 'ITALY',
	},
	{
		abbreviation: 'JABL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/7922.png)'],
		primary: '#025719',
		secondary: '#000000',
		full: 'JABLONEC',
	},
	{
		abbreviation: 'JAM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/jam.png)'],
		primary: '#ffe000',
		secondary: '#000000',
		full: 'JAMAICA',
	},
	{
		abbreviation: 'JOR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/jor.png)'],
		primary: '#E70000',
		secondary: '#000000',
		full: 'JORDAN',
	},
	{
		abbreviation: 'JPN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/jpn.png)'],
		primary: '#ED1D24',
		secondary: '#ffffff',
		full: 'JAPAN',
	},
	{
		abbreviation: 'JUV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/111.png)'],
		primary: '#000000',
		secondary: '#ffcd00',
		full: 'JUVENTUS',
	},
	{
		abbreviation: 'KAIR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2528.png)'],
		primary: '#FCEE33',
		secondary: '#81c0ff',
		full: 'KAIRAT ALMATY',
	},
	{
		abbreviation: 'KAZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/kaz.png)'],
		primary: '#ffec00',
		secondary: '#00bfff',
		full: 'KAZAKHSTAN',
	},
	{
		abbreviation: 'KC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/20907.png)'],
		primary: '#67C4C7',
		secondary: '#CD343C',
		full: 'KANSAS CITY CURRENT',
	},
	{
		abbreviation: 'KEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ken.png)'],
		primary: '#922529',
		secondary: '#008C51',
		full: 'KENYA',
	},
	{
		abbreviation: 'KGZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/kgz.png)'],
		primary: '#E70000',
		secondary: '#E70000',
		full: 'KYRGYZ REPUBLIC',
	},
	{
		abbreviation: 'KH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/cam.png)'],
		primary: '#d50032',
		secondary: '#032EA1',
		full: 'CAMBODIA',
	},
	{
		abbreviation: 'KHA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/21125.png)'],
		primary: '#000000',
		secondary: '#C60000',
		full: 'WFC KHARKIV',
	},
	{
		abbreviation: 'KIEV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/440.png)'],
		primary: '#176fc1',
		secondary: '#e4ac21',
		full: 'DYNAMO KIEV',
	},
	{
		abbreviation: 'KOR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/kors.png)'],
		primary: '#ea2300',
		secondary: '#0a2f41',
		full: 'SOUTH KOREA',
	},
	{
		abbreviation: 'KOS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/18272.png)'],
		primary: '#0000cd',
		secondary: '#ffec00',
		full: 'KOSOVO',
	},
	{
		abbreviation: 'KSA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ksa.png)'],
		primary: '#168d40',
		secondary: '#dddddd',
		full: 'SAUDI ARABIA',
	},
	{
		abbreviation: 'KUW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/kuw.png)'],
		primary: '#000000',
		secondary: '#CE1126',
		full: 'KUWAIT',
	},
	{
		abbreviation: 'LA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/187.png)'],
		primary: '#0065a4',
		secondary: '#00245d',
		full: 'LA GALAXY',
	},
	{
		abbreviation: 'LAFC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/18966.png)'],
		primary: '#000000',
		secondary: '#c39e6d',
		full: 'LOS ANGELES FC',
	},
	{
		abbreviation: 'LAO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/lao.png)'],
		primary: '#EF3340',
		secondary: '#003DA5',
		full: 'LAOS',
	},
	{
		abbreviation: 'LAS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/4411.png)'],
		primary: '#ffffff',
		secondary: '#000000',
		full: 'LASK LINZ',
	},
	{
		abbreviation: 'LAZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/112.png)'],
		primary: '#87d8f7',
		secondary: '#15366f',
		full: 'LAZIO',
	},
	{
		abbreviation: 'LBR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/lbr.png)'],
		primary: '#002868',
		secondary: '#BF0A30',
		full: 'LIBERIA',
	},
	{
		abbreviation: 'LBY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/lby.png)'],
		primary: '#E70013',
		secondary: '#000000',
		full: 'LIBYA',
	},
	{
		abbreviation: 'LCA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/lca.png)'],
		primary: '#63C6F7',
		secondary: '#F4CA14',
		full: 'ST. LUCIA',
	},
	{
		abbreviation: 'LCN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/314.png)'],
		primary: '#C8142F',
		secondary: '#c6d4db',
		full: 'LINCOLN CITY',
	},
	{
		abbreviation: 'LEE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/357.png)'],
		primary: '#ffcd00',
		secondary: '#1d428a',
		full: 'LEEDS UNITED',
	},
	{
		abbreviation: 'LEGI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/555.png)'],
		primary: '#2b6a36',
		secondary: '#ffffff',
		full: 'LEGIA WARSAW',
	},
	{
		abbreviation: 'LEI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/375.png)'],
		primary: '#003090',
		secondary: '#fdbe11',
		full: 'LEICESTER CITY',
	},
	{
		abbreviation: 'LEIP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/11420.png)'],
		primary: '#dd0741',
		secondary: '#001f47',
		full: 'RB LEIPZIG',
	},
	{
		abbreviation: 'LEO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/228.png)'],
		primary: '#008000',
		secondary: '#FFF61B',
		full: 'LEÓN',
	},
	{
		abbreviation: 'LES',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/les.png)'],
		primary: '#0042AD',
		secondary: '#CE1020',
		full: 'LESOTHO',
	},
	{
		abbreviation: 'LEV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/1538.png)'],
		primary: '#b4053f',
		secondary: '#005ca5',
		full: 'LEVANTE',
	},
	{
		abbreviation: 'LEVE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/131.png)'],
		primary: '#e32221',
		secondary: '#f3e500',
		full: 'BAYER LEVERKUSEN',
	},
	{
		abbreviation: 'LEY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/309.png)'],
		primary: '#C8142F',
		secondary: '#0e00f7',
		full: 'LEYTON ORIENT',
	},
	{
		abbreviation: 'LIB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/lib.png)'],
		primary: '#E70000',
		secondary: '#FFFFFF',
		full: 'LEBANON',
	},
	{
		abbreviation: 'LIE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/lie.png)'],
		primary: '#0000cd',
		secondary: '#E70000',
		full: 'LIECHTENSTEIN',
	},
	{
		abbreviation: 'LILL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/166.png)'],
		primary: '#ef2f24',
		secondary: '#1a1a1a',
		full: 'LILLE',
	},
	{
		abbreviation: 'LIV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/364.png)'],
		primary: '#c8102e',
		secondary: '#00b2a9',
		full: 'LIVERPOOL',
	},
	{
		abbreviation: 'LMO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/442.png)'],
		primary: '#fd0715',
		secondary: '#028542',
		full: 'LOKOMOTIV MOSCOW',
	},
	{
		abbreviation: 'LOR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/273.png)'],
		primary: '#ff4a00',
		secondary: '#ffffff',
		full: 'LORIENT',
	},
	{
		abbreviation: 'LOU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/20905.png)'],
		primary: '#C3B5FF',
		secondary: '#14002F',
		full: 'RACING LOUISVILLE FC',
	},
	{
		abbreviation: 'LRI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/17856.png)'],
		primary: '#C60000',
		secondary: '#ffffff',
		full: 'LINCOLN RED IMPS',
	},
	{
		abbreviation: 'LTU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ltu.png)'],
		primary: '#ffe400',
		secondary: '#DE2918',
		full: 'LITHUANIA',
	},
	{
		abbreviation: 'LUD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/13018.png)'],
		primary: '#008000',
		secondary: '#ffffff',
		full: 'LUDOGORETS RAZGRAD',
	},
	{
		abbreviation: 'LUT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/301.png)'],
		primary: '#ff4f00',
		secondary: '#ffffff',
		full: 'LUTON TOWN',
	},
	{
		abbreviation: 'LUX',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/lux.png)'],
		primary: '#E70000',
		secondary: '#ffffff',
		full: 'LUXEMBOURG',
	},
	{
		abbreviation: 'LVA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/lva.png)'],
		primary: '#992242',
		secondary: '#ffffff',
		full: 'LATVIA',
	},
	{
		abbreviation: 'LYON',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/167.png)'],
		primary: '#ffffff',
		secondary: '#ff4a00',
		full: 'LYON',
	},
	{
		abbreviation: 'MA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mar.png)'],
		primary: '#d50414',
		secondary: '#117957',
		full: 'MOROCCO',
	},
	{
		abbreviation: 'MAC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mac.png)'],
		primary: '#00795E',
		secondary: '#FBD20E',
		full: 'MACAU',
	},
	{
		abbreviation: 'MACC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/611.png)'],
		primary: '#34a66d',
		secondary: '#000000',
		full: 'MACCABI HAIFA',
	},
	{
		abbreviation: 'MAD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/86.png)'],
		primary: '#febe10',
		secondary: '#00529f',
		full: 'REAL MADRID',
	},
	{
		abbreviation: 'MAIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2950.png)'],
		primary: '#c3141e',
		secondary: '#000000',
		full: 'MAINZ',
	},
	{
		abbreviation: 'MAL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/84.png)'],
		primary: '#E20613',
		secondary: '#000000',
		full: 'MALLORCA',
	},
	{
		abbreviation: 'MALM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2720.png)'],
		primary: '#4FC0E4',
		secondary: '#FFFFFF',
		full: 'MALMO FF',
	},
	{
		abbreviation: 'MAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/360.png)'],
		primary: '#da291c',
		secondary: '#fbe122',
		full: 'MANCHESTER UNITED',
	},
	{
		abbreviation: 'MANS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/304.png)'],
		primary: '#FECD32',
		secondary: '#1a1a1a',
		full: 'MANSFIELD TOWN',
	},
	{
		abbreviation: 'MAR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/176.png)'],
		primary: '#2faee0',
		secondary: '#bea064',
		full: 'MARSEILLE',
	},
	{
		abbreviation: 'MAS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mas.png)'],
		primary: '#DE0019',
		secondary: '#011E68',
		full: 'MALAYSIA',
	},
	{
		abbreviation: 'MAZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/20702.png)'],
		primary: '#9400D3',
		secondary: '#000000',
		full: 'MAZATLÁN FC',
	},
	{
		abbreviation: 'MDA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mda.png)'],
		primary: '#0c2fff',
		secondary: '#FCD116',
		full: 'MOLDOVA',
	},
	{
		abbreviation: 'MDG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mad.png)'],
		primary: '#FC3D32',
		secondary: '#007E3A',
		full: 'MADAGASCAR',
	},
	{
		abbreviation: 'MDV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mdv.png)'],
		primary: '#D21034',
		secondary: '#007E3A',
		full: 'MALDIVES',
	},
	{
		abbreviation: 'METZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/177.png)'],
		primary: '#8C3140',
		secondary: '#f3ecc0',
		full: 'METZ',
	},
	{
		abbreviation: 'MEX',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mex.png)'],
		primary: '#014227',
		secondary: '#CE1226',
		full: 'MEXICO',
	},
	{
		abbreviation: 'MGL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mgl.png)'],
		primary: '#c4272F',
		secondary: '#0076CC',
		full: 'MONGOLIA',
	},
	{
		abbreviation: 'MIA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/20232.png)'],
		primary: '#f7b5cd',
		secondary: '#231f20',
		full: 'INTER MIAMI CF',
	},
	{
		abbreviation: 'MID',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/369.png)'],
		primary: '#f42727',
		secondary: '#1a1a1a',
		full: 'MIDDLESBROUGH',
	},
	{
		abbreviation: 'MIDT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/572.png)'],
		primary: '#e00210',
		secondary: '#000000',
		full: 'MIDTJYLLAND',
	},
	{
		abbreviation: 'MIL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/103.png)'],
		primary: '#fb090b',
		secondary: '#000000',
		full: 'AC MILAN',
	},
	{
		abbreviation: 'MILL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/391.png)'],
		primary: '#091453',
		secondary: '#228b22',
		full: 'MILLWALL',
	},
	{
		abbreviation: 'MIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/17362.png)'],
		primary: '#585958',
		secondary: '#000000',
		full: 'MINNESOTA UNITED FC',
	},
	{
		abbreviation: 'MK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mkd.png)'],
		primary: '#E70000',
		secondary: '#FDFF00',
		full: 'NORTH MACEDONIA',
	},
	{
		abbreviation: 'MKD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/390.png)'],
		primary: '#ffffff',
		secondary: '#ff4f00',
		full: 'MILTON KEYNES DONS',
	},
	{
		abbreviation: 'MLI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mli.png)'],
		primary: '#000000',
		secondary: '#C60000',
		full: 'MALI',
	},
	{
		abbreviation: 'MLT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mlt.png)'],
		primary: '#DE2400',
		secondary: '#ffffff',
		full: 'MALTA',
	},
	{
		abbreviation: 'MNC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/382.png)'],
		primary: '#6cabdd',
		secondary: '#1c2c5b',
		full: 'MANCHESTER CITY',
	},
	{
		abbreviation: 'MNE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mtg.png)'],
		primary: '#E70000',
		secondary: '#D4AE3D',
		full: 'MONTENEGRO',
	},
	{
		abbreviation: 'MON',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/268.png)'],
		primary: '#028542',
		secondary: '#000000',
		full: 'BORUSSIA MONCHENGLADBACH',
	},
	{
		abbreviation: 'MONT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/274.png)'],
		primary: '#011F68',
		secondary: '#ffffff',
		full: 'MONTPELLIER',
	},
	{
		abbreviation: 'MONY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/220.png)'],
		primary: '#001C58',
		secondary: '#ffffff',
		full: 'MONTERREY',
	},
	{
		abbreviation: 'MOR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/278.png)'],
		primary: '#C8142F',
		secondary: '#ffff00',
		full: 'MORECAMBE',
	},
	{
		abbreviation: 'MOZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/moz.png)'],
		primary: '#D21034',
		secondary: '#007168',
		full: 'MOZAMBIQUE',
	},
	{
		abbreviation: 'MRI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mri.png)'],
		primary: '#EA2839',
		secondary: '#1A206D',
		full: 'MAURITIUS',
	},
	{
		abbreviation: 'MSR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/msr.png)'],
		primary: '#001F7E',
		secondary: '#D00C27',
		full: 'MONTSERRAT',
	},
	{
		abbreviation: 'MTA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/524.png)'],
		primary: '#ffff00',
		secondary: '#020202',
		full: 'MACCABI TEL-AVIV',
	},
	{
		abbreviation: 'MTL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/9720.png)'],
		primary: '#2b63ad',
		secondary: '#373536',
		full: 'CF MONTRÉAL',
	},
	{
		abbreviation: 'MTN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mtn.png)'],
		primary: '#D01C1F',
		secondary: '#00A95C',
		full: 'MAURITANIA',
	},
	{
		abbreviation: 'MUN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/132.png)'],
		primary: '#0066b2',
		secondary: '#dc052d',
		full: 'BAYERN MUNICH',
	},
	{
		abbreviation: 'MWI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mwi.png)'],
		primary: '#CE1126',
		secondary: '#339E35',
		full: 'MALAWI',
	},
	{
		abbreviation: 'MYA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/mya.png)'],
		primary: '#E70000',
		secondary: '#34B233',
		full: 'MYANMAR',
	},
	{
		abbreviation: 'NAM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/nam.png)'],
		primary: '#FFCE00',
		secondary: '#003580',
		full: 'NAMIBIA',
	},
	{
		abbreviation: 'NANT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/165.png)'],
		primary: '#ffff00',
		secondary: '#ffff00',
		full: 'NANTES',
	},
	{
		abbreviation: 'NAP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/114.png)'],
		primary: '#003c82',
		secondary: '#12a0d7',
		full: 'NAPOLI',
	},
	{
		abbreviation: 'NCA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/nca.png)'],
		primary: '#333ebd',
		secondary: '#000000',
		full: 'NICARAGUA',
	},
	{
		abbreviation: 'NCC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/15366.png)'],
		primary: '#0271B9',
		secondary: '#fafafc',
		full: 'NORTH CAROLINA COURAGE',
	},
	{
		abbreviation: 'NE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/189.png)'],
		primary: '#e51938',
		secondary: '#002b5c',
		full: 'NEW ENGLAND REVOLUTION',
	},
	{
		abbreviation: 'NEC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/147.png)'],
		primary: '#ef2f24',
		secondary: '#000000',
		full: 'NEC NIJMEGEN',
	},
	{
		abbreviation: 'NED',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ned.png)'],
		primary: '#FD0300',
		secondary: '#0730CE',
		full: 'NETHERLANDS',
	},
	{
		abbreviation: 'NEP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/nep.png)'],
		primary: '#E70000',
		secondary: '#000080',
		full: 'NEPAL',
	},
	{
		abbreviation: 'NEW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/361.png)'],
		primary: '#241f20',
		secondary: '#f1be48',
		full: 'NEWCASTLE UNITED',
	},
	{
		abbreviation: 'NEWP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/635.png)'],
		primary: '#FECD32',
		secondary: '#cdcdcd',
		full: 'NEWPORT COUNTY',
	},
	{
		abbreviation: 'NEX',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/229.png)'],
		primary: '#EF0107',
		secondary: '#000000',
		full: 'NECAXA',
	},
	{
		abbreviation: 'NGA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/nga.png)'],
		primary: '#5bbd19',
		secondary: '#1a4030',
		full: 'NIGERIA',
	},
	{
		abbreviation: 'NICE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2502.png)'],
		primary: '#1a1a1a',
		secondary: '#ffffff',
		full: 'NICE',
	},
	{
		abbreviation: 'NIG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/nig.png)'],
		primary: '#E05206',
		secondary: '#0DB02B',
		full: 'NIGER',
	},
	{
		abbreviation: 'NIR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/nir.png)'],
		primary: '#CE1224',
		secondary: '#ffffff',
		full: 'NORTHERN IRELAND',
	},
	{
		abbreviation: 'NJNY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/15364.png)'],
		primary: '#000000',
		secondary: '#0ceefa',
		full: 'NJ/NY GOTHAM FC',
	},
	{
		abbreviation: 'NO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/nor.png)'],
		primary: '#ee3b33',
		secondary: '#222576',
		full: 'NORWAY',
	},
	{
		abbreviation: 'NOR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/381.png)'],
		primary: '#109A55',
		secondary: '#FDDD03',
	},
	{
		abbreviation: 'NORT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/353.png)'],
		primary: '#8E003B',
		secondary: '#1a1a1a',
		full: 'NORTHAMPTON TOWN',
	},
	{
		abbreviation: 'NOT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/393.png)'],
		primary: '#f42727',
		secondary: '#1f365e',
		full: 'NOTTINGHAM FOREST',
	},
	{
		abbreviation: 'NSH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/18986.png)'],
		primary: '#ece938',
		secondary: '#190f44',
		full: 'NASHVILLE SC',
	},
	{
		abbreviation: 'NSM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/20029.png)'],
		primary: '#000000',
		secondary: '#ffffff',
		full: 'NS MURA',
	},
	{
		abbreviation: 'NY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/190.png)'],
		primary: '#e31351',
		secondary: '#002f65',
		full: 'NEW YORK RED BULLS',
	},
	{
		abbreviation: 'NYC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/17606.png)'],
		primary: '#6cace4',
		secondary: '#041e42',
		full: 'NEW YORK CITY FC',
	},
	{
		abbreviation: 'OLD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/332.png)'],
		primary: '#0e00f7',
		secondary: '#F7AA25',
		full: 'OLDHAM ATHLETIC',
	},
	{
		abbreviation: 'OLR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/15363.png)'],
		primary: '#0000bf',
		secondary: '#ffffff',
		full: 'OL REIGN',
	},
	{
		abbreviation: 'OLY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/435.png)'],
		primary: '#e7c569',
		secondary: '#e21e26',
		full: 'OLYMPIAKOS',
	},
	{
		abbreviation: 'OMA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/oma.png)'],
		primary: '#E70000',
		secondary: '#FFFFFF',
		full: 'OMAN',
	},
	{
		abbreviation: 'OMON',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/617.png)'],
		primary: '#025719',
		secondary: '#ffffff',
		full: 'OMONIA NICOSIA',
	},
	{
		abbreviation: 'ORL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/12011.png)'],
		primary: '#61259e',
		secondary: '#ffe293',
		full: 'ORLANDO CITY SC',
	},
	{
		abbreviation: 'ORLP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/18206.png)'],
		primary: '#633492',
		secondary: '#a7c0dd',
		full: 'ORLANDO PRIDE',
	},
	{
		abbreviation: 'OSA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/97.png)'],
		primary: '#0a346f',
		secondary: '#d91a21',
		full: 'OSASUNA',
	},
	{
		abbreviation: 'OXF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/311.png)'],
		primary: '#ffff00',
		secondary: '#ffffff',
		full: 'OXFORD UNITED',
	},
	{
		abbreviation: 'PAC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/234.png)'],
		primary: '#001C58',
		secondary: '#02b0d0',
		full: 'PACHUCA',
	},
	{
		abbreviation: 'PAK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/pak.png)'],
		primary: '#01411C',
		secondary: '#FFFFFF',
		full: 'PAKISTAN',
	},
	{
		abbreviation: 'PAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/pan.png)'],
		primary: '#D20300',
		secondary: '#0537e4',
		full: 'PANAMA',
	},
	{
		abbreviation: 'PAOK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/605.png)'],
		primary: '#000000',
		secondary: '#ffffff',
		full: 'PAOK SALONIKA',
	},
	{
		abbreviation: 'PAR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/115.png)'],
		primary: '#1b4094',
		secondary: '#ffd200',
		full: 'PARMA',
	},
	{
		abbreviation: 'PART',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/541.png)'],
		primary: '#020202',
		secondary: '#ffffff',
		full: 'PARTIZAN BELGRADE',
	},
	{
		abbreviation: 'PEC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2565.png)'],
		primary: '#0000d4',
		secondary: '#307B64',
		full: 'PEC ZWOLLE',
	},
	{
		abbreviation: 'PER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/per.png)'],
		primary: '#ea2300',
		secondary: '#000000',
		full: 'PERU',
	},
	{
		abbreviation: 'PET',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/342.png)'],
		primary: '#0000fa',
		secondary: '#ebebeb',
		full: 'PETERBOROUGH UNITED',
	},
	{
		abbreviation: 'PGY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/par.png)'],
		primary: '#ea2300',
		secondary: '#0c2fff',
		full: 'PARAGUAY',
	},
	{
		abbreviation: 'PHI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/10739.png)'],
		primary: '#002d55',
		secondary: '#5090cd',
		full: 'PHILADELPHIA UNION',
	},
	{
		abbreviation: 'PHIL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/phi.png)'],
		primary: '#0000cd',
		secondary: '#CE1126',
		full: 'PHILIPPINES',
	},
	{
		abbreviation: 'PLE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/pal.png)'],
		primary: '#CE1126',
		secondary: '#007A3D',
		full: 'PALESTINE',
	},
	{
		abbreviation: 'PLY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/307.png)'],
		primary: '#003e00',
		secondary: '#9eefe1',
		full: 'PLYMOUTH ARGYLE',
	},
	{
		abbreviation: 'PNE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/394.png)'],
		primary: '#ffffff',
		secondary: '#007921',
		full: 'PRESTON NORTH END',
	},
	{
		abbreviation: 'PO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/385.png)'],
		primary: '#0000fa',
		secondary: '#c6d4db',
		full: 'PORTSMOUTH',
	},
	{
		abbreviation: 'POL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/pol.png)'],
		primary: '#ffffff',
		secondary: '#D12F2A',
		full: 'POLAND',
	},
	{
		abbreviation: 'POR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/437.png)'],
		primary: '#00428c',
		secondary: '#d60019',
		full: 'PORTO',
	},
	{
		abbreviation: 'PORT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/9723.png)'],
		primary: '#00482b',
		secondary: '#d69a00',
		full: 'PORTLAND TIMBERS',
	},
	{
		abbreviation: 'PRK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/korn.png)'],
		primary: '#ea2300',
		secondary: '#0081d6',
		full: 'NORTH KOREA',
	},
	{
		abbreviation: 'PRT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/15362.png)'],
		primary: '#004812',
		secondary: '#000000',
		full: 'PORTLAND THORNS FC',
	},
	{
		abbreviation: 'PSG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/160.png)'],
		primary: '#004170',
		secondary: '#e30613',
		full: 'PARIS SAINT-GERMAIN',
	},
	{
		abbreviation: 'PSV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/148.png)'],
		primary: '#ef2f24',
		secondary: '#2a2247',
		full: 'PSV EINDHOVEN',
	},
	{
		abbreviation: 'PT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/por.png)'],
		primary: '#ea2300',
		secondary: '#036233',
		full: 'PORTUGAL',
	},
	{
		abbreviation: 'PUE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/231.png)'],
		primary: '#ffffff',
		secondary: '#0032a8',
		full: 'PUEBLA',
	},
	{
		abbreviation: 'PUR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/11766.png)'],
		primary: '#0537e4',
		secondary: '#C60000',
		full: 'PUERTO RICO',
	},
	{
		abbreviation: 'QAT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/qat.png)'],
		primary: '#691a40',
		secondary: '#000000',
		full: 'QATAR',
	},
	{
		abbreviation: 'QPR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/334.png)'],
		primary: '#0000d4',
		secondary: '#1a1a1a',
		full: 'QUEENS PARK RANGERS',
	},
	{
		abbreviation: 'QRT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/222.png)'],
		primary: '#212121',
		secondary: '#02b0d0',
		full: 'QUERÉTARO',
	},
	{
		abbreviation: 'RAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/257.png)'],
		primary: '#0064cd',
		secondary: '#000000',
		full: 'RANGERS',
	},
	{
		abbreviation: 'RAND',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3132.png)'],
		primary: '#81c0ff',
		secondary: '#ff6600',
		full: 'RANDERS FC',
	},
	{
		abbreviation: 'RAY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/101.png)'],
		primary: '#000000',
		secondary: '#e53027',
	},
	{
		abbreviation: 'RCL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/175.png)'],
		primary: '#ffff00',
		secondary: '#006a2e',
		full: 'LENS',
	},
	{
		abbreviation: 'REA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/338.png)'],
		primary: '#0000fa',
		secondary: '#ffde00',
		full: 'READING',
	},
	{
		abbreviation: 'REIM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3243.png)'],
		primary: '#ef2f24',
		secondary: '#0000bf',
		full: 'STADE DE REIMS',
	},
	{
		abbreviation: 'RENN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/169.png)'],
		primary: '#e13327',
		secondary: '#000000',
		full: 'STADE RENNES',
	},
	{
		abbreviation: 'RKC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/155.png)'],
		primary: '#FCEE33',
		secondary: '#000000',
		full: 'RKC WAALWIJK',
	},
	{
		abbreviation: 'ROCH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/303.png)'],
		primary: '#101087',
		secondary: '#c6d4db',
		full: 'ROCHDALE',
	},
	{
		abbreviation: 'ROMA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/104.png)'],
		primary: '#f0bc42',
		secondary: '#8e1f2f',
		full: 'AS ROMA',
	},
	{
		abbreviation: 'ROT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/402.png)'],
		primary: '#f42727',
		secondary: '#1a1a1a',
		full: 'ROTHERHAM UNITED',
	},
	{
		abbreviation: 'ROU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/rom.png)'],
		primary: '#ffec00',
		secondary: '#D12F2A',
		full: 'ROMANIA',
	},
	{
		abbreviation: 'RSA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/rsa.png)'],
		primary: '#dabf00',
		secondary: '#5bbd19',
		full: 'SOUTH AFRICA',
	},
	{
		abbreviation: 'RSB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2290.png)'],
		primary: '#FF0000',
		secondary: '#011F68',
		full: 'RED STAR BELGRADE',
	},
	{
		abbreviation: 'RSL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/4771.png)'],
		primary: '#b30838',
		secondary: '#013a81',
		full: 'REAL SALT LAKE',
	},
	{
		abbreviation: 'RSO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/89.png)'],
		primary: '#0067b1',
		secondary: '#e4b630',
		full: 'REAL SOCIEDAD',
	},
	{
		abbreviation: 'RUS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/rus.png)'],
		primary: '#ff0000',
		secondary: '#0537e4',
		full: 'RUSSIA',
	},
	{
		abbreviation: 'RWA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/rwa.png)'],
		primary: '#00A1DE',
		secondary: '#E5BE01',
		full: 'RWANDA',
	},
	{
		abbreviation: 'SAL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3240.png)'],
		primary: '#8D3F33',
		secondary: '#F6B617',
		full: 'SALERNITANA',
	},
	{
		abbreviation: 'SALF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/4703.png)'],
		primary: '#C8142F',
		secondary: '#ffffff',
		full: 'SALFORD CITY',
	},
	{
		abbreviation: 'SALZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2790.png)'],
		primary: '#df003c',
		secondary: '#ffd300',
		full: 'SALZBURG',
	},
	{
		abbreviation: 'SAMP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2734.png)'],
		primary: '#1b5497',
		secondary: '#c21718',
		full: 'SAMPDORIA',
	},
	{
		abbreviation: 'SAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/225.png)'],
		primary: '#15926d',
		secondary: '#00331b',
		full: 'SANTOS',
	},
	{
		abbreviation: 'SAS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3997.png)'],
		primary: '#00a752',
		secondary: '#000000',
		full: 'SASSUOLO',
	},
	{
		abbreviation: 'SCHA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/133.png)'],
		primary: '#004d9d',
		secondary: '#000000',
		full: 'SCHALKE 04',
	},
	{
		abbreviation: 'SCO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/sco.png)'],
		primary: '#1a2d69',
		secondary: '#dcf5f7',
		full: 'SCOTLAND',
	},
	{
		abbreviation: 'SCP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2250.png)'],
		primary: '#008057',
		secondary: '#f3c242',
		full: 'SPORTING CP',
	},
	{
		abbreviation: 'SCUN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/313.png)'],
		primary: '#82dbf5',
		secondary: '#228b22',
		full: 'SCUNTHORPE UNITED',
	},
	{
		abbreviation: 'SEA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/9726.png)'],
		primary: '#236192',
		secondary: '#658d1b',
		full: 'SEATTLE SOUNDERS FC',
	},
	{
		abbreviation: 'SEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/sen.png)'],
		primary: '#00843d',
		secondary: '#000000',
		full: 'SENEGAL',
	},
	{
		abbreviation: 'SER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/20835.png)'],
		primary: '#000000',
		secondary: '#C60000',
		full: 'SERVETTE',
	},
	{
		abbreviation: 'SEV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/243.png)'],
		primary: '#f43333',
		secondary: '#c79100',
		full: 'SEVILLA FC',
	},
	{
		abbreviation: 'SEY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/sey.png)'],
		primary: '#003F87',
		secondary: '#FCD856',
		full: 'SEYCHELLES',
	},
	{
		abbreviation: 'SHE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/614.png)'],
		primary: '#1a1a1a',
		secondary: '#ffff00',
		full: 'SHERIFF TIRASPOL',
	},
	{
		abbreviation: 'SHK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/493.png)'],
		primary: '#f0612c',
		secondary: '#000000',
		full: 'SHAKHTAR DONETSK',
	},
	{
		abbreviation: 'SHR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/302.png)'],
		primary: '#0000fa',
		secondary: '#29088a',
		full: 'SHREWSBURY TOWN',
	},
	{
		abbreviation: 'SHU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/398.png)'],
		primary: '#ee2737',
		secondary: '#0d171a',
		full: 'SHEFFIELD UNITED',
	},
	{
		abbreviation: 'SHW',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/399.png)'],
		primary: '#0e00f7',
		secondary: '#758875',
		full: 'SHEFFIELD WEDNESDAY',
	},
	{
		abbreviation: 'SIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/sin.png)'],
		primary: '#D20300',
		secondary: '#FFFFFF',
		full: 'SINGAPORE',
	},
	{
		abbreviation: 'SJ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/191.png)'],
		primary: '#1f1f1f',
		secondary: '#30457a',
		full: 'SAN JOSE EARTHQUAKES',
	},
	{
		abbreviation: 'SKC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/186.png)'],
		primary: '#93b1d7',
		secondary: '#002a5c',
		full: 'SPORTING KANSAS CITY',
	},
	{
		abbreviation: 'SKN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/skn.png)'],
		primary: '#009E49',
		secondary: '#CE1126',
		full: 'ST. KITTS AND NEVIS',
	},
	{
		abbreviation: 'SLE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/sle.png)'],
		primary: '#1EB53A',
		secondary: '#0072C6',
		full: 'SIERRA LEONE',
	},
	{
		abbreviation: 'SLOV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/521.png)'],
		primary: '#81c0ff',
		secondary: '#000099',
		full: 'SLOVAN BRATISLAVA',
	},
	{
		abbreviation: 'SLP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/494.png)'],
		primary: '#dc1f26',
		secondary: '#81c0ff',
		full: 'SLAVIA PRAGUE',
	},
	{
		abbreviation: 'SLV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/slv.png)'],
		primary: '#0537e4',
		secondary: '#000000',
		full: 'EL SALVADOR',
	},
	{
		abbreviation: 'SMR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/smr.png)'],
		primary: '#00bfff',
		secondary: '#ffffff',
		full: 'SAN MARINO',
	},
	{
		abbreviation: 'SOM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/som.png)'],
		primary: '#4189DD',
		secondary: '#FFFFFF',
		full: 'SOMALIA',
	},
	{
		abbreviation: 'SOU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/376.png)'],
		primary: '#d71920',
		secondary: '#130c0e',
		full: 'SOUTHAMPTON',
	},
	{
		abbreviation: 'SPA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/151.png)'],
		primary: '#F31522',
		secondary: '#2eccfa',
		full: 'SPARTA ROTTERDAM',
	},
	{
		abbreviation: 'SPAR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/433.png)'],
		primary: '#791b29',
		secondary: '#ffffff',
		full: 'SPARTA PRAGUE',
	},
	{
		abbreviation: 'SPEZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/4056.png)'],
		primary: '#99834a',
		secondary: '#1f1a17',
		full: 'SPEZIA',
	},
	{
		abbreviation: 'SPM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/1941.png)'],
		primary: '#DB170C',
		secondary: '#ffffff',
		full: 'SPARTAK MOSCOW',
	},
	{
		abbreviation: 'SRB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/sba.png)'],
		primary: '#ea2300',
		secondary: '#ffffff',
		full: 'SERBIA',
	},
	{
		abbreviation: 'SRI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/sri.png)'],
		primary: '#EB7400',
		secondary: '#00534E',
		full: 'SRI LANKA',
	},
	{
		abbreviation: 'SSD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/14075.png)'],
		primary: '#da131a',
		secondary: '#1146b0',
		full: 'SOUTH SUDAN',
	},
	{
		abbreviation: 'STEV',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/285.png)'],
		primary: '#ffffff',
		secondary: '#091453',
		full: 'STEVENAGE',
	},
	{
		abbreviation: 'STG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3746.png)'],
		primary: '#000000',
		secondary: '#ffffff',
		full: 'SK STURM GRAZ',
	},
	{
		abbreviation: 'STK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/336.png)'],
		primary: '#f42727',
		secondary: '#007921',
		full: 'STOKE CITY',
	},
	{
		abbreviation: 'STP',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/11808.png)'],
		primary: '#000000',
		secondary: '#C60000',
		full: 'SAO TOME E PRINCIPE',
	},
	{
		abbreviation: 'STRA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/180.png)'],
		primary: '#0000bf',
		secondary: '#ffffff',
		full: 'STRASBOURG',
	},
	{
		abbreviation: 'STU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/134.png)'],
		primary: '#e32219',
		secondary: '#ffed00',
		full: 'VFB STUTTGART',
	},
	{
		abbreviation: 'SUD',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/sud.png)'],
		primary: '#D20300',
		secondary: '#000000',
		full: 'SUDAN',
	},
	{
		abbreviation: 'SUI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/sui.png)'],
		primary: '#ea2300',
		secondary: '#ffffff',
		full: 'SWITZERLAND',
	},
	{
		abbreviation: 'SUN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/366.png)'],
		primary: '#EB172B',
		secondary: '#0000fa',
		full: 'SUNDERLAND',
	},
	{
		abbreviation: 'SUR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/sur.png)'],
		primary: '#377E3F',
		secondary: '#B40A2D',
		full: 'SURINAME',
	},
	{
		abbreviation: 'SUT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3231.png)'],
		primary: '#ffd700',
		secondary: '#ffffff',
		full: 'SUTTON UNITED',
	},
	{
		abbreviation: 'SVK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/svk.png)'],
		primary: '#024595',
		secondary: '#CE1226',
		full: 'SLOVAKIA',
	},
	{
		abbreviation: 'SVN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/svn.png)'],
		primary: '#CB0300',
		secondary: '#0c2fff',
		full: 'SLOVENIA',
	},
	{
		abbreviation: 'SWA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/318.png)'],
		primary: '#ffffff',
		secondary: '#1a1a1a',
		full: 'SWANSEA CITY',
	},
	{
		abbreviation: 'SWE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/swe.png)'],
		primary: '#ffec00',
		secondary: '#0756AE',
		full: 'SWEDEN',
	},
	{
		abbreviation: 'SWI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/341.png)'],
		primary: '#C8142F',
		secondary: '#cdcdcd',
		full: 'SWINDON TOWN',
	},
	{
		abbreviation: 'SWZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/swz.png)'],
		primary: '#000000',
		secondary: '#E70000',
		full: 'ESWATINI',
	},
	{
		abbreviation: 'SYR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/syr.png)'],
		primary: '#E70000',
		secondary: '#000000',
		full: 'SYRIA',
	},
	{
		abbreviation: 'TAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/tan.png)'],
		primary: '#1EB53A',
		secondary: '#FCD116',
		full: 'TANZANIA',
	},
	{
		abbreviation: 'TCA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/tca.png)'],
		primary: '#CF0820',
		secondary: '#002368',
		full: 'TURKS AND CAICOS ISLANDS',
	},
	{
		abbreviation: 'THA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/tha.png)'],
		primary: '#0033a0',
		secondary: '#A51931',
		full: 'THAILAND',
	},
	{
		abbreviation: 'TIJ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/10125.png)'],
		primary: '#EF0107',
		secondary: '#e1e1e1',
		full: 'TIJUANA',
	},
	{
		abbreviation: 'TJK',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/tjk.png)'],
		primary: '#E70000',
		secondary: '#006600',
		full: 'TAJIKISTAN',
	},
	{
		abbreviation: 'TKM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/tkm.png)'],
		primary: '#009f4d',
		secondary: '#FFFFFF',
		full: 'TURKMENISTAN',
	},
	{
		abbreviation: 'TLS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/tmp.png)'],
		primary: '#000000',
		secondary: '#DC241F',
		full: 'TIMOR-LESTE',
	},
	{
		abbreviation: 'TOG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/tog.png)'],
		primary: '#179354',
		secondary: '#C60000',
		full: 'TOGO',
	},
	{
		abbreviation: 'TOL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/223.png)'],
		primary: '#EF0107',
		secondary: '#ffffff',
		full: 'TOLUCA',
	},
	{
		abbreviation: 'TOR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/239.png)'],
		primary: '#8a1e03',
		secondary: '#eeb111',
		full: 'TORINO',
	},
	{
		abbreviation: 'TORO',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/7318.png)'],
		primary: '#ab1e2d',
		secondary: '#3f4743',
		full: 'TORONTO FC',
	},
	{
		abbreviation: 'TOT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/367.png)'],
		primary: '#132257',
		secondary: '#000000',
		full: 'TOTTENHAM HOTSPUR',
	},
	{
		abbreviation: 'TPE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/14561.png)'],
		primary: '#FFFFFF',
		secondary: '#C60000',
		full: 'CHINESE TAIPEI',
	},
	{
		abbreviation: 'TRI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/tri.png)'],
		primary: '#c8102e',
		secondary: '#000000',
		full: 'TRINIDAD AND TOBAGO',
	},
	{
		abbreviation: 'TRN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/345.png)'],
		primary: '#ffffff',
		secondary: '#4c00b3',
		full: 'TRANMERE ROVERS',
	},
	{
		abbreviation: 'TRY',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/170.png)'],
		primary: '#0000bf',
		secondary: '#fafafc',
		full: 'TROYES',
	},
	{
		abbreviation: 'TUN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/tun.png)'],
		primary: '#D20300',
		secondary: '#000000',
		full: 'TUNISIA',
	},
	{
		abbreviation: 'TUR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/tur.png)'],
		primary: '#ffffff',
		secondary: '#E70000',
		full: 'TURKEY',
	},
	{
		abbreviation: 'TWE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/152.png)'],
		primary: '#F31522',
		secondary: '#091453',
		full: 'FC TWENTE',
	},
	{
		abbreviation: 'UAE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/uae.png)'],
		primary: '#E70000',
		secondary: '#00732F',
		full: 'UNITED ARAB EMIRATES',
	},
	{
		abbreviation: 'UANL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/232.png)'],
		primary: '#ffd011',
		secondary: '#0000ff',
		full: 'TIGRES UANL',
	},
	{
		abbreviation: 'UDN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/118.png)'],
		primary: '#000000',
		secondary: '#8b7d37',
		full: 'UDINESE',
	},
	{
		abbreviation: 'UGA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/uga.png)'],
		primary: '#FCDC04',
		secondary: '#D90000',
		full: 'UGANDA',
	},
	{
		abbreviation: 'UKR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ukr.png)'],
		primary: '#ffec00',
		secondary: '#0537e4',
		full: 'UKRAINE',
	},
	{
		abbreviation: 'UNAM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/233.png)'],
		primary: '#ffffff',
		secondary: '#060040',
		full: 'PUMAS UNAM',
	},
	{
		abbreviation: 'URU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/uru.png)'],
		primary: '#7fb5ff',
		secondary: '#ffffff',
		full: 'URUGUAY',
	},
	{
		abbreviation: 'USA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/usa.png)'],
		primary: '#002868',
		secondary: '#C60000',
		full: 'UNITED STATES',
	},
	{
		abbreviation: 'USVI',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/vir.png)'],
		primary: '#FFFFFF',
		secondary: '#0033a0',
		full: 'US VIRGIN ISLANDS',
	},
	{
		abbreviation: 'UTR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/153.png)'],
		primary: '#F31522',
		secondary: '#dbaf2d',
		full: 'FC UTRECHT',
	},
	{
		abbreviation: 'UZB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/uzb.png)'],
		primary: '#0081d6',
		secondary: '#1EB53A',
		full: 'UZBEKISTAN',
	},
	{
		abbreviation: 'VAL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/94.png)'],
		primary: '#0097d7',
		secondary: '#ffdf1c',
		full: 'VALENCIA',
	},
	{
		abbreviation: 'VALE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/348.png)'],
		primary: '#ffffff',
		secondary: '#FECD32',
		full: 'PORT VALE',
	},
	{
		abbreviation: 'VALL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/95.png)'],
		primary: '#921b88',
		secondary: '#f4e423',
		full: 'REAL VALLADOLID',
	},
	{
		abbreviation: 'VAN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/9727.png)'],
		primary: '#04265c',
		secondary: '#94c2e4',
		full: 'VANCOUVER WHITECAPS',
	},
	{
		abbreviation: 'VEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/17530.png)'],
		primary: '#036434',
		secondary: '#F57F1F',
	},
	{
		abbreviation: 'VER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/119.png)'],
		primary: '#005395',
		secondary: '#ffe74a',
		full: 'HELLAS VERONA',
	},
	{
		abbreviation: 'VIE',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/519.png)'],
		primary: '#2b6a36',
		secondary: '#dc1f26',
		full: 'RAPID VIENNA',
	},
	{
		abbreviation: 'VILL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/102.png)'],
		primary: '#005187',
		secondary: '#ffe667',
		full: 'VILLARREAL',
	},
	{
		abbreviation: 'VIN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/vin.png)'],
		primary: '#32ad42',
		secondary: '#0072C6',
		full: 'ST. VINCENT AND THE GRENADINES',
	},
	{
		abbreviation: 'VIT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/154.png)'],
		primary: '#FCEE33',
		secondary: '#0000d4',
		full: 'VITESSE',
	},
	{
		abbreviation: 'VNZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/ven.png)'],
		primary: '#880007',
		secondary: '#FDFF00',
		full: 'VENEZUELA',
	},
	{
		abbreviation: 'VT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/vie.png)'],
		primary: '#E70000',
		secondary: '#FFCD00',
		full: 'VIETNAM',
	},
	{
		abbreviation: 'WAL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/401.png)'],
		primary: '#C8142F',
		secondary: '#091453',
		full: 'WALSALL',
	},
	{
		abbreviation: 'WAT',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/395.png)'],
		primary: '#FFF201',
		secondary: '#ED1D24',
	},
	{
		abbreviation: 'WBA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/383.png)'],
		primary: '#122f67',
		secondary: '#755031',
		full: 'WEST BROMWICH ALBION',
	},
	{
		abbreviation: 'WER',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/137.png)'],
		primary: '#1d9053',
		secondary: '#000000',
		full: 'WERDER BREMEN',
	},
	{
		abbreviation: 'WGA',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/350.png)'],
		primary: '#0000fa',
		secondary: '#E03A3E',
		full: 'WIGAN ATHLETIC',
	},
	{
		abbreviation: 'WHU',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/371.png)'],
		primary: '#7a263a',
		secondary: '#1bb1e7',
		full: 'WEST HAM UNITED',
	},
	{
		abbreviation: 'WIL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/156.png)'],
		primary: '#F80017',
		secondary: '#00ffc0',
		full: 'WILLEM II',
	},
	{
		abbreviation: 'WIM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/3802.png)'],
		primary: '#0000d4',
		secondary: '#ffff00',
		full: 'AFC WIMBLEDON',
	},
	{
		abbreviation: 'WLS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/wal.png)'],
		primary: '#E70000',
		secondary: '#01AA39',
		full: 'WALES',
	},
	{
		abbreviation: 'WOB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/20107.png)'],
		primary: '#0c4011',
		secondary: '#89bcff',
		full: 'VFL WOLFSBURG',
	},
	{
		abbreviation: 'WOL',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/380.png)'],
		primary: '#fdb913',
		secondary: '#231f20',
		full: 'WOLVERHAMPTON WANDERERS',
	},
	{
		abbreviation: 'WOLF',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/138.png)'],
		primary: '#65b32e',
		secondary: '#000000',
		full: 'VFB STUTTGART',
	},
	{
		abbreviation: 'WSH',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/15365.png)'],
		primary: '#C60000',
		secondary: '#fafafc',
		full: 'WASHINGTON SPIRIT',
	},
	{
		abbreviation: 'WYC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/344.png)'],
		primary: '#4cb8e5',
		secondary: '#ffff00',
		full: 'WYCOMBE WANDERERS',
	},
	{
		abbreviation: 'YB',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2722.png)'],
		primary: '#F9CA2A',
		secondary: '#000000',
		full: 'YOUNG BOYS',
	},
	{
		abbreviation: 'YEM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/yem.png)'],
		primary: '#CE1126',
		secondary: '#000000',
		full: 'YEMEN',
	},
	{
		abbreviation: 'ZAG',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/597.png)'],
		primary: '#0000ff',
		secondary: '#ffff00',
		full: 'DINAMO ZAGREB',
	},
	{
		abbreviation: 'ZAM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/countries/500/zam.png)'],
		primary: '#198A00',
		secondary: '#DE2010',
		full: 'ZAMBIA',
	},
	{
		abbreviation: 'ZEN',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/2533.png)'],
		primary: '#0097db',
		secondary: '#000000',
		full: 'ZENIT ST PETERSBURG',
	},
	{
		abbreviation: 'ZIM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/4214.png)'],
		primary: '#FFFF00',
		secondary: '#1D8348',
		full: 'ZIMBABWE',
	},
	{
		abbreviation: 'LEC',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/113.png)'],
		primary: '#1D204F',
		secondary: '#CEA468',
		full: 'LECCE',
	},
	{
		abbreviation: 'MONZ',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/4007.png)'],
		primary: '#E4022E',
		secondary: '#FFFFFF',
		full: 'MONZA',
	},
	{
		abbreviation: 'FROS',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/4057.png)'],
		primary: '#2E56A6',
		secondary: '#F4DA36',
		full: 'FROSINONE',
	},
	{
		abbreviation: 'GIR',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/9812.png)'],
		primary: '#CF1F39',
		secondary: '#FCB413',
		full: 'GIRONA',
	},
	{
		abbreviation: 'PALM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/98.png)'],
		primary: '#009EDD',
		secondary: '#FFE600',
		full: 'LAS PALMAS',
	},
	{
		abbreviation: 'ALM',
		logos: ['url(https://a.espncdn.com/i/teamlogos/soccer/500/6832.png)'],
		primary: '#E10F17',
		secondary: '#FEBB13',
		full: 'ALMERIA',
	},
];

export const f1Teams = [
	{
		abbreviation: 'RED',
		full: 'RED BULL',
		full2: 'RED BULL RACING',
		primary: '#15185F',
		secondary: '#FFC801',
		logos: ['url(/logos/f1/redbull.png)'],
		duplicate: 'url(/logos/f1/redbull-car.png)',
	},
	{
		abbreviation: 'FER',
		full: 'FERRARI',
		primary: '#FF0100',
		secondary: '#000000',
		logos: ['url(/logos/f1/ferrari.png)'],
		duplicate: 'url(/logos/f1/ferrari-car.png)',
	},
	{
		abbreviation: 'MER',
		full: 'MERCEDES',
		primary: '#00A19C',
		secondary: '#000000',
		logos: ['url(/logos/f1/mercedes.png)'],
		duplicate: 'url(/logos/f1/mercedes-car.png)',
	},
	{
		abbreviation: 'ALPN',
		full: 'ALPINE',
		primary: '#0E1C2C',
		secondary: '#DC7DBF',
		logos: ['url(/logos/f1/alpine.png)'],
		duplicate: 'url(/logos/f1/alpine-car.png)',
	},
	{
		abbreviation: 'MCL',
		full: 'MCLAREN',
		primary: '#FF8001',
		secondary: '#48AEE0',
		logos: ['url(/logos/f1/mclaren.png)'],
		duplicate: 'url(/logos/f1/mclaren-car.png)',
	},
	{
		abbreviation: 'ALF',
		full: 'ALFA ROMEO',
		primary: '#990001',
		secondary: '#303030',
		logos: ['url(/logos/f1/alfa.png)'],
		duplicate: 'url(/logos/f1/alfa-car.png)',
	},
	{
		abbreviation: 'HAAS',
		full: 'HAAS F1 TEAM',
		primary: '#000000',
		secondary: '#E2032E',
		logos: ['url(/logos/f1/haas.png)'],
		duplicate: 'url(/logos/f1/haas-car.png)',
	},
	{
		abbreviation: 'ALPH',
		full: 'ALPHATAURI',
		primary: '#022947',
		secondary: '#2F3338',
		logos: ['url(/logos/f1/alphatauri.png)'],
		duplicate: 'url(/logos/f1/alpha-car.png)',
	},
	{
		abbreviation: 'AST',
		full: 'ASTON MARTIN',
		primary: '#02574F',
		secondary: '#242424',
		logos: ['url(/logos/f1/aston.png)'],
		duplicate: 'url(/logos/f1/aston-car.png)',
	},
	{
		abbreviation: 'WIL',
		full: 'WILLIAMS',
		primary: '#041E42',
		secondary: '#489BF7',
		logos: ['url(/logos/f1/williams.png)'],
		duplicate: 'url(/logos/f1/williams-car.png)',
	},
];

export const wnbaTeams = [
	{
		abbreviation: 'ATL',
		full: 'ATLANTA',
		full2: 'DREAM',
		primary: '#d02030',
		secondary: '#383a37',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/atl.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/atl.png)'],
	},
	{
		abbreviation: 'CHI',
		full: 'CHICAGO',
		full2: 'SKY',
		primary: '#4f91cd',
		secondary: '#f1f2f3',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/chi.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/chi.png)'],
	},
	{
		abbreviation: 'CONN',
		full: 'CONNECTICUT',
		full2: 'SUN',
		primary: '#de6b36',
		secondary: '#002d62',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/conn.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/conn.png)'],
	},
	{
		abbreviation: 'DAL',
		full: 'DALLAS',
		full2: 'WINGS',
		primary: '#11213e',
		secondary: '#c5d646',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/dal.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/dal.png)'],
	},
	{
		abbreviation: 'IND',
		full: 'INDIANA',
		full2: 'FEVER',
		primary: '#e2382f',
		secondary: '#08265b',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/ind.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/ind.png)'],
	},
	{
		abbreviation: 'LV',
		full: 'LAS VEGAS',
		full2: 'ACES',
		primary: '#000000',
		secondary: '#c7cfd4',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/lv.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/lv.png)'],
	},
	{
		abbreviation: 'LA',
		full: 'LOS ANGELES',
		full2: 'SPARKS',
		primary: '#532481',
		secondary: '#49ac9c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/la.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/la.png)'],
	},
	{
		abbreviation: 'MIN',
		full: 'MINNESOTA',
		full2: 'LYNX',
		primary: '#005084',
		secondary: '#47a757',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/min.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/min.png)'],
	},
	{
		abbreviation: 'NY',
		full: 'NEW YORK',
		full2: 'LIBERTY',
		primary: '#0d79b9',
		secondary: '#89c9bf',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/ny.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/ny.png)'],
	},
	{
		abbreviation: 'PHX',
		full: 'PHOENIX',
		full2: 'MERCURY',
		primary: '#3a267d',
		secondary: '#e56020',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/phx.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/phx.png)'],
	},
	{
		abbreviation: 'SEA',
		full: 'SEATTLE',
		full2: 'STORM',
		primary: '#05452a',
		secondary: '#f7c43c',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/sea.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/sea.png)'],
	},
	{
		abbreviation: 'WSH',
		full: 'WASHINGTON',
		full2: 'MYSTICS',
		primary: '#e03a3e',
		secondary: '#09295a',
		logos: ['url(https://a.espncdn.com/i/teamlogos/wnba/500/wsh.png)', 'url(https://a.espncdn.com/i/teamlogos/wnba/500-dark/wsh.png)'],
	},
];

export const aflTeams = [
	{
		primary: '#cd1b2d',
		secondary: '#000000',
		abbreviation: 'ESS',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/ess.png)'],
		full: 'ESSENDON',
		full2: 'BOMBERS',
	},
	{
		primary: '#002b5c',
		secondary: '#e21937',
		abbreviation: 'ADEL',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/adel.png)'],
		full: 'ADELAIDE',
		full2: 'CROWS',
	},
	{
		primary: '#4d2004',
		secondary: '#fbbf15',
		abbreviation: 'HAW',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/haw.png)'],
		full: 'HAWTHORN',
		full2: 'HAWKS',
	},
	{
		primary: '#ee3728',
		secondary: '#ffdf16',
		abbreviation: 'GCFC',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/gcfc.png)'],
		full: 'GOLD COAST',
		full2: 'SUNS',
	},
	{
		primary: '#7c0040',
		secondary: '#0055a3',
		abbreviation: 'BL',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/bl.png)'],
		full: 'BRISBANE',
		full2: 'LIONS',
	},
	{
		primary: '#031a29',
		secondary: '#ffffff',
		abbreviation: 'CARL',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/carl.png)'],
		full: 'CARLTON',
		full2: 'BLUES',
	},
	{
		primary: '#1b2e71',
		secondary: '#be0027',
		abbreviation: 'WB',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/wb.png)'],
		full: 'WESTERN',
		full2: 'BULLDOGS',
	},
	{
		primary: '#f47920',
		secondary: '#ffffff',
		abbreviation: 'GWS',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/gws.png)'],
		full: 'GREATER WESTERN SYDNEY',
		full2: 'GIANTS',
	},
	{
		primary: '#aa9767',
		secondary: '#000000',
		abbreviation: 'COLL',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/coll.png)'],
		full: 'COLLINGWOOD',
		full2: 'MAGPIES',
	},
	{
		primary: '#1a3b8e',
		secondary: '#c4d1e5',
		abbreviation: 'NMFC',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/nmfc.png)'],
		full: 'NORTH MELBOURNE',
		full2: 'KANGAROOS',
	},
	{
		primary: '#002b5c',
		secondary: '#ffffff',
		abbreviation: 'GEEL',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/geel.png)'],
		full: 'GEELONG',
		full2: 'CATS',
	},
	{
		primary: '#ed171f',
		secondary: '#000000',
		abbreviation: 'SYD',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/syd.png)'],
		full: 'SYDNEY',
		full2: 'SWANS',
	},
	{
		primary: '#2a0d54',
		secondary: '#a2acb4',
		abbreviation: 'FRE',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/fre.png)'],
		full: 'FREMANTLE',
		full2: 'DOCKERS',
	},
	{
		primary: '#ed1b2f',
		secondary: '#000000',
		abbreviation: 'STK',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/stk.png)'],
		full: 'ST KILDA',
		full2: 'SAINTS',
	},
	{
		primary: '#008aab',
		secondary: '#000000',
		abbreviation: 'PORT',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/port.png)'],
		full: 'PORT ADELAIDE',
		full2: 'POWER',
	},
	{
		primary: '#0f1131',
		secondary: '#cc2031',
		abbreviation: 'MELB',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/melb.png)'],
		full: 'MELBOURNE',
		full2: 'DEMONS',
	},
	{
		primary: '#ffd200',
		secondary: '#000000',
		abbreviation: 'RICH',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/rich.png)'],
		full: 'RICHMOND',
		full2: 'TIGERS',
	},
	{
		primary: '#003087',
		secondary: '#f2a900',
		abbreviation: 'WCE',
		logos: ['url(https://a.espncdn.com/combiner/i?img=/i/teamlogos/afl/500/wce.png)'],
		full: 'WEST COAST',
		full2: 'EAGLES',
	},
];
