import Draggable from 'react-draggable';
import useGlobalStore from "../../../stores/global.store";
import usePostStore from "../../../stores/posts/base-post.store";
const NUMERIC_REGEXP = /[-]{0,1}[\d]*[.]{0,1}[\d]+/g;

export default function FloatingTexts(props) {
	const { floatingTexts, setFloatingTexts } = usePostStore();
	const { individuallyEdit, sizeMultiplier, graphicMap } = useGlobalStore();

	if (!graphicMap) return;
	if (!floatingTexts || floatingTexts.length === 0) return;
	return floatingTexts.map((floating, i) => {
		return (
			<div
				className='floatingText drag-container'
				id={`floatingText${i}`}
				key={i}
				style={{ zIndex: 200 + i }}>
				<Draggable
					position={{ x: floating.xval, y: floating.yval }}
					disabled={!individuallyEdit}
					onStop={(e) => {
						const transform = document.getElementById('floatingTextSpan' + i).style.transform.split(',');
						let floatingTextsCopy = [...floatingTexts];
						floatingTextsCopy[i].xval = Number(transform[0].match(NUMERIC_REGEXP)[0]);
						floatingTextsCopy[i].yval = Number(transform[1].match(NUMERIC_REGEXP)[0]);
						setFloatingTexts(floatingTextsCopy);
						props.setDraggables();
					}}
					handle='.float-handle'>
					<span
						className='floatingTextSpan'
						id={'floatingTextSpan' + i}>
						<span className='floatingTextInnerSpan'>
							{individuallyEdit && (
								<span className='float-handle'>
									<i className='fa-solid fa-grip-vertical'></i>
								</span>
							)}
							<input
								id={`floatinput${i}`}
								type='text'
								style={{
									width: String(floating.text).length + 2 + 'ch',
									fontFamily: floating.font,
									fontSize: floating.fontSize * sizeMultiplier,
									color: floating.fontColor,
									letterSpacing: floating.letterSpacing * sizeMultiplier,
									textShadow: `calc(${floating.textShadow} * var(--sizemultiplier) * 1px) calc(${floating.textShadow} * var(--sizemultiplier) * 1px) ${floating.shadowColor}`,
								}}
								placeholder='Add text'
								value={floating.text}
								className={`blend-in ${individuallyEdit ? 'moveable' : ''}`}
								onFocus={() => props.toggleFloatEditor(i)}
								onChange={(e) => {
									let floatingTextsCopy = [...floatingTexts];
									floatingTextsCopy[i].text = e.target.value;
									setFloatingTexts(floatingTextsCopy);
								}}></input>
						</span>
					</span>
				</Draggable>
			</div>
		);
	})
}
