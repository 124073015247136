export const tiktokSettingsLarge = {
	headerSize: 16,
	nameSize: 12,
	statSize: 11,
	logoOrder: -2,
	logoOpacity: 100,
	logoSize: 39,
	headerMargin: 10,
	headerSpacing: 0,
	nameMargin: 3,
	headerFont: 'roboto bold',
	nameFont: 'roboto bold',
	statFont: 'roboto bold',
	headerColor: '#000000',
	headerFontColor: '#ffffff',
	watermark: '',
	watermarkFont: 'exo',
	watermarkSize: 28,
	watermarkWidth: 82,
	statMargin: 0,
	watermarkColor: '#ffffff',
	nameShadow: 1,
	borderColor: '#ffffff',
	nameSpacing: -2,
	statSpacing: -2,
	statShadow: 1,
	type: 'post',
	logoPosition: 292,
	overlap: false,
	statMargin2: 0,
	lineHeight: 0.9,
	nameBackground: '#000000',
	statBackground: '#000000',
	nameColor: '#ffffff',
	statColor: '#ffffff',
	imageHorizontalNews: 0,
	imageVerticalNews: 0,
	imageZoomNews: 100,
	headerSizeNews: 20,
	headerFontNews: 'roboto condensed',
	headerMarginNews: 0,
	headerSpacingNews: 0,
	headerColorNews: '#ffffff',
	headerFontColorNews: '#000000',
	logoOpacityNews: 100,
	nameColorNews: '#ffffff',
	logoSizeNews: 500,
	orientationNews: 'leftright',
	borderRadius: 5,
	scoreMargin: 5,
	paneSplit: [50, 50],
	paneSplitNews: [50, 50],
	duplicate: true,
	logoShadow: 2,
	nameMargin2: 10,
	postWidth: 1080,
	postHeight: 1920,
	postWidthNews: 800,
	postHeightNews: 800,
	tierFont: 'roboto condensed',
	tierColor: '#ffffff',
	tierSize: 75,
	duplicateLogoSize: 540,
	duplicateLogoPosition: 0,
	duplicateLogoOpacity: 8,
	lighterLogos: false,
	backgroundGradientStops: [
		{
			color: '#013369',
			percentage: 0,
		},
		{
			color: '#D50A0A',
			percentage: 100,
		},
	],
	gradientDirection: '270deg',
	gradientDirectionNews: '0deg',
	gradientType: 'linear-gradient',
	gradientStops: [
		{
			color: 'secondary',
			percentage: 0,
		},
		{
			color: 'mid',
			percentage: 32,
		},
		{
			color: 'primary',
			percentage: 100,
		},
	],
	gradientTypeNews: 'linear-gradient',
	gradientStopsNews: [
		{
			color: 'secondary',
			percentage: 0,
		},
		{
			color: 'mid',
			percentage: 50,
		},
		{
			color: 'primary',
			percentage: 100,
		},
	],
};
