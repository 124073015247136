import React, { useEffect } from 'react';
import useSettingsStore from '../stores/settings.store';
import usePostStore from '../stores/posts/base-post.store';
import useWithImageStore from '../stores/posts/with-image.store';
import useChartStore from '../stores/posts/chart.store';
import useComparisonStore from '../stores/posts/comparison.store';
import useBracketStore from '../stores/posts/bracket.store';
import useTierStore from '../stores/posts/tier.store';
import useGlobalStore from '../stores/global.store';
import { Settings } from '../data/settings/settings';
import { posts } from '../data/prefill/homedemo';
import { Post } from '../data/interfaces/post';
import Select, { SingleValue } from 'react-select';
import usePreferencesStore from '../stores/preferences.store';
import { settingsStylesDark } from '../data/styles/settingsStylesDark';
import { settingsStyles } from '../data/styles/settingsStyles';
import { sportOptions } from '../data/styles/sportOptions';
import useSidebarStore from '../stores/sidebar.store';
import { Option } from '../stores/options.store';

interface TemplatesProps {
	user: any;
	changeType2: (type: string) => void;
	resetImage: (settings: Settings) => void;
	sportChange: (sport: string, shouldReset?: boolean) => void;
	addToTeamOverrides: (arr: any[]) => void;
	setTeamOptions: (sport: string) => void;
	moveDraggables: () => void;
	resetDraggables: () => void;
}

const Templates: React.FC<TemplatesProps> = (props) => {
	const { settings, replaceSettings } = useSettingsStore();
	const { setRound } = useBracketStore();
	const { setTiers } = useTierStore();
	const { setNewsTitle, setNewsSubtitle, setNewsImage, setNewsTeam } = useWithImageStore();
	const { setLeftName, setRightName, setLeftTeam, setRightTeam, setLeftImage, setRightImage, setLeftStats, setRightStats, setStatLabels } = useComparisonStore();
	const {
		setTitle,
		setSubtitle,
		setName,
		setTeam,
		setStat,
		setPoints,
		setImage,
		setRanks,
		setFloatingTexts,
		setNameOverrides,
		setStatOverrides,
		setNameHighlights,
		setStatHighlights,
		setDescription,
		setTeamSplits
	} = usePostStore();
	const { setxLabel, setyLabel } = useChartStore();
	const { setForceGraphicUpdate, setExpanded, setDraggables } = useGlobalStore();
    const { darkMode } = usePreferencesStore();
	const {
		templates,
		setTemplates,
		imagesLoaded,
		setImagesLoaded,
		sportFilter,
		setSportFilter,
		currentTemplateId,
		setCurrentTemplateId
	} = useSidebarStore();

	useEffect(() => {
        setTemplates(posts);
        if (props.user.plan !== 'all') {
            setSportFilter(sportOptions.find((opt) => opt.value === props.user.sport)!);
        }
		const postTypeGrid = document.querySelector('.postTypeGrid');
		if (!postTypeGrid) return;

		const images = Array.from(postTypeGrid.querySelectorAll('img'));
		const imagePromises = images.map((image) => {
			return new Promise<void>((resolve) => {
				if (image.complete) {
					resolve();
				} else {
					image.onload = () => resolve();
					image.onerror = () => resolve();
				}
			});
		});

		void Promise.all(imagePromises).then(() => {
			setImagesLoaded(true);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const formatsLoading = document.getElementById('formatsLoading');
		const formatsLoaded = document.getElementById('formatsLoaded');
		if (imagesLoaded && formatsLoading && formatsLoaded) {
			formatsLoading.classList.add('hide');
			formatsLoaded.classList.remove('hide');
		} else if (formatsLoading && formatsLoaded) {
			formatsLoading.classList.remove('hide');
			formatsLoaded.classList.add('hide');
		}
	}, [imagesLoaded]);

	const loadTemplate = async (id: string): Promise<void> => {
		setCurrentTemplateId(id);
		const post = templates.find((post: Post) => post._id === id);
		if (!post) return;
		if (post.settings.type !== settings.type) {
			await props.changeType2(post.settings.type!);
		}
		if (post.settings.type === 'postimage' || post.settings.type === 'gridimage') {
			if (!post.settings.paneSplit) {
				post.settings.paneSplit = post.settings.paneSplitNews;
			}
			if (!post.settings.orientation) {
				post.settings.orientation = post.settings.orientationNews;
			}
		}
		if (post.settings.type === 'news' || post.settings.type === 'highlightimage' || post.settings.type === 'lineupimage') {
			props.resetImage(post.settings as Settings);
			replaceSettings(post.settings);
			setNewsTitle(post.newstitle ?? '');
			setNewsSubtitle(post.newssubtitle ?? '');
			setNewsImage(post.newsimage ?? '');
			setNewsTeam(post.newsteam ?? '');
			props.sportChange(post.sport);
			setTeamSplits(post.teamsplits);
			setFloatingTexts(post.floatingTexts ? post.floatingTexts : []);
			props.addToTeamOverrides(post.teamoverrides ?? []);
			if (post.settings.type === 'lineupimage') {
				setName(post.name ?? []);
				setStat(post.stat ?? []);
			}
			props.setTeamOptions(post.sport);
		} else if (post.settings.type === 'comparison') {
			props.resetImage(post.settings as Settings);
			replaceSettings(post.settings);
			setTitle(post.title);
			setSubtitle(post.subtitle ?? '');
			setLeftName(post.leftName ?? '');
			setRightName(post.rightName ?? '');
			setLeftTeam(post.leftTeam ?? '');
			setRightTeam(post.rightTeam ?? '');
			setLeftImage(post.leftImage ?? '');
			setRightImage(post.rightImage ?? '');
			setLeftStats(post.leftStats ?? []);
			setRightStats(post.rightStats ?? []);
			setStatLabels(post.statLabels ?? []);
			props.sportChange(post.sport);
			setTeamSplits(post.teamsplits);
			setFloatingTexts(post.floatingTexts);
			props.addToTeamOverrides(post.teamoverrides ?? []);
			props.setTeamOptions(post.sport);
		} else {
			replaceSettings(post.settings);
			setTitle(post.title);
			setSubtitle(post.subtitle ?? '');
			setTeam(post.team ?? []);
			setName(post.name ?? []);
			setStat(post.stat ?? []);
			setPoints(post.points ? post.points : []);
			setImage(post.image ?? []);
			props.sportChange(post.sport);
			setTeamSplits(post.teamsplits ? post.teamsplits : []);
			setRanks(post.ranks ? post.ranks : []);
			setFloatingTexts(post.floatingTexts ? post.floatingTexts : []);
			setxLabel(post.xLabel ? post.xLabel : '');
			setyLabel(post.yLabel ? post.yLabel : '');
			setNameOverrides(post.nameOverrides ? post.nameOverrides : []);
			setStatOverrides(post.statOverrides ? post.statOverrides : []);
			setNameHighlights(post.nameHighlights ? post.nameHighlights : []);
			setStatHighlights(post.statHighlights ? post.statHighlights : []);
			setDescription(post.description ? post.description : '');
			props.addToTeamOverrides(post.teamoverrides ?? []);
			props.setTeamOptions(post.sport);
			if (post.settings.type === 'bracket') {
                const emptyBracketRound = {
                    team: [],
                    name: [],
                    stat: [],
                    image: []
                };
				setRound('round32', post.round32 ?? emptyBracketRound);
				setRound('round16', post.round16 ?? emptyBracketRound);
				setRound('round12', post.round12 ?? emptyBracketRound);
				setRound('round8', post.round8 ?? emptyBracketRound);
				setRound('round4', post.round4 ?? emptyBracketRound);
				setRound('round2', post.round2 ?? emptyBracketRound);
				setRound('round1', post.round1 ?? emptyBracketRound);
			} else if (post.settings.type === 'tier') {
				if (post.tiers) {
					setTiers(post.tiers);
				} else {
					setTiers({
						0: 1,
						1: 2,
						2: 3,
						3: 4,
						4: 5,
						5: 6,
						6: 7,
						7: 8,
						8: 9,
						9: 10
					});
				}
			}
			if (post.settings.type!.includes('image')) {
				props.resetImage(post.settings as Settings);
				setNewsImage(post.newsimage ?? '');
			}
		}
		if (post.settings.type!.includes('post') || post.settings.type!.includes('grid') || post.settings.type === 'bracket' || post.settings.type === 'scores') {
			if (post.team?.includes('#')) {
				const filteredTeam = post.team.filter((value) => value !== '#');
				setTeam(filteredTeam);
			}
		}
		if (post.draggables?.length) {
			setDraggables(post.draggables);
			props.moveDraggables();
		} else {
			props.resetDraggables();
		}
		window.dispatchEvent(new Event('resize'));
		// force update to get text fit to work properly
		setForceGraphicUpdate();
		if (document.documentElement.clientWidth <= 950) {
			setExpanded('');
		}
	};

    const filteredTemplates = templates.filter((template) => {
		if (sportFilter) {
            if (template.sport !== sportFilter.value) {
				return false;
			}
		}
		return true;
	});
	return (
		<div className='template-library'>
			<span
				className='post-image-loading'
				id='formatsLoading'>
				<i className='fa fa-spinner fa-spin'></i>
			</span>
            {props.user.plan === 'all' && (<Select
                id='typeDropdown'
                instanceId='types'
                value={sportFilter}
                onChange={(selectedOption: SingleValue<Option>) => setSportFilter(selectedOption)}
                options={sportOptions}
                styles={darkMode ? settingsStylesDark : settingsStyles}
                placeholder='Sport'
            />)}
			<div
				className='postTypeGrid hide'
				id='formatsLoaded'>
                {filteredTemplates.map((template, i) => {
                    return (
                        <span
                            key={i}
							className={currentTemplateId === template._id ? 'selected' : ''}
                            onClick={() => loadTemplate(template._id)}>
                            <img
                                src={template.thumbnail ?? '/template1.jpeg'}
                                alt='template'
                                draggable='false'
                            />
                    </span>
                    );
                })}
			</div>
		</div>
	);
};

export default Templates;
