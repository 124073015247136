import {useEffect, useRef, useState} from 'react';
import Select from 'react-select';
import {Popover} from 'react-tiny-popover';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import {fontOptions} from '../data/styles/fontOptions';
import {settingsStyles} from '../data/styles/settingsStyles';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {settingsStylesDark} from '../data/styles/settingsStylesDark';
import { ChromePicker } from './ChromePicker';
import useSettingsStore from '../stores/settings.store';
import usePreferencesStore from '../stores/preferences.store';
import useGlobalStore from '../stores/global.store';
import usePostStore from '../stores/posts/base-post.store';

export default function InlineEditor(props) {
	const didMount = useRef(false);
	const [popovers, setPopovers] = useState([]);
	const [font, setFont] = useState({value: 'roboto condensed', label: 'Roboto Condensed', className: 'robotocondensed'});
	const [fontSize, setFontSize] = useState(1);
	const [fontColor, setFontColor] = useState('#ffffff')
	const [letterSpacing, setLetterSpacing] = useState(0);
	const [changed, setChanged] = useState(false);
	const { settings } = useSettingsStore();
	const { darkMode } = usePreferencesStore();
	const { inlineEditor } = useGlobalStore();
	const { nameOverrides, statOverrides, setNameOverrides, setStatOverrides } = usePostStore();
	
	// componentDidMount
	useEffect(() => {
		let existingOverride;
		if (inlineEditor.type === 'name') {
			existingOverride = nameOverrides.find((obj) => obj.id === inlineEditor.id);
			if (!existingOverride) {
				const usedFont = fontOptions.find((opt) => opt.value === settings.nameFont);
				setFont(usedFont);
				setFontColor(settings.nameColor);
				setLetterSpacing(settings.nameSpacing);
			}
		} else {
			existingOverride = statOverrides.find((obj) => obj.id === inlineEditor.id);
			if (!existingOverride) {
				const usedFont = fontOptions.find((opt) => opt.value === settings.statFont);
				setFont(usedFont);
				setFontColor(settings.statColor);
				setLetterSpacing(settings.statSpacing);
			}
		}
		if (existingOverride) {
			setFont(existingOverride.font);
			setFontSize(existingOverride.fontSize);
			setFontColor(existingOverride.fontColor);
			setLetterSpacing(existingOverride.letterSpacing);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// state updated
	useEffect(() => {
		if (!didMount.current) {
			didMount.current = true;
			return; // Skip initial effect
		}
		// dont save if on inital state change
		if (!changed) {
			return;
		}
		saveOverride();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [font, fontSize, fontColor, letterSpacing])

	const saveOverride = () => {
		let existingOverride, newOverride;
		if (inlineEditor.type === 'name') {
			let nameOverridesCopy = [...nameOverrides];
			existingOverride = nameOverridesCopy.find((obj) => obj.id === inlineEditor.id);
			if (existingOverride) {
				Object.assign(existingOverride, {
					font,
					fontSize,
					fontColor,
					letterSpacing
				});
				setNameOverrides(nameOverridesCopy);
			} else {
				newOverride = {
					id: inlineEditor.id,
					font,
					fontSize,
					fontColor,
					letterSpacing
				}
				setNameOverrides([...nameOverrides, newOverride]);
			}
		} else {
			let statOverridesCopy = [...statOverrides];
			existingOverride = statOverridesCopy.find((obj) => obj.id === inlineEditor.id);
			if (existingOverride) {
				Object.assign(existingOverride, {
					font,
					fontSize,
					fontColor,
					letterSpacing
				});
				setStatOverrides(statOverridesCopy);
			} else {
				newOverride = {
					id: inlineEditor.id,
					font,
					fontSize,
					fontColor,
					letterSpacing
				}
				setStatOverrides([...statOverrides, newOverride]);
			}
		}
	}

	const togglePopover = (e, popover) => {
		let name = e ? e.target.getAttribute('data-popover') : popover;
		if (!popovers.includes(name)) {
			let popoversCopy = [...popovers];
			popoversCopy.push(name);
			setPopovers(popoversCopy);
		} else {
			let popoversCopy = [...popovers];
			const index = popoversCopy.indexOf(name);
			if (index >= 0) {
				popoversCopy.splice(index, 1);
				setPopovers(popoversCopy);
			}
		}
	};

	const shouldShowTrash = () => {
		if (inlineEditor.type === 'name') {
			return nameOverrides.some((o) => o.id === inlineEditor.id)
		} else {
			return statOverrides.some((o) => o.id === inlineEditor.id)
		}
	}

	const deleteOverride = () => {
		if (inlineEditor.type === 'name') {
			let nameOverridesCopy = [...nameOverrides];
			const nameIndex = nameOverridesCopy.findIndex((n) => n.id === inlineEditor.id);
			nameOverridesCopy.splice(nameIndex, 1);
			setNameOverrides(nameOverridesCopy);
			inlineEditor.element.style.fontFamily = 'inherit';
		} else {
			let statOverridesCopy = [...statOverrides];
			const statIndex = statOverridesCopy.findIndex((n) => n.id === inlineEditor.id);
			statOverridesCopy.splice(statIndex, 1);
			setStatOverrides(statOverridesCopy);
			inlineEditor.element.style.fontFamily = 'inherit';
		}
	};

	return (
		<div className='floating-rich-editor'>
			<span className='editor-dropdown'>
				<Select
					value={font}
					onChange={(selected) => {
						inlineEditor.element.style.fontFamily = selected.value;
						setChanged(true);
						setFont(selected);
					}}
					options={fontOptions}
					styles={darkMode ? settingsStylesDark : settingsStyles}
				/>
			</span>
			<Tippy content={<span>Font Size</span>}>
			<span
				className='editor-button'>
				<Popover
					isOpen={popovers.includes('popover3')}
					reposition={true}
					containerStyle={{'z-index': '1000'}}
					onClickOutside={() => togglePopover(null, 'popover3')}
					positions={['bottom', 'top', 'right', 'left']}
					content={
						<div className='popover-content'>
							<span>
								<h5>Font Size</h5>
								<Slider
									railStyle={{backgroundColor: 'lightgray'}}
									trackStyle={{backgroundColor: '#00a199'}}
									handleStyle={{borderColor: '#00a199'}}
									value={fontSize * 100}
									onChange={(value) => {
										setChanged(true);
										setFontSize(value / 100);
									}}
									min={0}
									max={200}
								/>
							</span>
						</div>
					}>
					<span
						className='spacing-editor'
						data-popover='popover3'
						onClick={togglePopover}>
						Aa
					</span>
				</Popover>
			</span>
			</Tippy>
			<Tippy content={<span>Text Color</span>}>
			<span
				className='editor-button'>
				<Popover
					isOpen={popovers.includes('popover2')}
					reposition={true}
					containerStyle={{'z-index': '1000'}}
					onClickOutside={() => togglePopover(null, 'popover2')}
					positions={['bottom', 'top', 'right', 'left']}
					content={
						<div className='popover-content color-picker'>
							<ChromePicker
								color={fontColor}
								onChange={(color) => {
									setChanged(true);
									setFontColor(color);
								}}
							/>
						</div>
					}>
					<span
						className='colorbox-editor'
						data-popover='popover2'
						onClick={togglePopover}>
						<span className='colorbox-letter'>A</span>
						<span className='rainbow-bar'></span>
					</span>
				</Popover>
			</span>
			</Tippy>
			<Tippy content={<span>Text Spacing</span>}>
			<span
				className='editor-button'>
				<Popover
					isOpen={popovers.includes('popover1')}
					reposition={true}
					containerStyle={{'z-index': '1000'}}
					onClickOutside={() => togglePopover(null, 'popover1')}
					positions={['bottom', 'top', 'right', 'left']}
					content={
						<div className='popover-content'>
							<span>
								<h5>Letter Spacing</h5>
								<Slider
									railStyle={{backgroundColor: 'lightgray'}}
									trackStyle={{backgroundColor: '#00a199'}}
									handleStyle={{borderColor: '#00a199'}}
									value={letterSpacing}
									onChange={(value) => {
										setChanged(true);
										setLetterSpacing(value);
									}}
									min={-5}
									max={5}
								/>
							</span>
						</div>
					}>
					<span
						className='spacing-editor'
						data-popover='popover1'
						onClick={togglePopover}>
						<i className='fas fa-arrow-down-up-across-line no-click'></i>
					</span>
				</Popover>
			</span>
			</Tippy>
			{shouldShowTrash() && (
				<Tippy content={<span>Delete Modifications</span>}>
					<span
						className='editor-delete editor-button'
						onClick={deleteOverride}>
						<i className='fa fa-trash'></i>
					</span>
				</Tippy>
			)}
		</div>
	);
}
