const validFonts = [
    'adidas',
    'american captain',
    'anton',
    'aurach',
    'beantown',
    'bebas neue',
    'berlina',
    'carbon',
    'edo',
    'enfatica',
    'exo',
    'graduate',
    'hemi',
    'jersey',
    'league gothic',
    'league spartan',
    'lemon milk light',
    'lemon milk reg',
    'metropolis',
    'montserrat',
    'new athletic',
    'octin',
    'open sans',
    'ostrich sans',
    'playball',
    'pop warner',
    'roboto bold',
    'roboto condensed',
    'staubach',
    'stadium',
    'the bold one',
    'true lies',
    'uberlin',
    'ubuntu',
    'uniform reg',
    'uniform bold',
    'uni sans',
    'varsity'
];

export const settingsStyles = {
	control: (styles) => ({
		...styles,
		height: 27,
		minHeight: 27,
	}),
	menu: (styles) => ({
		...styles,
		zIndex: 1000
	}),
	valueContainer: (styles) => ({
		...styles,
		height: '27px',
		fontSize: '11px',
		padding: '0 6px',
		fontWeight: '700',
		marginTop: '-1px',
		overflow: 'auto',
	}),
	container: (styles) => ({
		...styles,
		width: '100%',
		marginBottom: '10px'
	}),
	singleValue: (styles) => ({
		...styles,
		transform: 'translateY(0%)'
	}),
	input: (styles) => ({
		...styles,
		margin: '0px',
	}),
	indicatorSeparator: () => ({
		display: 'none',
	}),
	indicatorsContainer: (styles) => ({
		...styles,
		height: '27px',
		marginTop: '-1px',
		'> div': {
			padding: '0px',
		},
	}),
	option: (styles, state) => ({
		...styles,
		fontFamily: validFonts.includes(state.data.value) ? state.data.value : 'roboto condensed',
		height: '27px',
		padding: '4px 16px',
		fontSize: '14px',
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	}),
	multiValueLabel: (styles) => ({
		...styles,
		padding: '2px',
	}),
	multiValueRemove: (styles) => ({
		...styles,
		padding: '2px',
	}),
};
