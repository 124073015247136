import useSettingsStore from '../../stores/settings.store';
import usePostStore from '../../stores/posts/base-post.store';
import useGlobalStore from '../../stores/global.store';
import useUserStore from '../../stores/user.store';

export default function ScoreFormat(props) {
	const { settings } = useSettingsStore();
	const {
		team,
		name,
		stat,
		ranks
	} = usePostStore();
	const { individuallyEdit, sizeMultiplier, setEditTeam } = useGlobalStore();
	const { sport } = useUserStore();

	let statrows = 0;
	// eslint-disable-next-line
	team.map((t, i) => {
		if (stat[i / 2]) {
			// left
			if ((i / 2) % 2 === 0) {
				statrows = statrows + 1;
				// right
			} else {
				if (!stat[i / 2 - 1]) {
					statrows = statrows + 1;
				}
			}
		}
	});
	if (statrows > 20) statrows = 20;
	let maxeighty = team.slice(0, 80);
	return maxeighty.map((item, i) => {
		let teamValues = props.getTeam(item);
		let t = teamValues.team ? teamValues.team : null;
		let s = teamValues.sport ? teamValues.sport : sport;
		let rows = Math.ceil(team.length / 4);
		if (rows > 20) rows = 20;
		let statsize = Number(settings.statSize) * sizeMultiplier;
		let statmargin = Number(settings.statMargin) * sizeMultiplier;
		let bottom = statsize * -1 + 21 * sizeMultiplier - statmargin;
		let statcalc = statsize + statmargin + bottom > 0 ? statsize + statmargin + bottom : 0;
		let footerHeight = settings.hasFooter ? settings.footerHeight : 0;
		footerHeight = footerHeight * sizeMultiplier;
		let availableHeight = (settings.postHeight * 0.9 - footerHeight) / rows;
		let minusMargin = availableHeight - settings.scoreMargin * 2 * sizeMultiplier;
		let statspace = (statrows * statcalc) / rows;
		let height = minusMargin - statspace;
		height = Math.min(height, settings.scoreMinHeight * sizeMultiplier);
		let special = stat[i / 2] ? `${statsize + statmargin + bottom}px` : '';
		let evenodd = i % 2 === 0 ? 'even' : 'odd';
		if (!settings.logoFlip) {
			evenodd = 'same';
		}
		let logoExists = true;
		if (props.user.is_admin) {
			logoExists = props.getTeamObj(t, s);
		}
		let teamObj, boxbackground;
		if (t) {
			teamObj = props.getTeamObj(t, s);
			boxbackground = 'transparent';
			if (teamObj) {
				boxbackground = props.getBoxBackground(teamObj);
			}
		}
		let rankSide = i % 2 === 0 && settings.logoFlip ? 'odd' : 'even';
		if (settings.logoOrder === 1) {
			rankSide = i % 2 === 0 && settings.logoFlip ? 'even' : 'odd';
		}
		return (
			<span
				style={{position: 'relative'}}
				key={i}
				className={`score${evenodd}`}>
				<div
					id={`box${i}`}
					style={{height, marginBottom: special, background: boxbackground}}
					className={`score box ${evenodd}`}>
					{individuallyEdit && (
						<span
							className={`edit-box ${evenodd}`}
							onClick={(e) => {
								e.stopPropagation();
								setEditTeam(i);
							}}>
							<i className='fas fa-pencil-alt no-click'></i>
						</span>
					)}
					{logoExists && (
						<div
							className={`boximagecontainer`}
							onClick={() => props.selectSettings('team')}>
							<div
								id={`image${i}`}
								className={`boximage`}
								style={{backgroundImage: teamObj?.logo}}></div>
						</div>
					)}
					{!logoExists && (
						<div className={`boximagecontainer`}>
							<div className={`boximage alttext`}>{t}</div>
						</div>
					)}
					{settings.texture && (
						<div
							className={'boxtexture ' + settings.texture}
							onClick={() => props.selectSettings('team')}></div>
					)}
					<div
						id={`boxname${i}`}
						className={`boxname` + (settings.nameShadow === 0 ? ' no-shadow' : '')}
						onClick={() => props.selectSettings('name')}>
						<span
							id={`spanname${i}`}
							contentEditable={props.homePage}
							suppressContentEditableWarning={true}>
							{name[i]}
						</span>
					</div>
				</div>
				{ranks[i] && (
					<div
						className={`rank${rankSide} textglow`}
						contentEditable={props.homePage}
						suppressContentEditableWarning={true}
						onClick={() => props.selectSettings('rank')}>
						{ranks[i]}
					</div>
				)}
				{stat[i / 2] && (
					<div
						id={`boxstat${i / 2}`}
						style={{minWidth: null}}
						contentEditable={props.homePage}
						suppressContentEditableWarning={true}
						className={'boxstat' + (settings.statShadow === 0 ? ' no-shadow' : '')}
						onClick={() => props.selectSettings('stat')}>
						{stat[i / 2]}
					</div>
				)}
			</span>
		);
	});
}
