import { create } from 'zustand';
import { Option } from './options.store';
import { Post } from '../data/interfaces/post';
import { sportOptions } from '../data/styles/sportOptions';
import { SingleValue } from 'react-select';

interface SidebarStore {
    response: any[];
    responseSize: number;
    selectedPosition: Option | null;
    extraScore: boolean;
    tiktok: boolean;
    rookie: boolean;
    selectedAutoStat: any;
    articleID: string;
    selectedCBBConference: number;
    selectedCFBConference: number;
    templates: Post[];
    imagesLoaded: boolean;
    sportFilter: SingleValue<Option>;
    currentTemplateId: string;
    savedSettingPage: number;
    savedPostSearch: string;
    savedPostFilter: SingleValue<Option> | null;
    savedUserFilter: SingleValue<Option> | null;
    savedFolderFilter: SingleValue<Option> | null;
    settingFilter: SingleValue<Option> | null;
    settingValueFilter: SingleValue<Option> | null;
    multiSelect: boolean;
    selected: string[];
    selectedTitles: string[];
    selectedFolders: string[];
    setResponse: (newValue: any[]) => void;
    setResponseSize: (newValue: number) => void;
    setSelectedPosition: (newValue: Option) => void;
    setExtraScore: (newValue: boolean) => void;
    setTiktok: (newValue: boolean) => void;
    setRookie: (newValue: boolean) => void;
    setSelectedAutoStat: (newValue: any) => void;
    setArticleID: (newValue: string) => void;
    setSelectedCBBConference: (newValue: number) => void;
    setSelectedCFBConference: (newValue: number) => void;
    setTemplates: (newValue: Post[]) => void;
    setImagesLoaded: (newValue: boolean) => void;
    setSportFilter: (newValue: SingleValue<Option>) => void;
    setCurrentTemplateId: (newValue: string) => void;
    setSavedSettingPage: (newValue: number) => void;
    setSavedPostSearch: (newValue: string) => void;
    setSavedPostFilter: (newValue: SingleValue<Option> | null) => void;
    setSavedUserFilter: (newValue: SingleValue<Option> | null) => void;
    setSavedFolderFilter: (newValue: SingleValue<Option> | null) => void;
    setSettingFilter: (newValue: SingleValue<Option> | null) => void;
    setSettingValueFilter: (newValue: SingleValue<Option> | null) => void;
    setMultiSelect: (newValue: boolean) => void;
    setSelected: (newValue: string[]) => void;
    setSelectedTitles: (newValue: string[]) => void;
    setSelectedFolders: (newValue: string[]) => void;
}

const useSidebarStore = create<SidebarStore>((set) => ({
    response: [],
    responseSize: 10,
    selectedPosition: null,
    extraScore: false,
    tiktok: false,
    rookie: false,
    selectedAutoStat: null,
    articleID: '',
    selectedCBBConference: 50,
    selectedCFBConference: 0,
    templates: [],
    imagesLoaded: false,
    sportFilter: sportOptions[0],
    currentTemplateId: '',
    savedSettingPage: 1,
    savedPostSearch: '',
    savedPostFilter: null,
    savedUserFilter: null,
    savedFolderFilter: null,
    settingFilter: null,
    settingValueFilter: null,
    multiSelect: false,
    selected: [],
    selectedTitles: [],
    selectedFolders: [],
    setResponse: (newValue: any[]) => set({ response: newValue }),
    setResponseSize: (newValue: number) => set({ responseSize: newValue }),
    setSelectedPosition: (newValue: Option) => set({ selectedPosition: newValue }),
    setExtraScore: (newValue: boolean) => set({ extraScore: newValue }),
    setTiktok: (newValue: boolean) => set({ tiktok: newValue }),
    setRookie: (newValue: boolean) => set({ rookie: newValue }),
    setSelectedAutoStat: (newValue: any) => set({ selectedAutoStat: newValue }),
    setArticleID: (newValue: string) => set({ articleID: newValue }),
    setSelectedCBBConference: (newValue: number) => set({ selectedCBBConference: newValue }),
    setSelectedCFBConference: (newValue: number) => set({ selectedCFBConference: newValue }),
    setTemplates: (newValue: Post[]) => set({ templates: newValue }),
    setImagesLoaded: (newValue: boolean) => set({ imagesLoaded: newValue }),
    setSportFilter: (newValue: SingleValue<Option>) => set({ sportFilter: newValue }),
    setCurrentTemplateId: (newValue: string) => set({ currentTemplateId: newValue }),
    setSavedSettingPage: (newValue: number) => set({ savedSettingPage: newValue }),
    setSavedPostSearch: (newValue: string) => set({ savedPostSearch: newValue }),
    setSavedPostFilter: (newValue: SingleValue<Option> | null) => set({ savedPostFilter: newValue }),
    setSavedUserFilter: (newValue: SingleValue<Option> | null) => set({ savedUserFilter: newValue }),
    setSavedFolderFilter: (newValue: SingleValue<Option> | null) => set({ savedFolderFilter: newValue }),
    setSettingFilter: (newValue: SingleValue<Option> | null) => set({ settingFilter: newValue }),
    setSettingValueFilter: (newValue: SingleValue<Option> | null) => set({ settingValueFilter: newValue }),
    setMultiSelect: (newValue: boolean) => set({ multiSelect: newValue }),
    setSelected: (newValue: string[]) => set({ selected: newValue }),
    setSelectedTitles: (newValue: string[]) => set({ selectedTitles: newValue }),
    setSelectedFolders: (newValue: string[]) => set({ selectedFolders: newValue })
}));

export default useSidebarStore;
