import Split from 'react-split';
import Draggable from 'react-draggable';
import {Resizable} from 're-resizable';
import { colord, extend } from 'colord';
import mixPlugin from 'colord/plugins/mix';
import useSettingsStore from '../../../stores/settings.store';
import usePostStore from '../../../stores/posts/base-post.store';
import useGlobalStore from '../../../stores/global.store';
import useUserStore from '../../../stores/user.store';
import useWithImageStore from '../../../stores/posts/with-image.store';
import { useEffect, useState } from 'react';
import PostFormat from '../PostFormat';
import TitleBox from '../common/TitleBox';
import Footer from '../common/Footer';
import MatchupBox from '../MatchupBox';
import GridFormat from '../GridFormat';
import HighlightFormat from '../HighlightFormat/HighlightFormat';
import FileUpload from '../common/FileUpload';
import FloatingTexts from '../common/FloatingTexts';
import LineupFormat from '../LineupFormat/LineupFormat';
import { Textfit } from '@gmurph91/react-textfit';
import './ImageFormat.scss';
import { isMobile } from 'react-device-detect';
extend([mixPlugin]);

export default function ImageFormat(props) {
	const { settings, updateSetting } = useSettingsStore();
	const { description, name, team, stat } = usePostStore();
	const { newstitle, newssubtitle, newsteam, newsimage } = useWithImageStore();
	const { individuallyEdit, sizeMultiplier, showImage, setEditTeam, forceGraphicUpdate, isDownloading } = useGlobalStore();
	const { sport } = useUserStore();
	const [loading, setLoading] = useState(true);
	const [reloadKey, setReloadKey] = useState(0);
	const [size, setSize] = useState();

	useEffect(() => {
		setLoading(true);
		const newsImage = document.getElementById('newsImage');
		if (newsImage?.complete) {
			setLoading(false);
		}
	}, [newsimage]);

	useEffect(() => {
		setReloadKey(prevKey => prevKey + 1);
	}, [forceGraphicUpdate]);

	useEffect(() => {
		if (size) {
			let property = 'paneSplit';
			if (settings.type === 'news') {
				property = 'paneSplitNews';
			} else if (settings.type === 'matchupimage') {
				property = 'paneSplitMatchup';
			} else if (settings.type === 'highlightimage') {
				property = 'paneSplitHighlight';
			} else if (settings.type === 'lineupimage') {
				property = 'paneSplitLineup';
			}
			updateSetting(property, size);
			props.setImageDimensions();
			props.adjustImage();
			setReloadKey(prevKey => prevKey + 1);
		}
	}, [size]);

	const changeSplit = (s) => {
		setSize(s);
	}

	const getLinesSvg = () => {
		const width = 34 * sizeMultiplier;
		const height = settings.postHeightNews;
		const lineColor = props.findColor(settings.imageLinesColor, teamObj);
		const svgString = `
			<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 ${width} ${height}">
			<defs>
				<pattern id="pattern" width="${width}" height="${width}" patternUnits="userSpaceOnUse" patternTransform="rotate(125)">
				<rect width="${width / 2}" height="${width}" fill="transparent"></rect>
				<rect x="${width / 2}" width="${width / 2}" height="${width}" fill="${lineColor}"></rect>
				</pattern>
			</defs>
			<rect width="100%" height="100%" fill="url(#pattern)"></rect>
			</svg>`;
		const encodedSVG = encodeURIComponent(svgString);
		let style = {
			position: 'absolute',
			top: 0,
			right: 'calc(-4px * var(--sizemultiplier))',
			transform: 'rotate(1deg)',
			background: `url('data:image/svg+xml;utf8,${encodedSVG}')`,
			height: '100%',
			width,
			overflow: 'hidden'
		}
		let orientationSetting = settings.orientation;
		if (settings.type === 'news') {
			orientationSetting = settings.orientationNews;
		} else if (settings.type === 'matchupimage') {
			orientationSetting = settings.orientationMatchup;
		} else if (settings.type === 'highlightimage') {
			orientationSetting = settings.orientationHighlight;
		} else if (settings.type === 'lineupimage') {
			orientationSetting = settings.orientationLineup;
		}
		if (orientationSetting === 'rightleft') {
			style.right = 'auto';
			style.left = 'calc(-4px * var(--sizemultiplier))';
			style.transform = 'rotate(-1deg)';
		}
		return style;
	};

	const getImageStyle = (includeLeftRight) => {
		let style = { display: loading ? 'none' : 'block' };
		let orientationSetting = settings.orientation;
		if (settings.type === 'news') {
			orientationSetting = settings.orientationNews;
		} else if (settings.type === 'matchupimage') {
			orientationSetting = settings.orientationMatchup;
		} else if (settings.type === 'highlightimage') {
			orientationSetting = settings.orientationHighlight;
		} else if (settings.type === 'lineupimage') {
			orientationSetting = settings.orientationLineup;
		}
		const newsImage = document.getElementById('newsImage');
		if (orientationSetting.includes('top')) {
			if (isDownloading && !isMobile) return style;
			if (newsImage) {
				if (newsImage.closest('.react-draggable').style.height !== 'auto') {
					style.height = newsImage.closest('.react-draggable').style.height;
					return style; 
				}
			}
		}
		if (orientationSetting.includes('left') && includeLeftRight) {
			if (isDownloading && !isMobile) return style;
			if (newsImage) {
				if (newsImage.closest('.react-draggable').style.width !== 'auto') {
					style.width = newsImage.closest('.react-draggable').style.width;
					return style; 
				}
			}
			style.width = '100%';
		}
		return style;
	}

	const isStyle4 = () => {
		return (settings.type === 'highlightimage' || settings.type === 'lineupimage') && settings.newsStyle?.value === 'style-4'
	}

	const getStyle = (teamObj, boxbackground) => {
		if (settings.type === 'news') {
			let style = { width: settings.postWidthNews, height: settings.postHeightNews };
			if (settings.newsStyle?.value === 'style-6') {
				style['--line-color'] = props.findColor(settings.imageLinesColor, teamObj);
			}
			return style;
		}
		let style = { width: settings.postWidth, height: settings.postHeight };
		if (isStyle4()) {
			style.display = 'flex';
			style.background = boxbackground;
			style['--line-color'] = props.findColor(settings.imageLinesColor, teamObj);
		}
		return style;
	}

	const getClassName = () => {
		if (settings.type === 'matchupimage') {
			return `matchup-${settings.matchupStyle?.value}`;
		}
		if (settings.type === 'news') {
			return settings.newsStyle?.value;
		}
		if (settings.type === 'highlightimage' || settings.type === 'lineupimage') {
			if (settings.newsStyle?.value === 'style-4') {
				return 'new-style-4';
			} else {
				return settings.newsStyle?.value;
			}
		}
		return '';
	}
	
	const getLeftStyle = (teamObj, orientationSetting) => {
		let style = {};
		let boxbackground = '#000000';
		const newsTeamTypes = [
			'news',
			'highlightimage',
			'lineupimage'
		]
		if (newsTeamTypes.includes(settings.type)) {
			if (teamObj) {
				if (settings.teamBackgroundLeft === 'gradient') {
					boxbackground = settings.leftBackgroundGradientType === 'linear-gradient' ?
						`linear-gradient(${settings.leftBackgroundGradientDirection}, ` :
						`radial-gradient(circle, `;
					settings.leftBackgroundGradientStops.forEach((stop) => {
						let stopColor = props.findColor(stop.color, teamObj);
						if (stop.colorMix) {
							stopColor = colord(stopColor).mix(stop.color2, stop.colorMix / 100).toHex();
							colord(teamObj.primary).mix('#000000', 0.7).toHex();
						}
						boxbackground += `${stopColor} ${stop.percentage}%, `
					});
					boxbackground = boxbackground.slice(0, -2);
					boxbackground += ')';
					// single color
				} else {
					boxbackground = props.findColor(settings.teamBackgroundLeft, teamObj);
				}
			}
			style = {background: boxbackground};
		} else {
			if (settings.postBackground) {
				style = {backgroundImage: `var(--postbackground)`};
			} else if (settings.backgroundColor === 'gradient') {
				style = {background: props.getGraphicBackground()};
			} else {
				style = {backgroundColor: settings.headerColor};
			}
		}
		if (orientationSetting.includes('top')) {
			style.height = `calc(100% + calc(12px * var(--sizemultiplier)))`;
			if (orientationSetting === 'bottomtop') {
				style.marginTop = `calc(-12px * var(--sizemultiplier))`;
			}
		} else {
			style.width = `calc(100% + calc(12px * var(--sizemultiplier)))`
			if (orientationSetting === 'rightleft') {
				style.marginLeft = `calc(-12px * var(--sizemultiplier))`;
			}
		}
		return style;
	}

	let rightSide;
	const t = newsteam.toUpperCase();
	const teamObj = props.getTeamObj(t, sport);
	let boxbackground = props.getBoxBackground(teamObj);
	if (settings.type === 'news') {
		if (!newsteam) {
			rightSide = (
				<div className='newsRight'>
					<div className='zero-state'>
						{sport !== 'custom' && <button
							className='newButton newLine showExample'
							onClick={() => props.prefill()}>
							Show Example
						</button>}
					</div>
				</div>
			)
		} else {
			if (settings.newsStyle?.value === 'style-4') {
				let direction;
				let gradColor = teamObj?.primary;
				if (settings.orientationNews === 'leftright') direction = 'to left';
				if (settings.orientationNews === 'topbottom') direction = 'to top';
				if (settings.orientationNews === 'rightleft') direction = 'to right';
				if (settings.orientationNews === 'bottomtop') direction = 'to bottom';
				boxbackground = `linear-gradient(${direction}, ${gradColor} 50%, transparent 100%)`;
			}
			rightSide = (
				<div
					className='newsRight'
					style={{ background: boxbackground }}>
					{settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
					{individuallyEdit && teamObj && settings.imageStyle?.value !== 'style-3' && (
						<span
							className={`edit-box left`}
							onClick={(e) => {
								e.stopPropagation();
								setEditTeam(0);
							}}>
							<i className='fas fa-pencil-alt no-click'></i>
						</span>
					)}
					{settings.imageStyle?.value !== 'style-3' && <div
						id={`image1000-news`}
						onClick={(e) => {
							props.selectSettings('team');
						}}
						className='newsTeam'
						style={{ backgroundImage: teamObj?.logo }}></div>}
					<span className='textTogether'>
						{newstitle && (
							<div
								className='newsTitle'
								contentEditable={props.homePage}
								suppressContentEditableWarning={true}
								onClick={() => props.selectSettings('header')}>
								<span>{newstitle}</span>
							</div>
						)}
						{newssubtitle && (
							<div
								className='newsText'
								contentEditable={props.homePage}
								suppressContentEditableWarning={true}
								onClick={() => props.selectSettings('subtitle')}>
								{newssubtitle}
							</div>
						)}
					</span>
					{settings.newsStyle?.value === 'style-1' && (
						<span className='diagram'>
							{(sport === 'nfl' || sport === 'ncaa') && (
								<img
									className='playdiagram'
									src='/diagram.png'
									alt=''
								/>
							)}
							{sport === 'nba' && (
								<img
									className='playdiagram'
									src='/diagram2.png'
									alt=''
								/>
							)}
						</span>
					)}
					{settings.newsStyle?.value === 'style-3' && (
						<span className='news-lines'>
							<span className='news-line-1'></span>
							<span className='news-line-2'></span>
							<span className='news-line-3'></span>
							<span className='news-line-4'></span>
							<span className='news-line-5'></span>
						</span>
					)}
					{settings.newsStyle?.value === 'style-6' && (
						<div className='lines-div' style={getLinesSvg()}></div>
					)}
				</div>
			);
		}
	} else if (settings.type === 'matchupimage') {
		let middleBackground;
		if (settings.backgroundColor === 'gradient') {
			middleBackground = props.getGraphicBackground();
		} else {
			middleBackground = settings.headerColor;
		}
		rightSide = (
			<div
				className='postycontainer'
				style={{ position: 'relative' }}>
				<div
					id='postwithimage'
					className={props.classes}
					style={props.postStyle}>
					{team[0] ? (<div className='matchupBoxes'>
						<MatchupBox instance={0} getTeam={props.getTeam} getTeamObj={props.getTeamObj} getBoxBackground={props.getBoxBackground} selectSettings={props.selectSettings} homePage={props.homePage} findColor={props.findColor}></MatchupBox>
						{stat[0] && settings.matchupStyle?.value !== 'style-4' && (
							<div
								style={{ minWidth: null }}
								className={`status` + (settings.statShadow === 0 ? ' no-shadow' : '')}
								onClick={() => props.selectSettings('stat')}>
								<span contentEditable={props.homePage} suppressContentEditableWarning={true}>{stat.join('\n')}</span>
							</div>
						)}
						{settings.matchupStyle?.value === 'style-4' && (
							<div
								className='middle-matchup'
								style={{ background: middleBackground }}>
								<div
									style={{ minWidth: null }}
									className={`status` + (settings.statShadow === 0 ? ' no-shadow' : '')}
									onClick={() => props.selectSettings('stat')}>
									<span contentEditable={props.homePage} suppressContentEditableWarning={true}>{stat.join('\n')}</span>
								</div>
								<div
									className='middle-scores'
									contentEditable={props.homePage}
									suppressContentEditableWarning={true}
									onClick={() => props.selectSettings('name')}>
									{name[0]} - {name[1]}
								</div>
							</div>
						)}
						<MatchupBox instance={1} getTeam={props.getTeam} getTeamObj={props.getTeamObj} getBoxBackground={props.getBoxBackground} selectSettings={props.selectSettings} homePage={props.homePage} findColor={props.findColor}></MatchupBox>
						<div
							className='game-desc'
							contentEditable={props.homePage}
							suppressContentEditableWarning={true}
							onClick={() => props.selectSettings('description')}>
							{description}
						</div>
					</div>) : (
						<div className='zero-state'>
							{sport !== 'custom' && <button
								className='newButton newLine showExample'
								onClick={() => props.prefill()}>
								Show Example
							</button>}
						</div>
					)}
				</div>
			</div>
		);
	} else if (settings.type === 'highlightimage') {
		if (!newsteam) {
			rightSide = (
				<div className='newsRight'>
					<div className='zero-state'>
						{sport !== 'custom' && <button
							className='newButton newLine showExample'
							onClick={() => props.prefill()}>
							Show Example
						</button>}
					</div>
				</div>
			)
		} else {
			rightSide = <HighlightFormat getLinesSvg={getLinesSvg} getTeamObj={props.getTeamObj} getBoxBackground={props.getBoxBackground} selectSettings={props.selectSettings} homePage={props.homePage} findColor={props.findColor}></HighlightFormat>
		}
	} else if (settings.type === 'lineupimage') {
		if (!newsteam) {
			rightSide = (
				<div className='newsRight'>
					<div className='zero-state'>
						{sport !== 'custom' && <button
							className='newButton newLine showExample'
							onClick={() => props.prefill()}>
							Show Example
						</button>}
					</div>
				</div>
			)
		} else {
			rightSide = <LineupFormat getLinesSvg={getLinesSvg} getTeamObj={props.getTeamObj} getBoxBackground={props.getBoxBackground} selectSettings={props.selectSettings} homePage={props.homePage} findColor={props.findColor}></LineupFormat>
		}
	} else {
		rightSide = (
			<div
				className='postycontainer'
				style={{ position: 'relative' }}>
				<div
					id='postwithimage'
					className={props.classes}
					style={props.postStyle}>
					<TitleBox user={props.user} selectSettings={props.selectSettings} homePage={props.homePage}></TitleBox>
					{!team.length && !props.homePage && (
						<div className='addmore'>
							Begin by entering one or more team names
							<br />
							{sport !== 'custom' && <button
								className='newButton newLine showExample'
								onClick={() => props.prefill()}>
								Show Example
							</button>}
						</div>
					)}
					{settings.type === 'postimage' && (
						<PostFormat
							getTeamObj={props.getTeamObj}
							getTeam={props.getTeam}
							user={props.user}
							getBoxBackground={props.getBoxBackground}
							getTwoTeamBoxBackground={props.getTwoTeamBoxBackground}
							getNameStyle={props.getNameStyle}
							getStatStyle={props.getStatStyle}
							selectSettings={props.selectSettings}
							openInlineEditor={props.openInlineEditor}
							flipClip={props.flipClip}
							homePage={props.homePage}
							findColor={props.findColor}
							getImg={props.getImg}>
						</PostFormat>
					)}
					{settings.type === 'gridimage' && (
						<GridFormat
							getTeamObj={props.getTeamObj}
							getTeam={props.getTeam}
							getBoxBackground={props.getBoxBackground}
							getTwoTeamBoxBackground={props.getTwoTeamBoxBackground}
							selectSettings={props.selectSettings}
							openInlineEditor={props.openInlineEditor}
							flipClip={props.flipClip}
							homePage={props.homePage}
							setDraggables={props.setDraggables}
							findColor={props.findColor}
							getImg={props.getImg}>
						</GridFormat>
					)}
					{settings.hasFooter && <Footer selectSettings={props.selectSettings}></Footer>}
				</div>
			</div>
		);
	}
	let splitSetting = settings.paneSplit;
	let orientationSetting = settings.orientation;
	if (settings.type === 'news') {
		splitSetting = settings.paneSplitNews;
		orientationSetting = settings.orientationNews;
	} else if (settings.type === 'matchupimage') {
		splitSetting = settings.paneSplitMatchup;
		orientationSetting = settings.orientationMatchup;
	} else if (settings.type === 'highlightimage') {
		splitSetting = settings.paneSplitHighlight;
		orientationSetting = settings.orientationHighlight;
	} else if (settings.type === 'lineupimage') {
		splitSetting = settings.paneSplitLineup;
		orientationSetting = settings.orientationLineup;
	}
	let leftSide;
	if (settings.imageStyle?.value !== 'style-1') {
		leftSide = (<div
			className={`newsLeft drag-container image-${settings.imageStyle?.value}`}
			style={getLeftStyle(teamObj, orientationSetting)}>
			{settings.texture && <div className={'boxtexture ' + settings.texture}></div>}
			{individuallyEdit && teamObj && settings.imageStyle?.value ==='style-3' && (
				<span
					className={`edit-box left`}
					onClick={(e) => {
						e.stopPropagation();
						setEditTeam(0);
					}}>
					<i className='fas fa-pencil-alt no-click'></i>
				</span>
			)}
			{settings.imageStyle?.value ==='style-3' && <div
				className='newsTeamContainer'
				style={{ backgroundColor: props.findColor(settings.teamContainerBackground, teamObj) }}>
				<div
					id={`image1000-news`}
					onClick={(e) => {
						props.selectSettings('team');
					}}
					className='newsTeam'
					style={{ backgroundImage: teamObj?.logo }}></div>
			</div>}
			{settings.imageStyle?.value ==='style-3' && <div className='pattern-div' style={{'--pattern-color': colord(teamObj?.secondary).isDark() ? colord(teamObj?.secondary).lighten(0.1).toHex() : colord(teamObj?.secondary).darken(0.1).toHex()}}></div>}
			{settings.imageStyle?.value ==='style-3' && <div className='background-text' style={{color: colord(teamObj?.secondary).isDark() ? colord(teamObj?.secondary).lighten(0.2).toHex() : colord(teamObj?.secondary).darken(0.2).toHex()}}>
				{Array(50).fill(settings.backgroundText).map((val, i) => {
					return <Textfit
						key={`${i}-${reloadKey}`} 
						max={400}
						mode="single">
						{val}
					</Textfit>
				})}
			</div>}
			<Draggable
				disabled={!individuallyEdit}
				handle='.handle'
				onStop={() => props.setDraggables()}>
				<Resizable
					id='floatingImage'
					className={`floatingImage ${individuallyEdit ? ' canMove' : ''}`}
					lockAspectRatio={false}
					onResize={() => {
						const me = document.getElementById('floatingImage');
						me.style.minWidth = me.style.width;
						me.style.minHeight = me.style.height;
					}}
					onResizeStop={() => {
						props.setDraggables()
					}}
					enable={{
						top: individuallyEdit,
						right: individuallyEdit,
						bottom: individuallyEdit,
						left: individuallyEdit,
						topRight: individuallyEdit,
						bottomRight: individuallyEdit,
						bottomLeft: individuallyEdit,
						topLeft: individuallyEdit,
					}}>
					{individuallyEdit && (
						<span className='handle'>
							<i className='fa-solid fa-grip-vertical'></i>
						</span>
					)}
					{!newsimage && sport && (
						<FileUpload user={props.user}></FileUpload>
					)}
					{newsimage && showImage && (
						<Draggable
							disabled={!individuallyEdit}
							onStop={() => props.setDraggables()}>
							<Resizable
								lockAspectRatio={true}
								enable={{
									top: individuallyEdit,
									right: individuallyEdit,
									bottom: individuallyEdit,
									left: individuallyEdit,
									topRight: individuallyEdit,
									bottomRight: individuallyEdit,
									bottomLeft: individuallyEdit,
									topLeft: individuallyEdit,
								}}>
								<div style={orientationSetting.includes('top') ? {width: '100%'} : {height: '100%'}}>
									<img
										src={newsimage}
										style={getImageStyle(true)}
										onLoad={() => setLoading(false)}
										onError={() => setLoading(false)}
										draggable='false'
										id='newsImage'
										alt='player image'
										className={'newsImage' + (individuallyEdit ? ' candrag' : '')}
										onClick={() => props.selectSettings('image')}></img>
									{loading && (
										<span className='post-image-loading'>
											<i className='fa fa-spinner fa-spin'></i>
										</span>
									)}
								</div>
							</Resizable>
						</Draggable>
					)}
				</Resizable>
			</Draggable>
		</div>);
	} else {
		leftSide = (<div className='newsLeft drag-container'>
		{!newsimage && sport && (
			<FileUpload user={props.user}></FileUpload>
		)}
		{newsimage && showImage && (
			<Draggable disabled={!individuallyEdit} onStop={() => props.setDraggables()}>
				<Resizable
					lockAspectRatio={true}
					enable={{
						top: individuallyEdit,
						right: individuallyEdit,
						bottom: individuallyEdit,
						left: individuallyEdit,
						topRight: individuallyEdit,
						bottomRight: individuallyEdit,
						bottomLeft: individuallyEdit,
						topLeft: individuallyEdit,
					}}>
					<img
						src={newsimage}
						style={getImageStyle(false)}
						onLoad={() => setLoading(false)}
						onError={() => setLoading(false)}
						draggable='false'
						id='newsImage'
						alt='player image'
						className={'newsImage' + (individuallyEdit ? ' candrag' : '')}
						onClick={() => props.selectSettings('image')}></img>
					{loading && <span className='post-image-loading'><i className='fa fa-spinner fa-spin'></i></span>}
				</Resizable>
			</Draggable>
		)}
	</div>);
	}
	return (
		<div
			className='postcontainer'
			id='postcontainer'>
			<div
				id='post'
				className={getClassName()}
				style={getStyle(teamObj, boxbackground)}>
				{splitSetting && (orientationSetting === 'leftright' || orientationSetting === 'topbottom') && (
					<Split
						sizes={[splitSetting[0], splitSetting[1]]}
						direction={orientationSetting.includes('top') ? 'vertical' : 'horizontal'}
						className={`${settings.type.includes('image') ? 'notnews' : 'news'} ${orientationSetting}`}
						gutterSize={10 * sizeMultiplier}
						gutterAlign='center'
						onDrag={changeSplit}>
						<span id='newsLeft'>{leftSide}</span>
						<span id='newsRight'>{rightSide}</span>
					</Split>
				)}
				{splitSetting && (orientationSetting === 'rightleft' || orientationSetting === 'bottomtop') && (
					<Split
						sizes={[splitSetting[0], splitSetting[1]]}
						direction={orientationSetting.includes('top') ? 'vertical' : 'horizontal'}
						className={`${settings.type.includes('image') ? 'notnews' : 'news'} ${orientationSetting}`}
						gutterSize={10}
						gutterAlign='center'
						onDrag={changeSplit}>
						<span id='newsRight'>{rightSide}</span>
						<span id='newsLeft'>{leftSide}</span>
					</Split>
				)}
				{settings.type === 'news' && settings.newsStyle?.value === 'style-2' && (
					<span className='news-frame'>
						<span className='frame-top-side'>
							<i className='fa fa-quote-left'></i>
							<span className='frame-top'></span>
						</span>
						<span className='frame-left'></span>
						<span className='frame-right'></span>
						<span className='frame-bottom-side'>
							<span className='frame-bottom'></span>
							<i className='fa fa-quote-right'></i>
						</span>
					</span>
				)}
				<FloatingTexts setDraggables={props.setDraggables} toggleFloatEditor={props.toggleFloatEditor}></FloatingTexts>
			</div>
		</div>
	);
}
