import usePostStore from '../../../stores/posts/base-post.store';
import useSettingsStore from '../../../stores/settings.store';

export default function TitleBox (props: { user: { logo: string | undefined; }; selectSettings: (arg0: string) => void; homePage: boolean; }): React.JSX.Element {
    const { title, subtitle } = usePostStore();
	const { settings } = useSettingsStore();

    if (settings.type.includes('image') || settings.type === 'news' || settings.type === 'highlightimage' || settings.type === 'lineupimage') {
        const style: { gridColumnEnd?: number } = {};
        const titleStyle: { fontSize?: number } = {};
        const subtitleStyle: { fontSize?: number } = {};
        if (settings.orientation === 'topbottom' || settings.orientation === 'bottomtop') {
            titleStyle.fontSize = ((settings.postHeight / 800) * settings.headerSize * 3 * settings.paneSplitNews[1]) / 100;
            subtitleStyle.fontSize = ((settings.postHeight / 800) * settings.subtitleFontSize * 3 * settings.paneSplitNews[1]) / 100;
        }
        if (settings.type.includes('grid') && settings.gridColumns) {
            style.gridColumnEnd = Number(settings.gridColumns) + 1;
        }
        return (
            <div
                className='titlebox'
                id='titlebox'
                style={style}>
                {props.user.logo && (
                    <img
                        src={props.user.logo}
                        id='userLogo'
                        alt=''
                        className='userLogo1'></img>
                )}
                {!props.user.logo && (
                    <span
                        className='watermark1'
                        onClick={() => props.selectSettings('actualheader')}>
                        {settings.watermark}
                    </span>
                )}
                <h1
                    className='title'
                    contentEditable={props.homePage}
                    suppressContentEditableWarning={true}
                    style={titleStyle}
                    onClick={() => props.selectSettings('header')}>
                    {title}
                </h1>
                <h2
                    className='subtitle'
                    contentEditable={props.homePage}
                    suppressContentEditableWarning={true}
                    style={subtitleStyle}
                    onClick={() => props.selectSettings('subtitle')}>
                    {subtitle}
                </h2>
                {props.user.logo && (
                    <img
                        src={props.user.logo}
                        id='userLogo'
                        alt=''
                        className='userLogo2'></img>
                )}
                {!props.user.logo && (
                    <span
                        className='watermark2'
                        onClick={() => props.selectSettings('actualheader')}>
                        {settings.watermark}
                    </span>
                )}
            </div>
        );
    } else {
        let style;
        if (settings.type.includes('grid') && settings.gridColumns) {
            style = { gridColumnEnd: Number(settings.gridColumns) + 1 };
        }
        return (
            <div
                className='titlebox'
                id='titlebox'
                style={style}>
                {props.user.logo && (
                    <img
                        src={props.user.logo}
                        id='userLogo'
                        alt=''
                        className='userLogo1'></img>
                )}
                {!props.user.logo && (
                    <span
                        className='watermark1'
                        onClick={() => props.selectSettings('actualheader')}>
                        {settings.watermark}
                    </span>
                )}
                <h1
                    className='title'
                    contentEditable={props.homePage}
                    suppressContentEditableWarning={true}
                    onClick={() => props.selectSettings('header')}>
                    {title}
                </h1>
                <h2
                    className='subtitle'
                    contentEditable={props.homePage}
                    suppressContentEditableWarning={true}
                    onClick={() => props.selectSettings('subtitle')}>
                    {subtitle}
                </h2>
                {props.user.logo && (
                    <img
                        src={props.user.logo}
                        id='userLogo'
                        alt=''
                        className='userLogo2'></img>
                )}
                {!props.user.logo && (
                    <span
                        className='watermark2'
                        onClick={() => props.selectSettings('actualheader')}>
                        {settings.watermark}
                    </span>
                )}
            </div>
        );
    }
}
