import React, { useState } from 'react';
import Select, { SingleValue } from 'react-select';
import useUserStore from '../stores/user.store';
import useOptionsStore from '../stores/options.store';
import useSettingsStore from '../stores/settings.store';
import { backgroundColorOptions } from '../data/styles/colorOptions';
import { settingsStylesDark } from '../data/styles/settingsStylesDark';
import { settingsStyles } from '../data/styles/settingsStyles';
import usePreferencesStore from '../stores/preferences.store';
import { ChromePicker } from './ChromePicker';
import type { Option } from '../stores/options.store';
import { Popover } from 'react-tiny-popover';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { GradientStop } from '../data/settings/settings';

interface ColorMenuProps {
	setting: GradientStop;
	instance: number;
	background?: boolean;
}

export const ColorMenu: React.FC<ColorMenuProps> = ({ setting, instance, background }) => {
	const [availableOptions] = useState(structuredClone(backgroundColorOptions));
	const { lockedSettings } = useUserStore();
	const { selectedMultiColor, setSelectedMultiColor } = useOptionsStore();
	const { settings, updateSetting } = useSettingsStore();
	const { darkMode } = usePreferencesStore();
	const [popoverOpen, setPopoverOpen] = useState(false);

	const colorChange = (co: string): void => {
		if (background) {
			const gradientStopsCopy = structuredClone(settings.backgroundGradientStops);
			const stopToEdit = gradientStopsCopy[instance];
			stopToEdit.color = co;
			updateSetting('backgroundGradientStops', gradientStopsCopy);
		} else {
			const gradientStopsCopy = structuredClone(hasSingleTeam() ? settings.gradientStopsNews : settings.gradientStops);
			const stopToEdit = gradientStopsCopy[instance];
			// transparency slider changed on primary/secondary/primary darker
			if (co === '#NaNNaNNaN') co = '#ffffff';
			stopToEdit.color = co;
			updateSetting(hasSingleTeam() ? 'gradientStopsNews' : 'gradientStops', gradientStopsCopy);
		}
	};

	const hasSingleTeam = (): boolean => {
		return [
			'news',
			'highlightimage',
			'lineupimage'
		].includes(settings.type);
	}

	const togglePopover = (): void => {
		if (!popoverOpen) {
			if (!background) {
				let firstOption = availableOptions[0];
				if (setting.color.includes('#')) {
					const foundOption = availableOptions.find((opt) => opt.value === 'single');
					if (foundOption) {
						firstOption = foundOption;
					}
				} else {
					const foundOption = availableOptions.find((opt) => opt.value === setting.color);
					if (foundOption) {
						firstOption = foundOption;
					}
				}
				setSelectedMultiColor(firstOption as SingleValue<Option>);
			}
			document.addEventListener('click', handleClickOutside, true);
		}
		setPopoverOpen(!popoverOpen);
	}

	const handleClickOutside = (event: any): void => {
		if (event.target.closest('.topmost') === null && event.target.closest(`#opener${instance}`) === null) {
			setPopoverOpen(false);
			document.removeEventListener('click', handleClickOutside, true);
		}
	};

	return (
		<Tippy content={<span>{setting.color.includes('#') ? setting.color : availableOptions.find((co) => co.value === setting.color)?.label}</span>}>
			<span className='editor-button'>
				<Popover
					isOpen={popoverOpen}
					reposition={true}
					containerStyle={{ 'z-index': '1001' } as any}
					onClickOutside={togglePopover}
					positions={['bottom', 'top', 'right', 'left']}
					content={
						<div className='popover-content color-picker topmost'>
							{!background && (<span className='editor-dropdown'>
								<Select
									isDisabled={lockedSettings.includes(background ? 'backgroundGradientStops' : hasSingleTeam() ? 'gradientStopsNews' : 'gradientStops')}
									value={selectedMultiColor}
									onChange={(s) => {
										setSelectedMultiColor(s);
										const val = String((s as any).value);
										if (val !== 'single') {
											const gradientStopsCopy = structuredClone(hasSingleTeam() ? settings.gradientStopsNews : settings.gradientStops);
											const stopToEdit = gradientStopsCopy[instance];
											stopToEdit.color = val;
											updateSetting(hasSingleTeam() ? 'gradientStopsNews' : 'gradientStops', gradientStopsCopy);
										} else {
											const gradientStopsCopy = structuredClone(hasSingleTeam() ? settings.gradientStopsNews : settings.gradientStops);
											const stopToEdit = gradientStopsCopy[instance];
											stopToEdit.color = '#000000';
											updateSetting(hasSingleTeam() ? 'gradientStopsNews' : 'gradientStops', gradientStopsCopy);
										}
									}}
									options={availableOptions}
									styles={darkMode ? settingsStylesDark : settingsStyles}
									placeholder={setting.color.includes('#') ? 'Single' : setting.color}
								/>
							</span>)}
							{setting.color.includes('#') && (
								<span style={{ display: 'block', marginTop: '20px' }}>
									<ChromePicker
										color={setting.color}
										onChange={(color: string) => colorChange(color)}
									/>
								</span>
							)}
						</div>
					}>
					<span
						id={`opener${instance}`}
						className='colorbox-editor rainbow-box'
						style={setting.color.includes('#') ? { background: setting.color } : {}}
						onClick={togglePopover}>
					</span>
				</Popover>
			</span>
		</Tippy>
	);
};
