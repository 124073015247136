import { create } from 'zustand';
import { baseSettings } from '../data/settings/baseSettings';
import type { Settings } from '../data/settings/settings';

interface SettingsState {
  settings: Settings;
}

interface SettingsActions {
  updateSetting: <K extends keyof Settings>(key: K, value: Settings[K]) => void;
  updateSettings: (newSettings: Partial<Settings>) => void;
  replaceSettings: (newSettings: Partial<Settings>) => void;
}

const valueTypes: Record<string, string> = {
  '--headerfont': '',
  '--namefont': '',
  '--statfont': '',
  '--headersize': '',
  '--namesize': 'px',
  '--statsize': 'px',
  '--logoorder': '',
  '--logoopacity': '%',
  '--logosize': 'px',
  '--headermargin': 'px',
  '--headerspacing': 'px',
  '--namemargin': 'px',
  '--headercolor': '',
  '--headerfontcolor': '',
  '--watermarkfont': '',
  '--watermarksize': 'px',
  '--watermarkwidth': 'px',
  '--statmargin': 'px',
  '--statmargin2': 'px',
  '--watermarkcolor': '',
  '--namespacing': 'px',
  '--statspacing': 'px',
  '--statshadow': 'px',
  '--logoposition': 'px',
  '--lineheight': '',
  '--namebackground': '',
  '--statbackground': '',
  '--namecolor': '',
  '--statcolor': '',
  '--imagehorizontalnews': 'px',
  '--imageverticalnews': 'px',
  '--imagezoomnews': '%',
  '--headersizenews': 'px',
  '--headerfontnews': '',
  '--headermarginnews': 'px',
  '--headerspacingnews': 'px',
  '--headercolornews': '',
  '--headerfontcolornews': '',
  '--logoopacitynews': '%',
  '--logosizenews': 'px',
  '--orientation': '',
  '--orientationnews': '',
  '--orientationhighlight': '',
  '--orientationlineup': '',
  '--orientationmatchup': '',
  '--borderradius': 'px',
  '--scoremargin': 'px',
  '--panesplitleft': '',
  '--panesplitright': '',
  '--panesplitnewsleft': '',
  '--panesplitnewsright': '',
  '--panesplitmatchupleft': '',
  '--panesplitmatchupright': '',
  '--panesplithighlightleft': '',
  '--panesplithighlightright': '',
  '--panesplitlineupleft': '',
  '--panesplitlineupright': '',
  '--logoshadow': 'px',
  '--namemargin2': 'px',
  '--postwidth': 'px',
  '--postheight': 'px',
  '--postwidthnews': 'px',
  '--postheightnews': 'px',
  '--tierfont': '',
  '--tiercolor': '',
  '--tiersize': 'px',
  '--duplicatelogosize': 'px',
  '--duplicatelogoposition': 'px',
  '--duplicatelogoopacity': '%',
  '--gridmargin': '%',
  '--headeroutlinecolor': '',
  '--nameoutlinecolor': '',
  '--statoutlinecolor': '',
  '--headeroutlinesize': 'px',
  '--nameoutlinesize': 'px',
  '--statoutlinesize': 'px',
  '--headertransform': '',
  '--nametransform': '',
  '--stattransform': '',
  '--nameshadow': 'px',
  '--nameshadowcolor': '',
  '--statshadowcolor': '',
  '--sizemultiplier': '',
  '--postbackground': '',
  '--titleheight': '%',
  '--subtitlefont': '',
  '--subtitlefontsize': '',
  '--subtitlemargin': 'px',
  '--subtitlespacing': 'px',
  '--subtitlefontcolor': '',
  '--subtitleoutlinecolor': '',
  '--subtitleoutlinesize': 'px',
  '--subtitletransform': '',
  '--ranksize': 'px',
  '--rankfont': '',
  '--rankfontcolor': '',
  '--rankmargin': 'px',
  '--rankspacing': 'px',
  '--rankoutlinesize': 'px',
  '--rankoutlinecolor': '',
  '--logosizegrid': '%',
  '--logosizetier': '%',
  '--pointssize': 'px',
  '--pointsfont': '',
  '--pointsfontcolor': '',
  '--pointsmargin': 'px',
  '--pointsmargin2': 'px',
  '--pointsspacing': 'px',
  '--pointsoutlinesize': 'px',
  '--pointsoutlinecolor': '',
  '--matchuppointsbackground': '',
  '--pointsbackground': '',
  '--playerheight': '',
  '--statlineheight': '',
  '--playermargin': 'px',
  '--texturesize': 'px',
  '--textureopacity': '%',
  '--calcTextureSize': '',
  '--matchuprankbackground': '',
  '--rankbackground': '',
  '--boxmargin': 'px',
  '--actualheadercolor': '',
  '--rankwidth': 'px',
  '--rankmargin2': 'px',
  '--boxrankoutlinesize': 'px',
  '--bordersize': 'px',
  '--headerbordersize': 'px',
  '--headerbordercolor': '',
  '--boximagebackground': '',
  '--logodropshadow': 'px',
  '--footerheight': 'px',
  '--footerspacing': 'px',
  '--footermargin': 'px',
  '--footerfont': '',
  '--footersize': 'px',
  '--footerfontcolor': '',
  '--footerbackground': '',
  '--footeroutlinesize': 'px',
  '--footeroutlinecolor': '',
  '--footeralignment': '',
  '--tierbackground': '',
  '--tophighlight': '',
  '--bottomhighlight': '',
  '--subtitlefontnews': '',
  '--subtitlesizenews': 'px',
  '--subtitlemarginnews': 'px',
  '--subtitlespacingnews': 'px',
  '--imageshadowcolor': '',
  '--imageshadowsize': 'px',
  '--descriptionfont': '',
  '--descriptionsize': 'px',
  '--descriptionmargin': 'px',
  '--descriptionspacing': 'px',
  '--descriptionfontcolor': '',
  '--descriptionoutlinecolor': '',
  '--descriptionoutlinesize': 'px',
  '--descriptiontransform': '',
  '--comparisonradius': 'px',
  '--comparisonmargin': 'px',
  '--comparisonbordersize': 'px',
  '--comparisonbordercolor': '',
  '--labelfont': '',
  '--labelssize': 'px',
  '--labelsmargin': 'px',
  '--labelsspacing': 'px',
  '--labelsfontcolor': '',
  '--labelsbackground': '',
  '--labelstransform': '',
  '--boxglowwidth': 'px',
  '--boxglowcolor': '',
  '--lineheightnews': 'px',
  '--lineheighttextnews': '',
  '--imagetitlecolor': '',
  '--imagelinescolor': '',
  '--badgelineheightnews': 'px',
  '--titlebackground': '',
  '--textbackground': '',
  '--teamcontainerbackground': '',
  '--logoverticalmargin': 'px',
  '--logohorizontalmargin': 'px',
  '--logoverticalmarginnews': 'px',
  '--logohorizontalmarginnews': 'px',
  '--line21size': '%',
  '--line2size': '%',
  '--line3size': '%',
  '--footerpadding': 'px',
  '--playerorder': '',
  '--rankmarginreal': 'px',
  '--rankoutsidemargin': 'px',
  '--rankglowwidth': 'px',
  '--rankglowcolor': '',
  '--rankborderradius': 'px',
  '--poststatbackground': '',
  '--poststatpadding': 'px',
  '--poststatheight': '%',
  '--poststatborderradius': 'px',
  '--gradientdirection': '',
  '--gradientdirectionnews': '',
  '--backgroundgradientdirectiondeg': '',
  '--leftbackgroundgradientdirection': '',
  '--playeraspectratio': ''
};

const useSettingsStore = create<SettingsState & SettingsActions>((set) => {
  const updateCSSVariables = (settings: Partial<Settings>): void => {
    const root = document.documentElement;
    Object.entries(settings).forEach(([key, value]) => {
      const valueType = valueTypes[`--${key.toLowerCase()}`];
      if (valueType) {
        root.style.setProperty(`--${key.toLowerCase()}`, `${value}${valueType}`);
      } else {
        if (key === 'postBackground') {
          if (value) {
            root.style.setProperty(`--${key.toLowerCase()}`, `url('${value}')`);
          } else {
            root.style.setProperty(`--${key.toLowerCase()}`, 'none');
          }
        } else if (key.startsWith('paneSplit')) {
          const [left, right] = value as number[];
          const splitType = key.toLowerCase().replace('panesplit', '');
          root.style.setProperty(`--panesplit${splitType}left`, `${left}`);
          root.style.setProperty(`--panesplit${splitType}right`, `${right}`);
        } else if (key.endsWith('Font') || key.endsWith('FontNews')) {
          if (value.includes('bold')) {
            root.style.setProperty(`--${key.toLowerCase()}`, `${value.replaceAll(' ', '')}`);
          } else {
            root.style.setProperty(`--${key.toLowerCase()}`, `${value}`);
          }
        } else {
          root.style.setProperty(`--${key.toLowerCase()}`, `${value}`);
        }
      }
    });
  };

  return {
    settings: baseSettings,
    updateSetting: (key, value) =>
      set((state) => {
        const updatedSettings = { ...state.settings, [key]: value };
        updateCSSVariables({ [key]: value });
        return { settings: updatedSettings };
      }),
    updateSettings: (newSettings) =>
      set((state) => {
        // on top of current settings, add newSettings
        const updatedSettings = { ...state.settings, ...newSettings };
        updateCSSVariables(newSettings);
        return { settings: updatedSettings };
      }),
    replaceSettings: (newSettings) =>
      set(() => {
        // extend baseSettings to essentially addMissing but still overwrite current
        const updatedSettings = { ...baseSettings, ...newSettings };
        updateCSSVariables(updatedSettings);
        return { settings: updatedSettings };
      })
  };
});

export default useSettingsStore;
